import React, {useState, useEffect} from 'react';
import {FreeTrialMessage, ListePlans, NewCardForm} from "../../../components";
import {BorderTop, Button, Margin, P, PageHeading} from "../../../styles/ui";
import styled from 'styled-components';
import {useElements} from "@stripe/react-stripe-js";
import {useSubscription} from "../../../hooks";
import {AccountStatus} from "../../../models/account";

const Heading = styled.h2`
  font-size: .99em;
`;

const PlanSelection = ({showNextFn, account}) => {


    const {subscribeHandler, error, isLoading, stripe} = useSubscription();
    const [selectedPlan, setSelectedPlan] = useState('');


    const selectPlanHandler = (planId) => {
        setSelectedPlan(planId);
    };

    useEffect(() => {
        if (account.status === AccountStatus.PROF_ACTIF.label) {
            setSelectedPlan('');
        }
    }, [account.status])

    const elements = useElements();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const cardElement = elements.getElement('cardNumber');
        await subscribeHandler(cardElement, selectedPlan);
    };

    return (
        <>
            <PageHeading>Abonnement à Snowclass</PageHeading>
            <P>Choisir un plan d'abonnement.</P>
            <BorderTop>
                {account.status !== AccountStatus.PROF_ACTIF.label && (
                    <FreeTrialMessage/>
                )}
                <section>
                    <Margin marginbottom="1em"><Heading>Choisir un abonnement</Heading></Margin>
                    <ListePlans enabled={!isLoading} selectedPlan={selectedPlan} selectPlanFn={!isLoading ? selectPlanHandler : () => {}}/>
                </section>
            </BorderTop>
            {account.status === AccountStatus.PROF_ACTIF.label && (
                <Margin marginleft=".2em" margintop="2em"><Button onClick={() => showNextFn(true)} minwidth="210px">Poursuivre</Button></Margin>
            )}

            {account.status !== AccountStatus.PROF_ACTIF.label && (
                <NewCardForm account={account} selectedPlan={selectedPlan} show={selectedPlan !== ''} isLoading={isLoading} handleSubmitFn={handleSubmit} error={error} stripe={stripe}/>
            )}
        </>
    );
};

export default PlanSelection;

import {SideHeader, Footer} from "../../components";
import styled from 'styled-components';
import React from "react";
import {useLocation} from 'react-router-dom';

const LayoutWrapper = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    
    @media screen and (min-width: ${p => p.theme.sizes.media.large}) { 
      min-height: 100vh;
      flex-direction: row;
    } 
`;

const Left = styled.div`  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) { 
      flex: 3;
    }  
`;

const Right = styled.main`
  position:relative;
  padding-bottom: 3em;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    flex: 10;  
    padding-bottom: 1em;
  }
`;

const FullHeight = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  position:relative;
`;

const TopRight = styled.section`
  display:none;
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
      display:flex;
      justify-content: flex-end;
      padding: 1.78em 3em;
      position: absolute;
      top:0;
      right:0;
  }
`;

const Center = styled.section`
  display:flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
`;

const Content = styled.div`
  text-align: left;
  padding-top:1em;
  width: ${p => p.theme.sizes.layout.small};
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    width: ${p => p.theme.sizes.layout.small};
    //min-width: ${p => p.minWidth? p.minWidth + 'px' : 'initial'};
  }
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    width: ${p => p.theme.sizes.layout.medium};
    // min-width: ${p => p.minWidth? p.minWidth + 'px' : 'initial'};
  }
  
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
    width: ${p => p.theme.sizes.layout.large};
    // min-width: ${p => p.minWidth? p.minWidth + 'px' : 'initial'};
  }
`;

const FooterWrapper = styled.div`
  //position: absolute;
  //bottom: 0;
  //left: 0;
  //right: 0;
  
  width: 300px;
  margin: 4em auto;
  padding: 0;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    padding: 1em 3em 0 3em;
    width: auto;
    margin: 2em 0;
  }
  
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
    margin: 0;
  }
`;


const CommonLayout = ({children, topRightSection, minWidth}) => {
    const location = useLocation();
    let TopRightComp;
    if(topRightSection) {
        TopRightComp = topRightSection[location.pathname];
    }

    return (
        <LayoutWrapper>
            <Left>
                <SideHeader/>
            </Left>
            <Right>
                <FullHeight>
                    {TopRightComp && (
                        <TopRight>
                            <TopRightComp/>
                        </TopRight>
                    )}
                    <Center>
                        <Content minWidth={minWidth}>
                            {children}
                        </Content>
                    </Center>
                    <FooterWrapper><Footer common/></FooterWrapper>
                </FullHeight>

            </Right>
        </LayoutWrapper>
    );
};

export default CommonLayout;

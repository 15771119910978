import React,{useContext, useRef,useEffect, useState} from 'react';
import "./styles/styles.scss";
import 'firebase/auth';
import 'firebase/firestore';
import {routesGroup} from './routes';
import {PageSpinner} from "./components";
import {FBContext, LoaderContext} from "./context";
import styled from 'styled-components';
import Layout from "./layout/Layout";
import {CSSTransition} from "react-transition-group";
import {Helmet} from "react-helmet-async";
// import * as Sentry from '@sentry/react';

const Root = styled.div`
  font-size: 85%;
  position: relative;
  min-height: 100vh;
  ::selection {
    color: #fff;
    background-color: ${p => p.theme.colors.primary};
  }
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    font-size: 100%;
  }
`;

function App() {

    const {startLoading, isLoading} = useContext(LoaderContext);
    const { userLoading} = useContext(FBContext);

    const [renderedRoutes, setRenderedRoutes] = useState([]);
    useEffect(() => {
        setRenderedRoutes(routesGroup.map((r, i) => <Layout key={i} {...r} />));
    }, []);


    // const [isSpinning, setIsSpinning] = useState(true);
    useEffect(() => {
        if (userLoading) {
            startLoading();
        }

        /*if ((!userLoading && !accountLoading && user && account) || (!userLoading && !accountLoading && !user)) {
            //stopLoading();
            //setIsSpinning(false);
        } else {
            startLoading();
        }*/

    }, [startLoading, userLoading, /*stopLoading, account, auth, user,  accountLoading*/]);

    const nodeRef = useRef(null);
    //const errorFallback = <PageSpinner/>;


        {/*<Sentry.ErrorBoundary fallback={errorFallback}>*/}
    return (
            <Root>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Snowclass: apprendre est un jeu d'enfant.</title>
                </Helmet>
                {renderedRoutes.length && renderedRoutes}
                <CSSTransition nodeRef={nodeRef} mountOnEnter unmountOnExit in={isLoading} timeout={300} classNames="fly">
                    <PageSpinner/>
                </CSSTransition>
            </Root>
    );

        // </Sentry.ErrorBoundary>
}

export default App;

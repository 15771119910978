import {useState} from 'react';
import {DisplayError, FormElement} from "../";
import {Button, Fieldset, Margin, ButtonWrapper} from "../../styles/ui";
import PropTypes from 'prop-types';

const Form = ({elements, submitFn, btnLabel, isDisabled = false, msg = {msg: ''}, btnWidth}) => {

    /*
    * msg: {positive: true, msg: ''} // confirmation/erreur. Non affiché si msg === ''
    * elements: [{id: idUniqueAuForm, type: 'password/text', label:'', init: '', slot: UnComponent, focus: bool, choices, validation: [ type: 'minLength', val:8}]}]
    * */

    const checkIfValid = (element, val) => {
        if (element.type === 'checkbox' && !element.validation) return true;
        if (element.type === 'select' && val !== null) return true;
        if (!element.optionnal && !val) return false;
        if (!element.validation) return true;
        return !element.validation.some((validation, i) => {
            switch(validation.type) {
                case 'requiredCheckbox':
                    return !val;
                case 'minLength':
                    return val.length < validation.val;
                case 'alphaNum':
                    return !val.match(/[a-zA-z]+/) || !val.match(/[0-9]+/);
                case 'email':
                    return !val.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                case 'sameValue':
                    return !!elementsState && val !== elementsState[validation.val].text;
                default:
                    return true;
            }
        });
    };

    const getInitState = () => elements.reduce((acc, elem) => {
        const isValid = checkIfValid(elem, elem.init);
        acc[elem.id] = {
            text: elem.init,
            valid: isValid
        };

        acc.valid = isValid ? acc.valid : false;

        return acc;
    }, {valid: true});

    const [elementsState, setElementsState] = useState(getInitState());

    const submitHandler = e => {
        e.preventDefault();
        submitFn(returnOnlyData());
    };

    const returnOnlyData = () => {
        const newData = {};
        for(const prop in elementsState) {
            newData[prop] = elementsState[prop].text;
        }
        return newData;
    };

    const checkOtherFieldValidity = (currentValidId) => {
        return !Object.keys(elementsState).filter((k) => k !== 'valid').some((k) => k !== currentValidId && !elementsState[k].valid);
    };

    const updateHandler = (e, element) => {
        const val = element.type === 'checkbox' ? e.target.checked : e.target.value;
        const isValid = checkIfValid(element, val);
        setElementsState(elem => ({
            ...elem,
            [element.id] : {...elem[element.id], text:val, valid: isValid},
            valid: !isValid ? false : checkOtherFieldValidity(element.id)
        }));
    };

    return (
        <div>
            <form onSubmit={submitHandler}>
                <Fieldset disabled={isDisabled}>
                    {elements.map((element, i) => (
                    <FormElement checkValidityFn={() => checkIfValid(element, elementsState[element.id].text)} index={i}
                                 focus={element.focus} tip={element.tip} key={element.id} type={element.type}
                                 label={element.label} uniqId={element.id} text={elementsState[element.id].text}
                                 onChangeFn={(e) => updateHandler(e, element)} slotElement={element.slot} showSlotCondition={element.showSlotCondition}
                                 choices={element.choices}/>
                ))}
                    <ButtonWrapper><Button minwidth={btnWidth} disabled={isDisabled || !elementsState.valid}
                               primary={1}>{btnLabel}</Button>
                        {msg.text !== '' && (
                            <span className={`msg ${msg.positive ? 'positif' : 'negatif'}`}></span>
                        )}</ButtonWrapper>
                </Fieldset>
            </form>
            {msg.msg && <Margin margintop="1em"><DisplayError msg={msg}>Message d'erreur?</DisplayError></Margin>}
        </div>
    );
};

Form.propTypes = {
    submitFn: PropTypes.func.isRequired,
    btnLabel: PropTypes.string.isRequired,
    isDisabled : PropTypes.bool,
    msg: PropTypes.shape({
        msg: PropTypes.any,
        positive: PropTypes.bool
    }),
    choices: PropTypes.array, // pour type select
    btnWidth: PropTypes.string,
    elements: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            type: PropTypes.string,
            label: PropTypes.any,
            init: PropTypes.any,
            slot: PropTypes.any,
            showSlotCondition: PropTypes.func, //fonction pour afficher ou non slot
            focus: PropTypes.bool,
            validation: PropTypes.arrayOf(
                PropTypes.shape({
                    type: PropTypes.string,
                    val: PropTypes.any
                })
            ),
            optionnal: PropTypes.bool
        })
    ).isRequired
    /* elements: [{id: idUniqueAuForm, type: 'password/text', label:'', init: '', slot: UnComponent, focus: bool, validation: [ type: 'minLength', val:8}]}]*/
};

export default Form;
/*
{elements, submitFn, btnLabel, isDisabled = false, msg = {msg: ''}, btnWidth
* */

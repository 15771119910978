import {BigHeading, SectionDescription} from "../../styles/ui";
import {usePage} from "../../hooks";
import React, {useEffect} from "react";
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {Helmet} from "react-helmet-async";
import {colors} from "../../styles/constants";


const Wrapper = styled.div`
  font-weight: normal;
  
  font-size: .81em;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    font-size: .71em;
  }
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    font-weight: bold;
  }
`;

const Terms = () => {
    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Entente d'utilisation</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            <Wrapper>
                <header>
                    <BigHeading marginbottom=".2em">Entente sur les conditions d’utilisation de Snowclass</BigHeading>
                    <SectionDescription textalign="justify" fontsize="1.3em"><em>La présente constitue une entente
                        légale entre vous (l’utilisateur final ou l’organisation que vous représentez ou pour laquelle
                        vous travaillez) et Snowclass (propriété de Emerging Playgrounds Inc). Afin de refléter les
                        conditions d’utilisation les plus récentes, cette entente peut faire l’objet de mises à jour
                        ponctuelles.</em></SectionDescription>
                </header>
                <article>
                    <BigHeading margintop="1em" fontsize="2.7em" marginbottom=".2em">Accès et utilisation du site
                        web</BigHeading>
                    <SectionDescription textalign="justify" fontsize="1.3em">Les utilisateurs de 13 ans et moins doivent
                        avoir l’autorisation d’un parent, d’un tuteur légal ou d’un enseignant afin de créer un compte
                        utilisateur Snowclass et de l’utiliser.<br/>
                        Si vous vous êtes créé un compte utilisateur ou si votre enseignant ou votre tuteur légal a créé
                        un compte utilisateur pour vous et que vous utilisez Snowclass, cela signifie que vous avez lu,
                        compris et accepté les conditions d’utilisation de cette entente.
                    </SectionDescription>
                </article>

                <article>
                    <BigHeading margintop="1em" fontsize="2.7em" marginbottom=".2em">Guide de bonne conduite sur
                        Snowclass</BigHeading>
                    <SectionDescription textalign="justify" fontsize="1.3em">Il est interdit d’exprimer, d’alimenter ou
                        décrire des propos offensants sur Snowclass par le truchement de zones éditables ou toute autre
                        plateforme où l’utilisateur peut s’exprimer. Si vous utilisez un langage abusif, contrevenez à
                        une loi, ou insultez ou offensez d’autres utilisateurs, Snowclass se réserve le droit d’aviser
                        vos parents, votre tuteur légal ou votre enseignant. Snowclass peut supprimer votre compte si
                        vous ne respectez pas cette entente.</SectionDescription>
                </article>

                <article>
                    <BigHeading margintop="1em" fontsize="2.7em" marginbottom=".2em">Types de membre</BigHeading>
                    <SectionDescription textalign="justify" fontsize="1.3em">Les comptes payants pour les utilisateurs
                        sont offerts par le truchement d’un abonnement. Le service commence à être offert dès que
                        l’abonnement est activé. Le service est accessible depuis n’importe quel ordinateur ou tablette
                        branché à Internet et qui remplit les exigences minimales de Snowclass. Un compte d’abonné
                        inclut deux profils utilisateurs, un troisième et un quatrième utilisateur peuvent être ajoutés,
                        moyennant un montant annuel supplémentaire non remboursable. Tous les comptes d’enseignant ont
                        les mêmes droits qu’un compte d’élève, ce qui leur permet d’avoir accès aux outils
                        administratifs pour gérer leur propre classe. Snowclass fournit un soutien technique par
                        courriel. Snowclass ne fournit pas d’aide pédagogique, veuillez demander de ce type d’aide
                        auprès des enseignants ou tuteurs qualifiés.</SectionDescription>
                </article>

                <article>
                    <BigHeading margintop="1em" fontsize="2.7em" marginbottom=".2em">Le service</BigHeading>
                    <SectionDescription textalign="justify" fontsize="1.3em">Snowclass est accessible en tout temps,
                        mais parfois le site Web n’est pas disponible dû à des mises à jour ou à des ennuis techniques.
                        Snowclass n’est pas responsable en cas d’interruption de service ou de mise à jour. Snowclass
                        essaie d’effectuer les mises à jour lorsque l’affluence de visites sur le site Web est à son
                        plus bas. Snowclass se réserve le droit d’ajouter, de supprimer et de modifier tout le contenu
                        qui se trouve sur l’application et le site Web de Snowclass. Snowclass ne peut pas être tenu
                        responsable des fautes, des erreurs typographiques et des bogues. Si vous faites part de ce
                        genre de problèmes, Snowclass fera de son mieux pour corriger promptement la situation. Chaque
                        utilisateur créé dans le système est propriétaire de son propre compte. Nous ne supprimons rien
                        à moins qu’un utilisateur en fasse la demande ou après un délai d’expiration de l’abonnement de
                        180 jours.</SectionDescription>
                    <SectionDescription textalign="justify" fontsize="1.3em">Exigences : une connexion Internet, une
                        version de l’App à jour (PC, Mac, Android ou iOS)</SectionDescription>
                </article>

                <article>
                    <BigHeading margintop="1em" fontsize="2.7em" marginbottom=".2em">Propriété
                        intellectuelle</BigHeading>
                    <SectionDescription textalign="justify" fontsize="1.3em">Tous les droits de marque de commerce,
                        d’auteur et de propriété intellectuelle liés à Snowclass sont la propriété de Emerging
                        Playgrounds qui en fait l’exploitation. Vous ne pouvez ni copier ni utiliser le matériel, la
                        structure, le menu, l’apparence ou les caractères qui apparaissent sur Snowclass sans le
                        consentement explicite de Snowclass.</SectionDescription>
                </article>

                <article>
                    <BigHeading margintop="1em" fontsize="2.7em" marginbottom=".2em">Responsabilité
                        maximale</BigHeading>
                    <SectionDescription textalign="justify" fontsize="1.3em">Vous indemniserez Emerging Playgrounds, ses
                        directeurs, ses agents et ses employés de toute responsabilité, y compris des frais et
                        honoraires de justice, pour n’importe quelle réclamation ou demande faite par un tiers résultant
                        de votre accès au Site ou de l’utilisation des Services, de votre violation de cette entente, ou
                        de l’infraction par vous, ou par un quelconque tiers utilisateur de votre compte, des droits de
                        propriétés intellectuelles ou autres droits de toute personne ou entité. La responsabilité de
                        Emerging Playgrounds aux termes de cette entente ne devra en tout état de cause pas dépasser un
                        montant égal aux frais payables mensuellement (frais annuels divisés par 12), ou s’élever à plus
                        que le total de trois paiements mensuels (frais annuels divisés par 4). </SectionDescription>
                    <SectionDescription textalign="justify" fontsize="1.3em">Les abonnements annuels, annulés pendant
                        leur période de validité, ne seront pas remboursés par Emerging Playgrounds, mais le compte sera
                        accessible jusqu’à la fin de la période prévue par l’entente.</SectionDescription>
                </article>

                <article>
                    <BigHeading margintop="1em" fontsize="2.7em" marginbottom=".2em">Droit applicable</BigHeading>
                    <SectionDescription marginbottom="1em" textalign="justify" fontsize="1.3em">Cette entente est régie
                        par les lois applicables au Québec, Canada. Tout différent, toute controverse ou réclamation
                        découlant de cette entente ou une violation de ladite entente, incluant son interprétation, son
                        exécution ou sa résolution, devra se régler au Québec, Canada.</SectionDescription>
                </article>

                <footer>
                    <SectionDescription textalign="justify" fontsize="1.3em">Snowclass.com est une marque de Emerging
                        Playgrounds Inc.<br/>
                        Copyright © 2009-2021 Emerging Playgrounds - Tous droits réservés.
                    </SectionDescription>
                </footer>
            </Wrapper></>
    );
};

export default Terms;

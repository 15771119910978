import styled from "styled-components";
import {Link} from "react-router-dom";

export const StyledThumbnail = styled.img`
  border-radius: 50%;
  width: 57px;
  height: 57px;
  flex-grow: 0;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.xsmall}) {
    width: ${p => p.imgWidth || 80}px;
    height: ${p => p.imgWidth || 80}px;
  }
`;

export const AddUserBtn = styled.button`
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  font-size: .9375em;
  padding: .87em 0;
  cursor:pointer;
  background: ${p => p.theme.colors.dark};
  border:none;
  outline-color: ${p => p.theme.colors.formBorder};
  border-radius: .49em;
  color: #fff;
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  
  &:disabled {
    background-color: ${p => p.theme.colors.textField};
    color: ${p => p.theme.colors.darkGray};
    cursor: default;
  }
  
  &:not(:disabled):hover {
    //color: #969696;
    background: ${p => p.theme.colors.secondary};
  }
`;

export const StyledLink = styled(Link)`
  &:link, &:visited {
    color: ${p => p.theme.colors.primary};
  }
  &:hover, &:active {
    color: ${p => p.hovercolor || '#000'};
  }
`;

export const HighlightMessage = styled.div`
  background-color: ${p => p.theme.colors.dark};
  color: #fff;
  text-align: ${p => p.align || 'center'};
  border-radius: .4em;
  padding: .3em 0.6em;
  
  a {
    text-decoration: underline;
    
    &:link, &:visited {
      color: #fff;
    }
    
    &:hover, &:active {
      color: ${p => p.theme.colors.secondary};
    }
  }
`;

export const AlertMessage = styled.h4`
  font-weight: normal;
  font-size: .85em;
  text-align: center;
  border-radius: .54em;
  padding: .62em;
  font-weight: 600;
  margin-bottom: 2.4em;
  background-color: ${p => p.positive ? p.theme.colors.secondary : p.theme.colors.danger};
  font: ${p => p.theme.fonts.text};
  color: #fff;
  transform: translateX(0%);  
  
  &.fly-enter{
    transform: translateX(200%);
  }
  
  &.fly-enter-active {
    transform: translateX(0%);
    transition: transform ease-out .2s;
  }
  
  &.fly-exit {
    transform: translateX(0%);
  }
  
  &.fly-exit-active {
    transform: translateX(-200%);
    transition: transform ease-in .2s;
  }
  
  a {
    text-decoration: underline;
    
    &:link, &:visited {
      color: #fff;
    }
    
    &:hover, &:active {
      color: #000;//${p => p.theme.colors.secondary};
    }
  }
`;

export const DeleteConfirmation = styled.div`
  background: ${p => p.theme.colors.textField};
  padding: 1em;
  border-radius: .52em;
  
  .buttonsWrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-top: .9em;
      
      &>* {
        flex: 1;
        min-width: auto;
      }
      
      & > :last-child {
          margin-top: .5em;
      }
      
      
      @media screen and (min-width: ${p => p.theme.sizes.media.xsmall}) {
        flex-direction: row;
        align-items: flex-end;
        
        & > :last-child {
          margin-left: .5em;
          margin-top: 0;
        }
      }
  }
`;

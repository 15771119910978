import React, {useEffect} from 'react';
import {FreeAppDownload, FullHeader, ListePlans, Mountain, Tree} from "../../components";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {
    Margin,
    ZIndex,
    HomeSection,
    SectionWrapper,
    ColWrapper, SectionTitle, SectionDescription, Button, StyledLink
} from "../../styles/ui";
import {colors} from "../../styles/constants";
import {usePage} from "../../hooks";
import HomeFooter from "../../components/HomeFooter/HomeFooter";
import {Helmet} from "react-helmet-async";

const SectionImg = styled.img`
  margin: 1.2em 0;
  
  @media screen and (min-width:${p => p.theme.sizes.media.large}) {
    margin: 0 1.2em;
    width: ${p => p.width || '100%'};
  }
  width: 70%;  
`;

const LandscapeWrapper = styled.div`
  display:inline-block;
  position: relative;
  z-index:2;
`;

const CallToActionBtn = styled(Button)`
  font-size: 2.8em;
  max-width: 400px;
`;

const DownloadWrapper = styled.div`
  display:flex;  
  justify-content: center;
  margin-top: 2em;
  //margin-left: -5%;
  //margin-right: -5%;
  //
  &>* {
    max-width: 426px;
  }
`;

const Home = () => {

    // const [showCookieMsg, setShowCookieMsg] = useState(false);
    //
    // useEffect(() => {
    //     if (!localStorage.getItem('hideCookieMsg')) {
    //         setShowCookieMsg(true);
    //     }
    // }, []);
    //
    // const hideCookieMsgHandler = () => {
    //   localStorage.setItem('hideCookieMsg', '1');
    //   setShowCookieMsg(false);
    // };

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass: apprendre est un jeu d'enfant.</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>

            <FullHeader/>

            {/*{showCookieMsg && <CookiesMsg closeFn={hideCookieMsgHandler}>Avec votre accord, nous utilisons des cookies ou technologies similaires pour stocker et accéder à des informations personnelles comme votre visite sur ce site.</CookiesMsg>}*/}

            <main>

                <HomeSection color={colors.paparman}>
                    <SectionWrapper>
                        <ColWrapper width="55%">
                            <SectionTitle>Bienvenue à la classe de neige!</SectionTitle>
                            <SectionDescription>Jeux éducatifs pour enrichir le travail de révision et les devoirs à la maison des élèves du primaire.</SectionDescription>
                        </ColWrapper>
                        <ColWrapper><SectionImg src={`${process.env.PUBLIC_URL}/img/img-snowman-illustration.png`} alt="Snowclass"/></ColWrapper>
                    </SectionWrapper>
                </HomeSection>

                <HomeSection color={colors.primary}>
                    <SectionWrapper>
                        <ColWrapper width="50%"><SectionImg src={`${process.env.PUBLIC_URL}/img/img-allgames.png`} alt="Activités Snowclass"/></ColWrapper>
                        <ColWrapper width="40%"><SectionTitle>Des jeux éducatifs</SectionTitle>
                            <SectionDescription>Conçu pour et par les enseignants pour répondre aux objectifs du
                                ministère de l'éducation.</SectionDescription></ColWrapper>
                    </SectionWrapper>
                </HomeSection>

                <HomeSection color={colors.paparman}>
                    <SectionWrapper>
                        <ColWrapper width="40%">
                            <Margin marginbottom="1em"><SectionTitle>Enseigner sur mesure</SectionTitle></Margin>
                            <SectionDescription>Une préparation simple et rapide. Une mise en ligne instantanée. Votre classe évolue à votre rythme et s'adapte à vos besoins.</SectionDescription></ColWrapper>
                        <ColWrapper width="53%"><SectionImg src={`${process.env.PUBLIC_URL}/img/img-class.png`} alt="Planification d'activités"/></ColWrapper>
                    </SectionWrapper>
                </HomeSection>

                {/*<HomeSection color={colors.paparman}>*/}
                {/*    <SectionWrapper paddingtop="1em">*/}
                {/*        <ColWrapper width="40%">*/}
                {/*            <Margin marginbottom="1em"><SectionTitle>Dynamique et stimulant!</SectionTitle></Margin>*/}
                {/*            <SectionDescription>De nouvelles activités ajoutées régulièrement.</SectionDescription></ColWrapper>*/}
                {/*        <ColWrapper width="53%"><SectionImg src={`${process.env.PUBLIC_URL}/img/tobotable.png`} alt="Planification d'activités"/></ColWrapper>*/}
                {/*    </SectionWrapper>*/}
                {/*</HomeSection>*/}

                <HomeSection color={colors.primary}>
                    <SectionWrapper>
                        <ColWrapper><SectionImg src={`${process.env.PUBLIC_URL}/img/img-famille-illustration.png`} alt="Famille Snowclass"/></ColWrapper>
                        <ColWrapper basis="50%" marginleft="10%"><SectionTitle>Pour toute la famille</SectionTitle>
                            <SectionDescription>De deux à quatre profils par compte, chacun avec ses classes et activités.</SectionDescription></ColWrapper>
                    </SectionWrapper>
                </HomeSection>


                <HomeSection color={colors.paparman}>
                    <SectionWrapper>
                        <ColWrapper basis="53%"><SectionTitle>Partager le plaisir d'apprendre et d'enseigner!</SectionTitle>
                            <SectionDescription>Chaque membre Snowclass gère sa propre classe et peut y inviter d'autres membres!</SectionDescription></ColWrapper>
                        <ColWrapper><SectionImg src={`${process.env.PUBLIC_URL}/img/img-share.jpg`} alt="Classe Snowclass"/></ColWrapper>
                    </SectionWrapper>
                </HomeSection>

                <HomeSection color={colors.primary}>
                    <SectionWrapper padding="6.95em">
                        <ColWrapper><SectionImg src={`${process.env.PUBLIC_URL}/img/img-multi-devices.png`} alt="Multiplateformes"/></ColWrapper>
                        <ColWrapper basis="60%" marginleft="10%"><SectionTitle>Accessible partout!</SectionTitle>
                            <SectionDescription>Téléchargez et installez Snowclass sur tous vos appareils (PC, Mac, Chromebook, iOS et Android).</SectionDescription></ColWrapper>
                    </SectionWrapper>
                </HomeSection>

                <HomeSection color={colors.paparman}>
                    <SectionWrapper direction="column" align="center">
                        <ColWrapper>
                            <SectionTitle textalign="center" margin=".11em">Entrez dans l'univers Snowclass!</SectionTitle>
                            <SectionDescription textalign="center">Devenir membre</SectionDescription>


                        </ColWrapper>

                        <DownloadWrapper>
                            <FreeAppDownload>
                                Téléchargez l'app Snowclass et entrez le nom d'utilisateur 'SNOW' pour visiter la classe test!&nbsp;→
                            </FreeAppDownload>
                        </DownloadWrapper>

                        <ColWrapper mobilewidth="100%">
                            <LandscapeWrapper>
                                <Margin margintop="2.8em">
                                    <ZIndex index="5"><ListePlans fixed color="#fff" bgColor={colors.dark}/></ZIndex>
                                </Margin>

                                <Mountain originv="bottom" originh="left" zindex="1" bottom="15px" right="-425px" scale=".63"/>
                                <Mountain originv="bottom" originh="right" zindex="1" bottom="15px" left="-486px" scale=".5"/>
                                <Mountain originv="bottom" originh="right" zindex="1" bottom="15px" left="-620px" scale=".4"/>

                                <Tree big left="-133px" bottom="0" scale=".45" originv="bottom" originh="left"/>
                                <Tree big left="-188px" bottom="10px" scale=".43" originv="bottom" originh="left" zindex="3"/>
                                <Tree big right="-192px" bottom="-13px" scale=".5" originv="bottom" originh="right" zindex="3"/>
                            </LandscapeWrapper>
                        </ColWrapper>
                    </SectionWrapper>
                    <SectionWrapper direction="column" justify="center" paddingtop="0" paddingbottom="5em">
                        <CallToActionBtn minwidth="auto" hovercolor={colors.dark} hovertextcolor="#fff" as={Link} to="/register">Débuter</CallToActionBtn>
                        <Margin margintop="2em"><SectionDescription textalign="center">Vous êtes une école? <StyledLink
                            hovercolor={colors.secondary} to="/ecoles">Cliquez ici</StyledLink></SectionDescription></Margin>
                    </SectionWrapper>
                </HomeSection>
            </main>

            <HomeFooter/>

        </>
    );
};

export default Home;

import React, {useContext, useEffect, useState, createRef} from 'react';
import {AccountContext} from "../../context";
import {UserFullVignette} from "../../components";
import {shallowEqual} from "../../utils";
import * as Account from '../../models/account';
import * as User from '../../models/users';
import firebase from 'firebase/app';
import {AddUserBtn, P, PageHeading} from "../../styles/ui";
import styled from 'styled-components';
import {Helmet} from "react-helmet-async";
import {usePage, useSubscription} from "../../hooks";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {AccountStatus} from "../../models/account";
import {colors} from "../../styles/constants";

const StyledList = styled.div`
  padding-top: 1.8em;
`;

const UserAnimate = styled.div`
  overflow: hidden;
  transition: all .2s ease-in-out, transform .2s ease-in-out, opacity .2s ease-in-out;

  &.appear-enter {
    max-height: 0;
  }
  
  &.appear-enter-active {
    max-height: 200px;
  }
  
  &.appear-exit {
    transform: translateX(0%);
    opacity: 1;
    max-height: 200px;
  }
  
  &.appear-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    max-height: 0;
  }
`;


const UsersInfo = () => {

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const {PLAN_ANNUEL_PLUS_ID, PLAN_ANNUEL_ID} = useSubscription();

    // const maxNbUsers = process.env.REACT_APP_MAX_NB_USERS;
    const {account, accountRef} = useContext(AccountContext);
    const [nextId, setNextId] = useState(account[Account.nextNo]);
    const [users, setUsers] = useState(account[Account.users] ? [...account[Account.users]] : [{[User.prenom]: '', [User.nom]: '', [User.id]: nextId, c:1}]);
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState(account[Account.users] ? account[Account.users].map(u => ({msg: '', positive: true})): {msg: '', positive: true});
    const [openVignettes, setOpenVignettes] = useState(users.map(() => false));

    const onToggleVignette = (i, isOpen) => {
        if (isOpen) {
            setOpenVignettes(vs => vs.map((v, index) => i === index));
        } else {
            setOpenVignettes(vs => vs.map((v, index) => i === index ? false: v));
        }
    };

    const updateUser = (val, id, prop) => setUsers(users => users.map(u => u[User.id] === id ? {...u, [prop]: val} : u));

    const saveUser = async (uid, index) => {
        if (isLoading) return;
        const after = users.find(u => u.id === uid);
        const {prenom, nom, g, c, id} = after;
        const modifiedUser = {
            prenom, nom, c, id,
            ...g && {g}
        };

        let toSaveUsers;

        if (account[Account.users]) {
            // check if exists before
            const before = account[Account.users].find(u => u[User.id] === uid);
            // check if has changed
            if (before && shallowEqual(before, after)) {
                return;
            }

            toSaveUsers = before ? account[Account.users].map(u => u[User.id] === uid ? modifiedUser : u) : [...account[Account.users], modifiedUser];
        } else {
            toSaveUsers = [modifiedUser];
        }

        setIsLoading(true);
        setMsg(msgs => msgs.map(() => ({msg: '', positive: true})));

        const maxUId = toSaveUsers.reduce((acc, current) => Math.max(current.id, acc), 0);
        try {

            const increment = firebase.firestore.FieldValue.increment(1);

            await accountRef.update({
                [Account.users]: toSaveUsers,
                [Account.updatedAt]: firebase.firestore.FieldValue.serverTimestamp(),
                [Account.userUpdateCount]: increment,
                ...maxUId >= account[Account.nextNo] && {[Account.nextNo]: maxUId + 1}
            });
            setMsg(msgs => msgs.map((m,i) => i === index ? {msg: 'Sauvegardé!', positive: true} : m));
        } catch(e) {
            setMsg(msgs => msgs.map((m,i) => i === index ? {msg: 'Erreur', positive: false} : m));
        }
        setIsLoading(false);
    };

    const ifEmpty = u => !u[User.prenom] || !u[User.prenom].trim() || !u[User.nom] || !u[User.nom].trim();
    const checkIfAnyEmpty = users.some(u => ifEmpty(u));

    const addUser = () => {

        // const maxUID = account[Account.users].reduce((acc, current) => Math.max(current.id, acc), 0);

        setUsers(users => [...users,{[User.prenom]: '', [User.nom]: '', [User.id]: nextId, c:1}]);
        setNextId(id => id + 1);
        setMsg(msgs => [...msgs, {msg: '', positive: true}]);
        setOpenVignettes(v => [...v, false]);
    };

    const deleteHander = async id => {
        const wasSaved = account[Account.users].find(u => u[User.id] === id);
        if (wasSaved){
            const toSaveUsers = account[Account.users].filter(u => u[User.id] !== id);
            setIsLoading(true);
            try {
                const increment = firebase.firestore.FieldValue.increment(1);
                await accountRef.update({
                    [Account.users]: toSaveUsers,
                    [Account.updatedAt]: firebase.firestore.FieldValue.serverTimestamp(),
                    [Account.userUpdateCount]: increment
                });
                setUsers(users => users.filter((u, i) => {
                    const foundIt = u[User.id] === id;
                    if (foundIt) {
                        setMsg(msgs => msgs.filter((m, index) => index !== i));
                        setOpenVignettes(vs => vs.filter((v, index) => index !== i));
                    }
                    return !foundIt;
                }));
            } catch(er) {
                for(let i = 0; i < users.length; i++) {
                    if (users[i][User.id] === id) {
                        setMsg(msgs => msgs.map((m,index) => i === index ? {positive: false, msg: 'Erreur.'} : m));
                    }
                }
            }
            setIsLoading(false);
        } else {
            if (id === nextId - 1) {
                setNextId(id);
            }
            setUsers(users => users.filter(u => u[User.id] !== id));
        }
    };


    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Profils</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            <PageHeading>Profils</PageHeading>
            <P>Modifiez les profils du compte.</P>
            <StyledList>
                <TransitionGroup>
                {users.map((u, i) => {
                    const nodeRef = createRef(null);
                    return (
                        <CSSTransition nodeRef={nodeRef} key={u[User.id]} timeout={200} classNames="appear">
                            <UserAnimate ref={nodeRef}>
                                <UserFullVignette
                                    toggleVignetteFn={onToggleVignette} prenom={u[User.prenom]}
                                    nom={u[User.nom]} nbUsers={users.length} index={i} isOpen={openVignettes[i]}
                                    c={u[User.c]} genre={u[User.g]} id={u[User.id]}
                                    deleteFn={e => deleteHander(u.id)}
                                    updateNomFn={e => updateUser(e.target.value, u[User.id], User.nom)}
                                    updatePrenomFn={e => updateUser(e.target.value, u[User.id], User.prenom)}
                                    updateClasseFn={e => updateUser(parseInt(e.target.value), u[User.id], User.c)}
                                    updateGenreFn={e => updateUser(e.target.value, u[User.id], User.g)}
                                    saveFn={e => saveUser(u[User.id], i)}
                                    msg={msg[i]}
                                    isLoading={isLoading}
                                    focus={false}
                                />
                            </UserAnimate>
                        </CSSTransition>
                    )
                })}
                </TransitionGroup>
                {((process.env.REACT_APP_IS_BETA_ON === '1' && users.length < process.env.REACT_APP_MAX_NB_USERS) ||
                    (account.status === AccountStatus.PROF_ACTIF.label && users.length < process.env.REACT_APP_MAX_NB_PLUS_USERS) ||
                    (account.activePlan === PLAN_ANNUEL_ID && users.length < process.env.REACT_APP_MAX_NB_USERS) ||
                    (account.activePlan === PLAN_ANNUEL_PLUS_ID && users.length < process.env.REACT_APP_MAX_NB_PLUS_USERS) ||
                    (account.status === AccountStatus.PROF_ACTIF.label && users.length < process.env.REACT_APP_MAX_NB_USERS)
                    ) && (
                    <AddUserBtn onClick={addUser} disabled={checkIfAnyEmpty}>+ Ajouter un autre
                        profil</AddUserBtn>)}

                {/*{process.env.REACT_APP_IS_BETA_ON !== '1' && users.length == process.env.REACT_APP_MAX_NB_USERS && (!account.activePlan || account.activePlan === process.env.REACT_APP_PLAN_ANNUEL_ID) && (*/}
                {/*    <HighlightMessage>Vous avez atteint le nombre maximum de profils pour ce type de compte. Pour pouvoir en ajouter davantages de profils, veuillez <Link to="/subscription">modifier le type d'abonnement</Link>.</HighlightMessage>)}*/}
            </StyledList>

        </>
    );
};

export default UsersInfo;

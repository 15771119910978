import styled from 'styled-components';

export const Button = styled.button`  
  display:inline-block;
  min-width: ${p => p.minwidth || '50%'};
  font-weight: bold;
  font-size: ${p => p.fontsize || p.theme.sizes.text.p}; //25
  line-height: 1.36em;
  border-radius: .28em;
  padding: .56em 1.4em;  
  text-align: center;
  border: none;
  outline-color: ${p => p.shadowcolor || p.theme.colors.secondary};
  box-shadow: 0 3px 0 ${props => props.shadowcolor || props.theme.colors.secondary};
  background-color: ${props => props.color || props.theme.colors.primary};
  color: ${p => p.textcolor || '#fff'};
  transition: color .2s ease-in-out, background-color .2s ease-in-out;
  
  &:disabled {
    background-color: ${p => p.disabledcolor || p.theme.colors.thumbBorder};
    box-shadow: 0 3px 0 ${props => props.disabledshadowcolor || props.theme.colors.darkGray};
  }
  
  &:not(:disabled) {
    cursor: pointer;
  }
  
  &:not(:disabled):hover {
    background: ${p => p.hovercolor || p.theme.colors.lightPrimary};
    color: ${p => p.hovertextcolor || '#fff'};
  }
`;

export const HeaderButton = styled(Button)`
  font-size: ${p => p.theme.sizes.text.h2}; //25
  box-shadow: 0 3px 0 ${props => props.theme.colors.dark};
  background-color: #fff;
  color: ${props => props.theme.dark};
  min-width: 200px;
  
  &:hover {
    background: #fff;
  }
`;

export const ButtonNoShape = styled(HeaderButton)`
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  
  &:hover {
    background: transparent;
  }
`;

export const TextButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  display: inline-block;
  color: ${p => p.theme.colors.lightGray};
  cursor: pointer;
  font-size: ${p => p.fontsize || p.theme.sizes.text.p};
  font-family: 'Roboto', sans-serif;
  line-height: 1.33em;

  &:hover {
    color: #000;
  }
`;

export const ToggleButton = styled.button`
  padding: .2em .4em;
  cursor: pointer;
  color: ${p => p.theme.colors.formBorder};
  font-weight: bold;
  font-size: 1.3em;
  border: none;
  outline: none;   
  //outline-color: ${p => p.theme.colors.lightGray};
  background: transparent;
  transition: .3s ease-in-out transform;
  
  transform: rotateZ(${p => p.isOpen ? '180deg' : '0'});
`;

import {useState, useContext, useCallback} from 'react';
import {functions, logEvent} from "../services/firebase";
import {useStripe} from "@stripe/react-stripe-js";
import {AccountContext, devContext} from "../context";
import {
    cancelRenewEvent,
    cancelRenewEventParams,
    purchaseEvent,
    purchaseEventParams,
    //upgradePlanEvent
} from "../models/anlytics";

export const useSubscription = () => {
    const [error, setError] = useState({msg: '', positive: false});

    const {isTesting} = useContext(devContext);
    const PLAN_ANNUEL_ID = isTesting ? process.env.REACT_APP_TEST_PLAN_ANNUEL_ID : process.env.REACT_APP_PLAN_ANNUEL_ID;
    const PLAN_ANNUEL_PLUS_ID = isTesting ? process.env.REACT_APP_TEST_PLAN_ANNUEL_PLUS_ID : process.env.REACT_APP_PLAN_ANNUEL_PLUS_ID;

    const {subscription, setSubscriptionUpdate, account, subscriptionLoading} = useContext(AccountContext);
    const [isLoading, setIsLoading] = useState({
        portalUrl: false,
        listCards: false,
        unsubscribe: false,
        subscribe: false
    });

    const subscribeFn = functions.httpsCallable('createSubscription');
    const cancelSubFn = functions.httpsCallable('cancelSubscription');
    // const upgradeSubFn = functions.httpsCallable('upgradeSubscription');
    // const listCardsFn = functions.httpsCallable('listPaymentMethods');
    const getPortalUrlFn = functions.httpsCallable('getCustomerPortalUrl');

    const stripe = useStripe();

    const subscribeHandler = useCallback(async (cardElement, selectedPlan) => {
        setError({msg: '', positive: false});
        setIsLoading(prev => ({...prev, subscribe: true}));
        const {paymentMethod, error} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        });

        if (error) {
            setError({positive: false, msg: error.message || 'Les informations soumises sont invalides', display:true});
            setIsLoading(prev => ({...prev, subscribe: false}));
            return;
        }

        let sub;
        try {
            sub = await subscribeFn({plan: selectedPlan, paymentMethod: paymentMethod.id});
            if (!sub.data.plan || sub.data.code || sub.data.decline_code) {
                throw sub?.data;
            }
        } catch(e) {
            setIsLoading(prev => ({...prev, subscribe: false}));
            if (!e.code) {
                setError({positive: false, msg: 'Une erreur de traitement s\'est produite. Veuillez recommencer plus tard ou communiquez avec nous', display:true});
                return;
            }
            setError({msg: e, positive: false});
            return;
        }

        const {latest_invoice} = sub.data;
        if (latest_invoice.payment_intent) {
            const {client_secret, status} = latest_invoice.payment_intent;

            if (status === 'requires_action') {
                const {error: confirmationError} = await stripe.confirmCardPayment(
                    client_secret
                );
                if (confirmationError) {
                    // console.log(confirmationError);
                    return;
                }
            }
            // await getSubscription();
            setIsLoading(prev => ({...prev, subscribe: false}));
        } else {
            setIsLoading(prev => ({...prev, subscribe: false}));
        }

        const value = selectedPlan === PLAN_ANNUEL_ID ? 19.99 : 24.99;
        const item = selectedPlan === PLAN_ANNUEL_ID ? 'Annuel' : 'Annuel+';

        if (!isTesting) {
            logEvent(purchaseEvent, {[purchaseEventParams.value]: value, [purchaseEventParams.items]: item});
        }

        setSubscriptionUpdate(prev => prev + 1);
        return true;
    },[logEvent, setSubscriptionUpdate, stripe, subscribeFn]);

    const unsubscribeHandler = useCallback(async () => {
        //unsubbing
        setIsLoading(prev => ({...prev, unsubscribe: true}));
        try {
            await cancelSubFn({subscriptionId: subscription.id});
            setSubscriptionUpdate(prev => prev + 1);

            const item = account.activePlan === PLAN_ANNUEL_ID ? 'Annuel' : 'Annuel+';
            logEvent(cancelRenewEvent, {[cancelRenewEventParams.items]: item});
        } catch (err) {

            setError({msg: 'Une erreur est survenue. Veuillez réessayer plus tard.', positive: false, display: true});
            return false;
        }

        setIsLoading(prev => ({...prev, unsubscribe: false}));
        return true;
        // cancelSubFn
    },[subscription, account?.activePlan, cancelSubFn, logEvent, setSubscriptionUpdate]);

    // const undoUnsubscribeHandler = useCallback(async () => {
    //     setIsLoading(true);
    //     const resp = await upgradeSubFn({isPreview: false, subscriptionId: subscription.id, plan: account.activePlan, isUpgrading: false});
    //     setSubscriptionUpdate(prev => prev + 1);
    //     setIsLoading(false);
    // }, [subscription, account]);

    // const listCreditCardsHandler = useCallback(async () => {
    //     setIsLoading(prev => ({...prev, listCards: true}));
    //     const cards = await listCardsFn();
    //     setIsLoading(prev => ({...prev, listCards: false}));
    //     return cards.data;
    // }, [subscription]);
    //

    const getPortalUrlHandler = async () => {
        if (!account.stripeCustomerId) {
            return;
        }
        setIsLoading(prev => ({...prev, portalUrl: true}));
        const resp = await getPortalUrlFn();
        setIsLoading(prev => ({...prev, portalUrl: false}));
        return resp.data;
    };


    // preview du upgrade
    // soustraire data.lines[1]/100 - Math.abs(data.lines[0].amount / 100)
    // const previewProrationHandler = useCallback(async (plan, confirmUpgrade = false) => {
    //
    //      await upgradeSubFn({isPreview: /*!confirmUpgrade*/ false, plan, subscriptionId: subscription.id, isUpgrading: true});
    //
    //      if (confirmUpgrade) {
    //          logEvent(upgradePlanEvent);
    //      }
    //
    //     //backend UpgradeSub (isPreview: true)
    //
    //     /*const items = [{
    //         id: subscription.id,
    //         price: plan
    //     }];
    //
    //     const proration_date = Math.floor(Date.now() / 1000);
    //
    //     const invoice = await stripe.retrieveUpcoming({
    //         customer: account.stripeCustomerId,
    //         subscription: subscription.id,
    //         subscription_items: items,
    //         subscription_proration_date: proration_date
    //     });
    //
    //     console.log(invoice);
    //     return invoice;*/
    // }, [subscription, logEvent, upgradeSubFn]);

    // console.log(isLoading, subscriptionLoading);

    return {
        subscription,
        // corriger doit retourner True si tous true sinon false
        isLoading: Object.values({...isLoading, subloading: subscriptionLoading}).reduce((acc, current) => {
            if (current) {
                return true;
            } else {
                return acc;
            }
        },false),
        subscribeHandler,
        unsubscribeHandler,
        //undoUnsubscribeHandler,
        //previewProrationHandler,
        // listCreditCardsHandler,
        getPortalUrlHandler,
        error,
        stripe,
        PLAN_ANNUEL_ID,
        PLAN_ANNUEL_PLUS_ID
    };

};

import React from 'react';
import styled from 'styled-components';
import {Input} from "../../styles/ui";
//import * as Sentry from '@sentry/react';

const StyledError = styled(Input)`
  color: #fff;
  background-color: ${p => p.positive ? p.theme.colors.primary : p.theme.colors.danger};
  border:none;
`;

const DisplayError = ({msg}) => {

    const showMessage = () => {
        if (msg?.msg?.code) {
            switch (msg.msg.code) {
            // auth
                case 'auth/invalid-email':
                    return 'L\'adresse courriel fournie est invalide';
                case 'auth/wrong-password':
                    return 'Le mot de passe fourni est invalide';
                case 'auth/weak-password':
                    return 'Le mot de passe doit contenir 8 caractères au minimum';
                case 'auth/user-not-found':
                    return 'Le courriel soumis ne correspond à aucun compte valide';
                case 'auth/email-already-in-use':
                    return 'Ce courriel a déjà été utilisé';

            // stripe
                case 'incorrect_number':
                    return 'Malheureusement, le numéro de carte entré est erroné.';
                case 'card_declined':
                    return 'Malheureusement, votre carte a été refusée.';
                case 'card_declined_card_not_supported':
                    return 'Malheureusement, votre carte n\'est pas prise en charge.';
                case 'expired_card':
                    return 'Malheureusement, votre carte est expirée.';
                case 'incorrect_cvc':
                    return 'Malheureusement, le code de sécurité (CVC) de votre carte est incorrect.';

                default:
                    // Sentry.captureEvent(msg.msg);
                    return 'Une erreur s\'est produite';
            }
        } else if (msg.display) {
            return msg.msg;
        } else {
            //Sentry.captureEvent(msg.msg);
            return msg.msg;
        }
    };


    return (
        <StyledError as={"div"} positive={msg.positive ? 1 : 0}>{showMessage()}</StyledError>
    );
};

export default DisplayError;

import styled, {css, keyframes} from "styled-components";

/* Animations */
export const vent1 = keyframes`
    0% {transform: rotateZ(0deg);}
    5%, 20% {transform: rotateZ(1.5deg);}
    25%, 100% {transform: rotateZ(0deg);}
`;

export const vent8 = keyframes`
    0% {transform: rotateZ(0deg) scale(.8);}
    5%, 20% {transform: rotateZ(1.5deg) scale(.8);}
    25%, 100% {transform: rotateZ(0deg) scale(.8);}
`;

export const vent75 = keyframes`
    0% {transform: rotateZ(0deg) scale(.75);}
    5%, 20% {transform: rotateZ(1.5deg) scale(.75);}
    25%, 100% {transform: rotateZ(0deg) scale(.75);}
`;

export const vent6 = keyframes`
    0% {transform: rotateZ(0deg) scale(.6);}
    5%, 20% {transform: rotateZ(1.5deg) scale(.6);}
    25%, 100% {transform: rotateZ(0deg) scale(.6);}
`;

export const vent5 = keyframes`
    0% {transform: rotateZ(0deg) scale(.5);}
    5%, 20% {transform: rotateZ(1.5deg) scale(.5);}
    25%, 100% {transform: rotateZ(0deg) scale(.5);}
`;

export const nuage1 = keyframes`
    0% {transform: translate(0%);}
    100% {transform: translate(100%);}
`;

export const nuage5 = keyframes`
    0% {transform: translate(0%) scale(.5);}
    100% {transform: translate(100%) scale(.5);}
`;

export const nuage3 = keyframes`
    0% {transform: translate(0%) scale(.3);}
    100% {transform: translate(100%) scale(.3);}
`;

export const settings = {
    centerColumnWidth: '445px',
    centerColumnHalfWidth: '222.5px',
    logoWidth: {
        mobile: '80%',
        desktop: '445px'
    },
    media: {
        small: '555px',
        medium: '700px',
        large: '834px',
        xLarge: '1280px'
    },
    colors: {
        sol: '#eefffd'
    },
    animation: {
        names: {
            vent1: 'vent1',
            vent5: 'vent5',
            vent6: 'vent6',
            vent75: 'vent75',
            vent8: 'vent8',
            nuage1: 'nuage1',
            nuage3: 'nuage3',
            nuage5: 'nuage5',
        },
        nuagesDuree: {
            nuage1: '70s',
            nuage5: '75s',
            nuage3: '100s'
        },
        anims:{
            vent1,
            vent5,
            vent6,
            vent75,
            vent8,
            nuage1,
            nuage3,
            nuage5,
        },
        duree: '15s',
        delai: '5s',
        timingFunction: 'ease-in-out'
    }
};


export const StyledNuage = styled.img`
  position: absolute;
  pointer-events: none;
  animation: ${p => css`${settings.animation.anims[p.animation]} ${settings.animation.nuagesDuree[p.animation]} 0s alternate infinite`};
  ${p => p.left && css`
    left: ${p.left};
  `}
  ${p => p.right && css`
    right: ${p.right};
  `}
  ${p => p.top && css`
    top: ${p.top};
  `}
  ${p => p.bottom && css`
    bottom: ${p.bottom};
  `}
  ${p => p.scale && css`
    transform: scale(${p.scale});
  `}
  ${p => p.zindex && css`
    z-index: ${p.zindex};
  `}
`;

export const StyledMountain = styled.img`
  z-index: ${p => p.zindex || 3};
  transform: scale(${p => p.scale || 1});
  right: -320px;
  position: absolute;
  bottom: 0;
  ${p => p.left && css`
    left: ${p.left};  
  `};
  
  ${p => p.right && css`
    right: ${p.right};
  `};
  
  ${p => p.bottom && css`
    bottom: ${p.bottom};
  `}
  
  ${p => (p.originv || p.originh) && css`
    transform-origin: ${p.originv ? p.originv : 'center'} ${p.originh ? p.originh : 'center'}
  `}
`;

export const StyledTree = styled.img`
  position: absolute;
  pointer-events: none;
  transform-origin: bottom center;
  z-index: 4;
  bottom: ${p => p.bottom};
  
  ${p => p.left && css`
    left: ${p.left};
  `}
  
  ${p => p.right && css`
    right: ${ p.right};
  `}

  ${p => p.scale && css`
    transform: scale(${p.scale});
  `}
  
  ${p => p.zindex && css`
    z-index: ${p.zindex};
  `}

  ${p => p.animation && css`
    animation: ${p => p.animation && css`${settings.animation.anims[p.animation]} ${settings.animation.duree} ${settings.animation.timingFunction} ${settings.animation.delai} infinite`};
  `}
  
  ${p => (p.originv || p.originh) && css`
    transform-origin: ${p.originv ? p.originv : 'center'} ${p.originh ? p.originh : 'center'};
  `}
`;

export const Mountain = (props) => (<StyledMountain {...props} src={`${process.env.PUBLIC_URL}/img/img-mountain.png`} alt="Montagne"/>);

export const Tree = (props) => (
    <StyledTree {...props} src={`${process.env.PUBLIC_URL}${props.big ? '/img/img-tree' : '/img/img-tree-small'}.png`} alt="Arbre"/>
);

export const Nuage = props => (<StyledNuage {...props} src={`${process.env.PUBLIC_URL}/img/img-cloud.png`} alt="Nuage"/>);

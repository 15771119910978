import React, {useContext, useEffect, useCallback, useState} from 'react';
import {FBContext} from "./fbContext";
import {firestore, functions} from "../services/firebase";
import {AccountStatus} from "../models/account";

const context = React.createContext();
const {Consumer, Provider} = context;

const AccountProvider = ({children, user}) => {

    const listSubscriptionsFn = functions.httpsCallable('listSubscriptions');
    const [subscription, setSubscription] = useState(null);
    const [subscriptionLoading, setSubscriptionLoading] = useState(true);
    //const accountRef = firestore.doc((`collections/${auth.currentUser.uid}`))
    const {useDocumentData, useCollectionData} = useContext(FBContext);
    let accountRef;
    let platformsRef;
    const [subscriptionUpdate, setSubscriptionUpdate] = useState(0); // pour forcer l'update du sub si annulation (pas de webhook)

    //useEffect(() => {
        if (user) {
            accountRef = firestore.collection('accounts').doc(user.uid);
            platformsRef = firestore.collection('platforms').where('active', '==', true);
        }
   // }, [user]);

    /*if (user){
        accountRef = firestore.collection('accounts').doc(user.uid);
        platformsRef = firestore.collection('platforms').where('active', '==', true);
    }*/


    const [account, accountLoading, accountError] = useDocumentData(accountRef);
    const [platforms, platformsLoading, platformsError] = useCollectionData(platformsRef, {idField: 'id'});

    const getSubscription = useCallback(async () => {
        setSubscriptionLoading(true);
        try {
            const subs = await listSubscriptionsFn();
            if (subs.data.data && subs.data.data.length) {
                // n'affiche que la première inscription
                setSubscription(subs.data.data[0]);
            } else {
                setSubscription(null);
            }
        } catch(err) {

        }
        setSubscriptionLoading(false);
    }, [listSubscriptionsFn]);

    const activePlan = account?.activePlan;
    const stripeCustomerId = account?.stripeCustomerId;

    useEffect(() => {
        if (account && account.status !== AccountStatus.PROF_ACTIF.label && stripeCustomerId) {
            getSubscription();
        } else {
            setSubscriptionLoading(false);
        }
    }, [account, activePlan, subscriptionUpdate, stripeCustomerId]);

    return (
        <Provider value={{account, user, accountRef, accountLoading, accountError, platforms, platformsLoading, platformsError, subscription, setSubscriptionUpdate, subscriptionLoading}}>
            {children}
        </Provider>
    );
};

export {Consumer as AccountConsumer, AccountProvider, context as AccountContext};

import React, {useContext, useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {Form, FreeAppDownload} from "../../components";
import {FBContext} from "../../context";
import {logEvent} from "../../services/firebase";
import {BorderTop, Margin, P, PageHeading, StyledLink} from "../../styles/ui";
import {Helmet} from "react-helmet-async";
import {usePage} from "../../hooks";
import {registerEvent, registerEventParams} from "../../models/anlytics";
import {colors} from "../../styles/constants";

const Register = () => {

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const {auth} = useContext(FBContext);
    const history = useHistory();

    const [error, setError] = useState({msg: '', positive: false});
    const [isLoading, setIsLoading] = useState(false);
    const signUp = ({email, password}) => {
        if (isLoading) {
            return;
        }
        setError({msg: '', positive: false});
        setIsLoading(true);
        auth.createUserWithEmailAndPassword(email, password)
            .then((_) => {
                // if (!testContext.isTesting && auth.currentUser) {
                //     auth.currentUser.sendEmailVerification({url: 'https://snowclass.com/onboarding', handleCodeInApp: false});
                // }
                logEvent(registerEvent, {[registerEventParams.method]: 'password'});
                history.replace('/confirmation');

            }).catch(er => {
            setError({msg: er, positive: false});
            setIsLoading(false);
        });
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Inscription</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>

            <Margin marginbottom="2em" margintop="1.5em"><FreeAppDownload>
                Téléchargez l'app Snowclass et entrez le nom d'utilisateur 'SNOW' pour visiter la classe test!&nbsp;→
            </FreeAppDownload></Margin>

            <PageHeading>Créer un compte</PageHeading>


            <P>Vous devez avoir un compte afin d'accéder à SNOWCLASS sur vos appareils.</P>
            <BorderTop>
                <Form btnLabel="Suivant" isDisabled={isLoading} msg={error} submitFn={signUp} elements={[
                    {id: 'email', type: 'email', label: 'Courriel', init: '', focus: true, validation: [{type: 'email'}]},
                    {id: 'password', tip: 'Doit contenir au moins un chiffre et une lettre et avoir une taille minimale de 8 caractères', type: 'password', label: 'Mot de passe', init: '', validation: [{type: 'minLength', val:8}, {type: 'alphaNum'}]}
                ]}/>
            </BorderTop>
            <BorderTop padding="1em">
                <P>Vous êtes une école? <StyledLink to="/ecoles">Cliquez ici</StyledLink></P>
            </BorderTop>
        </>
    );
};

export default Register;

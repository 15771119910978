import {useEffect, useState, useRef} from 'react';
import {AccountStatus} from "../../models/account";
import {AlertMessage} from "../../styles/ui";
import {Link} from "react-router-dom";
import {useSubscription} from "../../hooks";
import {CSSTransition} from "react-transition-group";
import styled from 'styled-components';

const Alerts = styled.div`
  overflow: hidden;
`;

const SubscriptionAlerts = ({account, positive = false}) => {

    const {getPortalUrlHandler} = useSubscription();
    const [portalUrl, setPortalUrl] = useState('');

    useEffect(() => {
        const getPortalUrl = async () => {
            const url = await getPortalUrlHandler();
            setPortalUrl(url);
        };

        if (account.status === AccountStatus.EXPIRED.label) {
            getPortalUrl();
        }
    }, [account, getPortalUrlHandler]);

    const betaOverRef = useRef(null);
    const optedOutRef = useRef(null);
    const expiredRef = useRef(null);

    if (!account) {
        return null;
    }

    return (
        <Alerts>
            <CSSTransition nodeRef={betaOverRef} unmountOnExit mountOnEnter timeout={200} in={account.status === AccountStatus.BETA_OVER.label} classNames="fly">
                <AlertMessage ref={betaOverRef} positive={positive}>Période de lancement terminée. Veuillez choisir un <Link to="/subscription">plan d'abonnement</Link>.</AlertMessage>
            </CSSTransition>

            <CSSTransition nodeRef={optedOutRef} unmountOnExit mountOnEnter timeout={5000} in={account.status === AccountStatus.OPTED_OUT.label} classNames="fly">
                <AlertMessage ref={optedOutRef} positive={positive}>Veuillez choisir un <Link to="/subscription">plan d'abonnement</Link>.</AlertMessage>
            </CSSTransition>

            <CSSTransition nodeRef={expiredRef} unmountOnExit mountOnEnter timeout={5000} in={account.status === AccountStatus.EXPIRED.label} classNames="fly">
                <AlertMessage ref={expiredRef}>Une erreur s'est produite lors du renouvellement. <a href={portalUrl} rel="noreferrer" target="_blank">Modifiez votre mode de paiement</a>.</AlertMessage>
            </CSSTransition>
        </Alerts>
    );
};

export default SubscriptionAlerts;

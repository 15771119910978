import {colors, fonts} from "../../styles/constants";
import {CSSTransition} from "react-transition-group";
import {Button, Margin, SpinnerParent} from "../../styles/ui";
import {DisplayError} from "../index";
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import Spinner from "../Spinner/Spinner";
import {createRef, useState} from "react";
import styled, {css} from "styled-components";
import {useSubscription} from "../../hooks";
import {AccountStatus} from "../../models/account";


const FormWrapper = styled(SpinnerParent)`
  transition: max-height 200ms ease-out;
  position: relative;

  &.appear-enter {
    max-height: 0;
  }
  
  &.appear-enter-active {
    max-height: 300px;
  }
  
  ${p => p.loading && css`    
    
    &::after{
      content: '';
      display: block;
      position: absolute;
      background-color: rgba(255,255,255, 0.5);
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index:10;
    }
  `}
`;

const CarsInputWrapper = styled.div`
  padding: 1em 0;
`;

const PaymentInput = styled.div`
  padding: .3em 0;
  
  label {
    font-family: ${p => p.theme.fonts.text};
    font-weight: 600;
  }
`;

const InputWrapper = styled.div`
  background-color: ${p => p.theme.colors.textField};
  border-radius: .44em;
  padding: .5em .5em;
  
  ${p => p.secured && css`
    padding-right: 3em;
    background: url('/img/lock.png') ${p => p.theme.colors.textField} no-repeat 98% center / auto 50%;
  `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    &>* {
      width: 45%;
    }
  }
`;

const BadgeStripe =  styled.h4`
  font-weight: normal;
  font-family: ${p => p.theme.fonts.text};
  color: ${p => p.theme.colors.lightGray};
  font-size: .85em;
  
  img {
    vertical-align: middle;
    width: 82px;
  }
`;

const Heading = styled.h2`
  font-size: .99em;
`;

const NewCardForm = ({show, isLoading, handleSubmitFn, error, stripe, selectedPlan, account = null, isEnvValid = true, autofocus = true}) => {

    const {PLAN_ANNUEL_ID} = useSubscription();
    const [isFormValid, setIsFormValid] = useState({no: false, date: false, cvc: false});

    const nodeRef = createRef(null);
    const cardOptions = {
        style: {
            base: {
                backgroundColor: colors.textField,
                color: colors.lightGray,
                fontWeight: 600,
                fontSmoothing: 'antialiased',
                fontFamily: fonts.text,
                "::focus": {

                },
                "::selection": {
                    color: '#fff',
                    backgroundColor: colors.primary
                },
                "::placeholder": {
                    color: colors.formBorder
                }
            },
            invalid: {
                color: colors.danger
            }
        }
    };

    const showPaymentDetails = () => {
        if (account.status === AccountStatus.ONBOARDING.label || account.status === AccountStatus.BETA_OVER.label) {
            return <h3>{`Autorisation sécuritaire par carte de crédit`} <br/><span style={{'font-size': '.9em'}}>{`${!!selectedPlan ? selectedPlan === PLAN_ANNUEL_ID ? ' (gratuit maintenant et 19.99$ +taxes au renouvellement)' : ' (gratuit maintenant et 24.99$ +taxes au renouvellement)' : ''}`}</span></h3>;
        }
        return <h3>Paiement sécuritaire par carte de crédit <br/><span style={{'font-size': '.9em'}}>{`${!!selectedPlan ? selectedPlan === PLAN_ANNUEL_ID ? ' (19.99$ +taxes pour un an)' : ' (24.99$ +taxes pour un an)' : '' }`}</span></h3>;
    };

    const isInvalid = !selectedPlan || isLoading || !isEnvValid || !stripe || !isFormValid.cvc || !isFormValid.date || !isFormValid.no;

    return (
        <CSSTransition nodeRef={nodeRef} mountOnEnter timeout={200} in={show} classNames="appear">
            <FormWrapper ref={nodeRef} loading={isLoading ? 1 : undefined}>
                <Margin margintop="2em"><Heading>{showPaymentDetails()}</Heading></Margin>
                {error.msg !== '' && (<Margin margintop="1.2em"><DisplayError msg={error}/></Margin>)}
                <form onSubmit={(e) => {
                    if (!isInvalid) {
                        handleSubmitFn(e);
                    }
                }}>
                    <CarsInputWrapper>

                        <PaymentInput>
                            <label>Numéro de carte</label>
                            <InputWrapper secured={true}>
                                <CardNumberElement options={{...cardOptions,iconStyle : 'solid'}} onChange={e => setIsFormValid(prev => ({
                                    ...prev,
                                    no: e.complete
                                }))}
                                                   onReady={(element) => {if (autofocus)element.focus()}}
                                />
                            </InputWrapper>
                        </PaymentInput>

                        <Row><PaymentInput><label>Date d'expiration</label>
                            <InputWrapper><CardExpiryElement options={cardOptions} onChange={e => setIsFormValid(prev => ({
                                ...prev,
                                date: e.complete
                            }))}/></InputWrapper></PaymentInput>

                            <PaymentInput><label>Code de sécurité (CVC)</label>
                                <InputWrapper><CardCvcElement options={cardOptions} onChange={e => setIsFormValid(prev => ({
                                    ...prev,
                                    cvc: e.complete
                                }))}/></InputWrapper></PaymentInput></Row>
                        {/*<CardElement options={cardOptions} onChange={e => setIsFormValid(e.complete)}/>*/}
                    </CarsInputWrapper>


                    <Row>
                        <Button disabled={isInvalid}>Confirmer</Button>
                        <BadgeStripe>Propulsé sécuritairement par <a href="https://www.stripe.com" rel="noreferrer" target="_blank"><img src="/img/stripe_badge.png" alt="Logo Stripe"/></a></BadgeStripe>
                    </Row>
                </form>
                <Spinner show={isLoading}/>
            </FormWrapper>
        </CSSTransition>
    );
};

export default NewCardForm;

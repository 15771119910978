import React, {useContext, useState} from 'react';
import {Form} from "../../../components";
import {AccountContext, FBContext} from "../../../context";
import * as Account from '../../../models/account';
import {BorderTop, P, PageHeading} from "../../../styles/ui";
import firebase from 'firebase/app';
import styled from 'styled-components';

const TermsLink = styled.a`
  color: ${p => p.theme.colors.primary};
  text-decoration: underline;
  
  :hover {
    color: ${p => p.theme.colors.secondary};
  }
`;

const MsgProf = styled.div`
  display:block;
  background-color: ${p => p.theme.colors.dark};
  border-radius : .44em;
  padding: .3em;
`;

const AccountName = () => {

    const {auth} = useContext(FBContext);
    const {accountRef} = useContext(AccountContext);
    const [msg, setMsg] = useState({positive: true, msg:''});
    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = async data => {
        if (isLoading) return;

        setIsLoading(true);
        try {
            if (auth.currentUser.displayName !== `${data.prenom} ${data.nom}`) {
                auth.currentUser.updateProfile({displayName: `${data.prenom} ${data.nom}`});
            }

            await accountRef.update({
                [Account.prenom]: data.prenom,
                [Account.nom]: data.nom,
                [Account.newsletter]: data.newsletter,
                [Account.updatedAt]: firebase.firestore.FieldValue.serverTimestamp(),
                ...data.isProf && {[Account.isProf] : data.isProf},
            });

        } catch(er) {
            // console.log(er);
            setMsg({positive: false, msg: 'Erreur'});
            setIsLoading(false);
        }
    };

    const profMsg = (<MsgProf><P color="#fff">En cochant cette case, vous acceptez que l'équipe Snowclass puisse vous contacter par courriel afin d'évaluer vos besoins pédagogiques et organisationnels.</P></MsgProf>);

    return (
                <>
            {/*<CommonLayout>*/}
                    <PageHeading>Terminons votre inscription</PageHeading>
                    <P>Entrez vos informations de compte.</P>
                    <BorderTop>
                        <Form isDisabled={isLoading} msg={msg} btnLabel="Suivant" submitFn={submitHandler}
                              elements={[
                                  {id: 'prenom', type: 'text', label: 'Prénom', init: '', focus: true},
                                  {id: 'nom', type: 'text', label: 'Nom', init: '',},
                                  {id: 'isProf', type: 'checkbox', label: 'Compte enseignant', init: false, slot: profMsg, showSlotCondition: (value) => !!value},
                                  {id: 'newsletter', type: 'checkbox', label: 'M\'inscrire à l\'infolettre Snowclass', init: false},
                                  {id: 'terms', type: 'checkbox', validation: [{type: 'requiredCheckbox'}], label: <strong>J'accepte les <TermsLink target="_blank"  href="/terms">termes et conditions</TermsLink></strong>, init: false}
                              ]}/>
            {/*</CommonLayout>*/}
                    </BorderTop></>
    );
};

export default AccountName;

import {Redirect, Route, useLocation} from "react-router-dom";
import {usePage} from "../../hooks";
import * as queryString from 'query-string';

const PrivateRoute = ({children, validation, category, ...props}) => {
    const {getRoutesStatus} = usePage();
    const result = getRoutesStatus(category, validation);

    const {pathname, search} = useLocation();
    const params = queryString.parse(search);

    const extraParams = [];

    if (result.ready && !result.success && result.includeRedirect) {
        extraParams.push('redirect=' + pathname.substr(1));
    }

    // provient de l'activation du courriel pour déterminer quel email provider fonctionne avec le courriel
    const {emails} = params;
    if (emails) {
        extraParams.push('emails=' + params.emails);
    }
    const sExtraParams = extraParams.length ? '?' + extraParams.join('&') : '';

    if (!result.ready) {
        return null;
    }
    return (
        <Route {...props} render={_ => {
                if (!result.success) {
                    return <Redirect to={`${result.redirectTo}${sExtraParams}`}/>;
                }
                return children;
        }}/>
    );
};

export default PrivateRoute;

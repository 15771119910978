import React from 'react';
import ReactDOM from 'react-dom';
// import './services/sentry';
import './services/firebase';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AccountProvider, devContext, DevProvider, FBConsumer, FBProvider, LoaderProvider} from "./context";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import * as theme from './styles/constants';
import {HelmetProvider} from "react-helmet-async";
import {Elements} from "@stripe/react-stripe-js";

import {useEffect, useState, useContext} from 'react';
import {loadStripe} from "@stripe/stripe-js";

const helmetContext = {};

const StripeElementsWrapper = ({children}) => {

    const {isTesting} = useContext(devContext);
    const [stripeResult, setStripeResult] = useState(null);

    useEffect(() => {
        setStripeResult(loadStripe(!isTesting ? process.env.REACT_APP_PUBLISHABLE_KEY : process.env.REACT_APP_TEST_PUBLISHABLE_KEY));
    }, [isTesting]);

    return (
        <Elements stripe={stripeResult}>
            {children}
        </Elements>
    );
};


ReactDOM.unstable_createRoot(document.getElementById('root')).render(
    <HelmetProvider context={helmetContext}>
        <BrowserRouter>
            <DevProvider>
                <StripeElementsWrapper>
                    <FBProvider>
                        <FBConsumer>
                            {({user}) => (
                                <ThemeProvider theme={theme}>
                                    <AccountProvider user={user}>
                                        <LoaderProvider>
                                            <App/>
                                        </LoaderProvider>
                                    </AccountProvider>
                                </ThemeProvider>
                            )}
                        </FBConsumer>
                    </FBProvider>
                </StripeElementsWrapper>
            </DevProvider>
        </BrowserRouter>
    </HelmetProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(/*console.log*/);

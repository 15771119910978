import {P} from "../../styles/ui";
import styled from "styled-components";
import {Link} from "react-router-dom";


const AuthLink = styled(Link)`
  &:link, &:visited {
    color: ${p => p.theme.colors.primary};
  }
  &:hover, &:active {
    color: #000;
  }
`;

const StyledTopRight = styled.div`
  display:none;
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    display:block;
  }
`;

const TopRight = () => (<>
    <StyledTopRight><P>Vous êtes nouveau? <AuthLink to="/register">Créez un compte</AuthLink> </P></StyledTopRight>
</>);

export default TopRight;

import {BigHeading, ColWrapper, Margin, SectionDescription} from "../../styles/ui";
import {usePage} from "../../hooks";
import {useLocation} from 'react-router-dom';
import firebase from 'firebase/app';
import React, {useEffect, useState, useContext} from "react";
import {Form} from "../../components";
import {FBContext} from "../../context";
import {Helmet} from "react-helmet-async";
import * as queryString from 'query-string';
import {colors} from "../../styles/constants";

export const Contact = () => {

    const questionTypes = [
        'Question générale',
        'Question technique',
        'Abonnement école',
        'Abonnement & facturation',
        'Suggestions',
        'Autre'
    ];

    const {user, firestore} = useContext(FBContext);

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const { pathname, search } = useLocation();

    const queryParams = queryString.parse(search);
    const {sujet: contactSujet} = queryParams;



    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [msg, setMsg] = useState({msg: '', positive: true});
    const messagesRef = firestore.collection('messages');

    const sendEmail = (data) => {
        setIsLoading(true);
        setMsg({msg: '', positive: true});

        messagesRef.add({
            done: false,
            email: data.email,
            nom: data.nom,
            type: questionTypes[data.type],
            sentAt: firebase.firestore.FieldValue.serverTimestamp(),
            message: data.message, //admin.firestore.FieldValue.serverTimestamp(),
            ...(user !== null && {userId: user.uid})
        }).then((resp) => {
            setIsLoading(false);
            setMsg({msg: 'Message envoyé. Merci!', positive: true});
            setIsDone(true);
        }).catch(err => {
            setIsLoading(false);
            setMsg({msg: err, positive: false});
            setIsDone(true);
        });
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Communiquez avec nous</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            <Margin marginbottom="4em">
                <header>
                    <BigHeading marginbottom=".1em">Communiquez avec nous</BigHeading>
                    <SectionDescription fontsize="1.3em">Communiquez avec nous en utilisant le formulaire suivant. Nous
                        répondrons aussitôt que possible.
                    </SectionDescription>
                </header>
            </Margin>
            <ColWrapper>
                <section>
                    <Form submitFn={sendEmail} btnLabel="Envoyer" isDisabled={isLoading || isDone} msg={msg} btnWidth="200px"
                          elements={[
                              {
                                  init: contactSujet === 'confirmation' && user ? user.email : '',
                                  focus: !(contactSujet === 'confirmation' && user),
                                  label: 'Votre courriel',
                                  type: 'email',
                                  id: 'email',
                                  validation: [{type: 'email'}]
                              },
                              {
                                  init: '',
                                  focus: !!(contactSujet === 'confirmation' && user),
                                  label: 'Votre nom',
                                  id: 'nom'
                              },
                              {
                                  init: contactSujet === 'confirmation' ? 1 : 0,
                                  label: 'Catégorie de message',
                                  id: 'type',
                                  type: 'select',
                                  choices: questionTypes
                              },
                              {
                                  init: contactSujet === 'confirmation' ? `Bonjour, je n'ai pas reçu ma confirmation d'inscription par courriel.` : '',
                                  label: 'Votre message',
                                  id: 'message',
                                  type: 'textarea'
                              }
                          ]}/>
                </section>
            </ColWrapper>
            <ColWrapper></ColWrapper>
        </>
    );
};

export default Contact;

import {Helmet} from "react-helmet-async";
import {BigHeading, ColWrapper, Margin, SectionDescription} from "../../styles/ui";
import {Form} from "../../components";
import firebase from 'firebase/app';
import React, {useContext, useEffect, useState} from "react";
import {FBContext} from "../../context";
import {useLocation} from 'react-router-dom';
import {usePage} from "../../hooks";
import styled, {css} from 'styled-components';
import {colors} from "../../styles/constants";

const ContentArticle = styled.article`
  background-color: ${p => p.bg || 'transparent'};
  padding: 1.5em;
  color: ${p => p.theme.colors.lightGray};
  margin-bottom: 1.25em;
  font-family: ${p => p.theme.fonts.text};
  font-size: 1.2em;
  border-radius: .32em;
  
  ${p => p.bordered && css`
    border: 1px solid ${p.theme.colors.lightGray};
    border-radius: .32em;
  `};
`;

const ContentHeader = styled.h3`
  font-size: ${p => p.fontsize || '1.3em'};
  color: ${p => p.theme.colors.dark};
`;

const ArrowedUL = styled.ul`
  list-style-type: none;
  margin-top: 1.8em;
  
  li {
    background: url('/img/arrow-right.png') 0 3px/15px 15px no-repeat;  //bgsize auto  //bgposition top/left
    padding-left: 1.2em;  //padding-left: 3em
    margin-bottom: 1.5em;
    min-height: 27px;
    text-align: left;
    
    @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
      background-position: left top;
      background-size: auto;
      padding-left: 3em;
    }
  }
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const CheckedUL = styled.ul`
  list-style-type: none;
  margin-top: 1.8em;
  
  li {
    background: url('/img/check.png') 0 3px/15px 11.1px no-repeat;
    padding-left: 1.2em;
    margin-bottom: 1.5em;
    min-height: 27px;
    text-align: left;
    
    @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
      background-position: left top;
      background-size: auto;
      padding-left: 3em;      
    }
  }
`;

const GroupesOL = styled.ol`
  list-style-type: none;
  margin-bottom: 1.4em;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    max-width: 29em;
  }
  
  li {
    display: flex;
    justify-content: space-between;
    background-color: ${p => p.theme.colors.textField};
    margin-bottom: .26em;
    padding: .47em;
  }
`;

const FamilyImg = styled.img`
  width: 280px;


  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    margin-right: 2em;
  }  
`;


const Ecoles = () => {

    const {user, firestore} = useContext(FBContext);

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [msg, setMsg] = useState({msg: '', positive: true});
    const ecolesRef = firestore.collection('ecoles');

    const sendEmail = (data) => {
        setIsLoading(true);
        setMsg({msg: '', positive: true});

        ecolesRef.add({
            done: false,
            email: data.email,
            nom: data.nom,
            school: data.school,
            ...data.phone && {phone: data.phone},
            sentAt: firebase.firestore.FieldValue.serverTimestamp(),
            message: data.message, //admin.firestore.FieldValue.serverTimestamp(),
            ...(user !== null && {userId: user.uid})
        }).then((resp) => {
            setIsLoading(false);
            setMsg({msg: 'Message envoyé. Merci!', positive: true});
            setIsDone(true);
        }).catch(err => {
            setIsLoading(false);
            setMsg({msg: err, positive: false});
            setIsDone(true);
        });
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Forfait pour écoles</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            <Margin marginbottom="2em">
                <header>
                    <BigHeading marginbottom=".1em">Snowclass pour les écoles</BigHeading>
                    <SectionDescription fontsize="1.3em">Pour les écoles, nous offrons une formule par groupe-classe avec un prix par élève et un abonnement gratuit pour l'enseignant.e.
                    </SectionDescription>
                </header>
            </Margin>
            <section>
                <ContentArticle bg={colors.textField}>
                <ContentHeader>Option 1 <br/>Abonnement individuel pour chaque élève</ContentHeader>
                <ArrowedUL>
                    <li>Vos élèves ont leurs propres machines (tablettes, ordinateurs, chromebooks) et votre école préfère laisser les parents gérer l'abonnement à Snowclass pour leurs enfants.</li>
                    <li>Vos parents d'élèves s'abonnent au plan annuel (1-2 enfants) ou annuel+ (2-4 enfants) et vos élèves peuvent accéder à votre classe en s'inscrivant avec votre code de classe.</li>
                    <li>Les élèves ont également la possibilité de créer leur propre classe et d'avoir leurs propres activités de révision en dehors de votre classe.</li>
                    <li>Dans ce cas, votre abonnement et leur abonnement sont hors formule école et valides 12 mois à partir de la date d'abonnement.</li>
                </ArrowedUL>
                </ContentArticle>

                <ContentArticle bg={colors.textField}>
                    <ContentHeader>Option 2 <br/>Abonnement par classe</ContentHeader>
                    <ArrowedUL>
                        <li>Votre école a un parc informatique et/ou désire abonner les élèves directement.</li>
                        <li>Nous avons un modèle pour les écoles qui permet un abonnement par classe du 1er septembre au 1er juillet. L'application ‘école’ peut être déployée sur PC, Mac, iPad, tablettes Android et Chromebook.</li>
                        <li>Après avoir convenu du nombre d'élèves et de classes que l'école désire abonner, nous pouvons interfacer avec le service technique pour le déploiement des applications dans les laboratoires ou chariots iPad.</li>
                    </ArrowedUL>
                </ContentArticle>
                <ContentArticle>
                    <ContentRow alignitems="flex-start">
                        <FamilyImg src="/img/img-famille-illustration.png" alt="Famille Snowclass"/>
                        <div>
                            <ContentHeader>Chaque classe obtient:</ContentHeader>
                            <CheckedUL>
                                <li>Un identifiant unique par élève qui lui permet de se connecter à la classe de son
                                    enseignant.e sur n'importe quelle machine possédant la version ‘école’ de Snowclass
                                    (que
                                    ce soit à l'école ou à la maison).
                                </li>
                                <li>Un compte Annuel gratuit par enseignant.e par groupe de 15-25 élèves. Ce compte
                                    annuel
                                    permet de créer et gérer une classe.
                                </li>
                            </CheckedUL>
                        </div>
                    </ContentRow>

                </ContentArticle>
                <ContentArticle bordered>
                    <ContentHeader>Prix du plan école</ContentHeader>
                    <Margin marginbottom="1.4em" margintop="1.4em">
                        <p>Afin de favoriser  l'implantation dans les écoles, nous avons prévu le tarif dégressif suivant:</p>
                    </Margin>
                    <GroupesOL>
                        <li>
                            <h4>Groupe #1</h4>
                            <p>9.99$ par élève</p>
                        </li>
                        <li>
                            <h4>Groupe #2</h4>
                            <p>8.99$ par élève</p>
                        </li>
                        <li>
                            <h4>Groupe #3</h4>
                            <p>7.99$ par élève</p>
                        </li>
                        <li>
                            <h4>Groupe #4 et plus</h4>
                            <p>6.99$ par élève</p>
                        </li>
                    </GroupesOL>
                    <p>Chaque groupe est défini par son accès à un code de classe rattaché au compte annuel de l'enseignant.e et comporte entre 15 et 25 élèves.</p>
                </ContentArticle>
            </section>
            <ColWrapper>
                <section>
                    <ContentArticle>
                        <ContentHeader>Pour une adhésion au plan école</ContentHeader>
                        <Margin margintop="1.2em" marginbottom="1.2em"><ContentHeader fontsize="1em">Communiquez avec nous pour démarrer la discussion pour une
                            adhésion au plan école.
                        </ContentHeader></Margin>
                        <Form submitFn={sendEmail} btnLabel="Envoyer" isDisabled={isLoading || isDone} msg={msg} btnWidth="200px"
                              elements={[
                                  {
                                      init: '',
                                      label: 'Votre courriel',
                                      type: 'email',
                                      id: 'email',
                                      validation: [{type: 'email'}]
                                  },
                                  {init: '', label: 'Votre nom', id: 'nom'},
                                  {init: '', label: 'Le nom de votre école', id: 'school'},
                                  {init: '', label: 'Votre numéro de téléphone', id: 'phone', optionnal: true},
                                  {init: '', label: 'Votre message', id: 'message', type: 'textarea'}
                              ]}/>
                    </ContentArticle>
                </section>
            </ColWrapper>
            <ColWrapper/>
        </>
    );
};

export default Ecoles;

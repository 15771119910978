import styled, {css} from 'styled-components';
import {P} from "../../styles/ui";
import {Link} from "react-router-dom";

const FooterP = styled(P)`
  font-size: 1.1em;
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    font-size: .81em;
  }
  color: ${p => p.theme.colors.lightGray};
  line-height: 1.33;
`;

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  
  ${p => p.common && css`
      flex-direction: column-reverse;
      
      &>.copyrights {
        margin-top: .5em;
      }
      
      @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
        flex-direction: row;  
        
        &>.copyrights {
        margin-top: 0;
      }
      }
  `}
`;

// const LogoutLink = styled(Link)`
//   border: none;
//   outline: none;
//   background: transparent;
//   color: ${p => p.theme.colors.lightGray};
//   cursor: pointer;
//   transition: color .2s ease-in-out;
//
//   &:hover {
//     color: #000;
//   }
// `;

const MailToLink = styled(Link)`
  &:link, &:visited {
    color: ${p => p.theme.colors.primary};
  }
  
  &:hover, &:active {
    color: ${p => p.theme.colors.secondary};
  }
`;

const Footer = ({common}) => {
    return (
        <StyledFooter common={common ? 1 : 0}>
            <div className="copyrights">
                <FooterP>SNOWCLASS (c) {new Date().getFullYear()}</FooterP>
                <FooterP>Lire notre <MailToLink to="/confidentiality">politique de confidentialité.</MailToLink></FooterP>
                <FooterP><MailToLink to="/contact">Communiquez avec nous</MailToLink></FooterP>
                <FooterP><MailToLink to="/logout">Déconnexion</MailToLink></FooterP>
            </div>

            {/*<section>*/}
            {/*    <LogoutLink to="/logout">Déconnexion</LogoutLink>*/}
            {/*</section>*/}
        </StyledFooter>
    );
};

export default Footer;

import React, {useState, useContext, createRef} from 'react';
import {AccountContext} from "../../../context";
import {UserFullVignette, TimedAlert} from "../../../components";
import * as Account from '../../../models/account';
import * as User from '../../../models/users';
import firebase from "firebase/app";
import {AddUserBtn, BorderTop, Button, P, PageHeading} from "../../../styles/ui";
import styled from 'styled-components';
import {AccountStatus} from "../../../models/account";
import {onboardingOverEvent} from "../../../models/anlytics";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {useSubscription} from "../../../hooks";
import {logEvent} from "../../../services/firebase";


const ButtonContainer = styled(BorderTop)`
  display:flex;
  justify-content: flex-end;
`;

const UserAnimate = styled.div`
  overflow: hidden;
  transition: all .2s ease-in-out, transform .2s ease-in-out, opacity .2s ease-in-out;

  &.appear-enter {
    max-height: 0;
  }
  
  &.appear-enter-active {
    max-height: 200px;
  }
  
  &.appear-exit {
    transform: translateX(0%);
    opacity: 1;
    max-height: 200px;
  }
  
  &.appear-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    max-height: 0;
  }
`;

const InitUsers = () => {

    const {PLAN_ANNUEL_PLUS_ID, PLAN_ANNUEL_ID} = useSubscription();
    const {account, accountRef} = useContext(AccountContext);
    const [nextId, setNextId] = useState(account[Account.nextNo]);
    const [users, setUsers] = useState(account[Account.users] ? [...account[Account.users]] : [{[User.prenom]: '', [User.nom]: '', id: nextId, c: 1}]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [openVignettes, setOpenVignettes] = useState([false]);

    const deleteHandler = (id) => {
        setUsers(users => users.filter((u,i) => {
            const isTheOne = u[User.id] === id;
            if (isTheOne) {
                setOpenVignettes(vs => vs.filter((v,index) => index !== i));
            }
            return !isTheOne;
        }));

    };

    const updateUser = (val, id, prop) => {
        return setUsers(users => users.map(u => u[User.id] === id ? {...u, [prop]: val} : u));
    };

    const addUser = () => {
        setUsers(u => [...u, {[User.prenom]: '', [User.nom]: '', id: nextId + 1, c: 1}]);
        setNextId(id => id + 1);
        setOpenVignettes(v => [...v, false]);
    };

    const saveUsers = async() => {
        if (isLoading) return;
        const increment = firebase.firestore.FieldValue.increment(1);
        setIsLoading(true);
        try {
            await accountRef.update({
                [Account.users]: users.filter((u) => u.prenom.trim() && u.nom.trim())
                    .map((u) => {
                    return {
                        id : u.id,
                        prenom: u.prenom,
                        nom: u.nom,
                        c: u.c,
                        ...u.g && {g: u.g}
                    };
                }),
                [Account.updatedAt]: firebase.firestore.FieldValue.serverTimestamp(),
                [Account.nextNo]: nextId + 1,
                ...process.env.REACT_APP_IS_BETA_ON === '1' && {[Account.status]: AccountStatus.BETA.label},
                [Account.userUpdateCount]: increment
            });
            logEvent(onboardingOverEvent, {usersCount: users.filter(u => u.prenom.trim() && u.nom.trim()).length});

        } catch(err) {
            setError('Erreur');
            setIsLoading(false);
        }
    };

    const showNbUsersToAdd = () => {
        if (account.status === AccountStatus.PROF_ACTIF.label || account.activePlan === PLAN_ANNUEL_PLUS_ID) {
            return process.env.REACT_APP_MAX_NB_PLUS_USERS;
        }
        return process.env.REACT_APP_MAX_NB_USERS;
    };

    const onToggleVignette = (i, isOpen) => {
        if (isOpen) {
            setOpenVignettes(vs => vs.map((v, index) => i === index));
        } else {
            setOpenVignettes(vs => vs.map((v, index) => i === index ? false: v));
        }
    };

    const ifEmpty = u => !u[User.prenom] || !u[User.prenom].trim() || !u[User.nom] || !u[User.nom].trim();
    const checkIfAnyEmpty = users.some(u => ifEmpty(u));
    const checkForOne = users.find(u => !ifEmpty(u));

    return (
        <>
            {/*<CommonLayout minWidth={470}>*/}
            {process.env.REACT_APP_IS_BETA_ON !== '1' && account.status !== AccountStatus.PROF_ACTIF.label && (
                <TimedAlert positive={true} delay={5000}>Une période d'essai de 14 jours a été activée pour le plan <strong>{account.activePlan === PLAN_ANNUEL_ID ? 'Annuel' : 'Annuel +'}</strong>.</TimedAlert>
            )}

            <PageHeading>Ajouter des profils</PageHeading>
            <P>Ajoutez jusqu'à {showNbUsersToAdd()} profils à votre compte. Il vous sera toujours
                possible de modifier la liste plus tard.</P>
            <BorderTop>
                <TransitionGroup>
                    {users.map((u, i) => {
                        const nodeRef = createRef(null);
                        return (
                        <CSSTransition nodeRef={nodeRef} key={u[User.id]} timeout={200} classNames="appear">
                            <UserAnimate ref={nodeRef}>
                                <UserFullVignette toggleVignetteFn={onToggleVignette}
                                                 index={i}
                                                 c={u[User.c]}
                                                 genre={u[User.g]} id={u[User.id]} isOpen={openVignettes[i]}
                                                 nom={u.nom}
                                                 prenom={u[User.prenom]} deleteFn={(e) => deleteHandler(u.id)}
                                                 updatePrenomFn={e => updateUser(e.target.value, u[User.id], User.prenom)}
                                                 updateNomFn={e => updateUser(e.target.value, u[User.id], User.nom)}
                                                 nbUsers={users.length}
                                                 updateClasseFn={e => updateUser(parseInt(e.target.value), u[User.id], User.c)}
                                                 updateGenreFn={e => updateUser(e.target.value, u[User.id], User.g)}
                                                 focus={true}
                            /></UserAnimate>
                        </CSSTransition>
                    )})}</TransitionGroup>
                {((process.env.REACT_APP_IS_BETA_ON === '1' && users.length < process.env.REACT_APP_MAX_NB_USERS) ||
                    (account.status === AccountStatus.PROF_ACTIF.label && users.length < process.env.REACT_APP_MAX_NB_PLUS_USERS) ||
                    (account.activePlan === PLAN_ANNUEL_ID && users.length < process.env.REACT_APP_MAX_NB_USERS) ||
                    (account.activePlan === PLAN_ANNUEL_PLUS_ID && users.length < process.env.REACT_APP_MAX_NB_PLUS_USERS)) && (
                    <AddUserBtn onClick={addUser} disabled={checkIfAnyEmpty}>+ Ajouter un autre
                        profil</AddUserBtn>)}
            </BorderTop>

            {error && <span>{error}</span>}
            <ButtonContainer>
                <Button disabled={isLoading || !checkForOne} onClick={saveUsers}>Suivant</Button>
            </ButtonContainer>
        {/*</CommonLayout>*/}
        </>
    );
};

export default InitUsers;

import styled, {css} from "styled-components";

export const Input = styled.input`
  background-color: ${p => p.theme.colors.textField};
  font-size: ${p => p.theme.sizes.text.p};
  border-radius: .35em;
  border:none;
  outline-color: ${p => p.theme.colors.formBorder};
  padding:.5em .6em;
  line-height: 1.3;
  margin-bottom: 0.4em;
  width: ${p => p.type !== 'checkbox' ? '100%' : 'auto'};
  margin-left: ${p => p.type !== 'checkbox' ? '0' : '2em'};
  ${p => !p.valid && css`  
    border: 2px solid ${p.theme.colors.danger};
    outline: none;
  `}
  
  &[type="text"], &[type="email"], &[type="password"] {
    padding: 1em .6em;
    
    @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
      padding:.5em .6em;
    }
  }
`;

export const Select = styled.select`
  background-color: ${p => p.theme.colors.textField};
  font-size: ${p => p.theme.sizes.text.p};
  border-radius: .35em;
  border:none;
  color: ${p => p.theme.colors.lightGray};
  outline-color: ${p => p.theme.colors.formBorder};
  font-family: ${p => p.theme.fonts.text};
  line-height: 1.3;
  margin-bottom: 0.4em;
  padding: 1em .6em;
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
    padding:.5em .6em;
  }
`;

export const TextArea = styled.textarea`
  background-color: ${p => p.theme.colors.textField};
  font-size: ${p => p.theme.sizes.text.p};
  border-radius: .35em;
  border:none;
  outline-color: ${p => p.theme.colors.formBorder};
  font-family: ${p => p.theme.fonts.text};
  line-height: 1.3;
  margin-bottom: 0.4em;
  width: 100%;
  padding: 1em .6em;
  resize: none;
  min-height: ${p => p.minheight || '9em'};
  
  ${p => !p.valid && css`
    border: 2px solid ${p.theme.colors.danger};
    outline: none;
  `}
    
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
    padding:.5em .6em;
  }
`;

export const Fieldset = styled.fieldset`
  border: none;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
    text-align: left;
  }
`;

import React, {useContext, useEffect} from 'react';
import {FBContext} from "../../context";
import styled from 'styled-components';
import {BigHeading, H2, Margin, P} from "../../styles/ui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faWindows, faApple, faChrome, faGoogle} from '@fortawesome/free-brands-svg-icons';
import {Helmet} from "react-helmet-async";
import {usePage} from "../../hooks";
import {downloadEvent, downloadEventParams} from "../../models/anlytics";
import {firestore, logEvent} from "../../services/firebase";
import {useCollectionData} from "react-firebase-hooks/firestore";
// import * as Sentry from '@sentry/react';
import {FreeAppDownload} from "../../components";
import {colors} from "../../styles/constants";


const StyledPlatforms = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    flex-wrap: nowrap;
  }
`;

const Platform = styled(P)`
  flex: 1;
  background: ${p => p.theme.colors.download};
  border-radius: 7px; //convert em
  text-align: center;  
  font-weight: bold;  
  display: flex;
  align-items: stretch;
  flex-basis: 40%;
  flex-grow: 0;  
  margin-bottom: .8em;
  
  &:nth-child(even) {
    margin-left: .8em;
  }
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    flex: 1;
    margin-bottom: 0;   
    justify-content: stretch;
    margin-left: 0;
        
    & + li {
      margin-left: 1em;
    }
  }
`;

const PlatformLink = styled.button`
  background-color: transparent;
  border: none;
  display: block;
  width: 100%;
  cursor: pointer;

  &:link, &:visited {
    color: #000;
  }
  padding: 1.1em 1.7em;
`;

const StyledPlatformIcon = styled(FontAwesomeIcon)`
  font-size: 2.6em;
  margin-bottom: .3em;
`;

const Heading = styled(H2)`
  text-align: center;
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    font-size: 1.126em;
    margin-bottom: 0;
    margin-top:2em;
  }
`;

const ScreensImg = styled.div`
  text-align: center;
  margin-bottom:0;
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    margin-bottom: 3em;
  }

  img {
    max-width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    flex-direction: column;
  }
`;

const BorderedWrapper = styled.div`
  border: 1px solid ${p => p.theme.colors.formBorder};
  border-radius: .375em;
  padding: 2em;
`;

const DownloadEcole = () => {

    const platformsRef = firestore.collection('platforms').where('active', '==', true);
    const [platforms, platformsLoading, platformsError] = useCollectionData(platformsRef, {idField: 'id'});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    let oPlatforms;
    if (platforms) {
        oPlatforms = platforms.reduce((acc, current) => {
            return {...acc, [current.id] : current};
        }, {});
    }

    const icons = {
        'Chrome': faChrome,
        'Apple': faApple,
        'Windows': faWindows,
        'Google': faGoogle
    };

    const getFile = async (link, platformId) => {
        try {
            logEvent(downloadEvent, {[downloadEventParams.platform]: platformId});
            if (platformId === 'mac') {
                window.location.href = `${process.env.REACT_APP_PHP_ROOT}files/ecoles/${platformId}`;
            } else {
                window.open(link, '_blank');
            }
        } catch(err) {
            // Sentry.captureEvent(err);
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Téléchargement</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            <div>
                <Margin marginbottom="2em">
                    <header>
                        <BigHeading marginbottom=".1em">Téléchargement</BigHeading>
                    </header>
                </Margin>

                <Margin marginbottom="2em" margintop=".5em"><FreeAppDownload isLink={false}>
                    Téléchargez l'app Snowclass et entrez le nom d'utilisateur 'SNOW' pour visiter la classe test!&nbsp;→
                </FreeAppDownload></Margin>

                <BorderedWrapper>

                    <ScreensImg>
                        <img src={`${process.env.PUBLIC_URL}/img/img-multi-devices.png`}
                             alt="Multi plateformes"/>
                    </ScreensImg>

                    <Content>
                        <StyledPlatforms>
                            {oPlatforms && oPlatforms['--order'] && oPlatforms['--order'].order
                                .filter(p => !!oPlatforms[p])
                                .map((p) => (
                                    <Platform as="li" key={p}><PlatformLink
                                        onClick={() => getFile(oPlatforms[p].url, p)}>
                                        <StyledPlatformIcon icon={icons[oPlatforms[p].icon]}/>
                                        <h3>{oPlatforms[p].label}</h3>
                                    </PlatformLink></Platform>
                                ))}
                        </StyledPlatforms>
                        {/*<RegularLink as="button" onClick={() => getFile('', 'pc32')}>Version 32-bits de Windows</RegularLink>*/}
                        <Heading>Téléchargez Snowclass sur vos appareils, connectez-vous et amusez-vous!</Heading>
                    </Content>
                </BorderedWrapper>
            </div>
        </>
    );
};

export default DownloadEcole;

import React, {useState, useContext, useEffect} from "react";
import {Link, useHistory} from 'react-router-dom';
import {FBContext} from "../../context";
import {Margin, P, PageHeading, HighlightMessage} from "../../styles/ui";
import styled from 'styled-components';
import {Helmet} from "react-helmet-async";
import {usePage} from "../../hooks";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {colors} from "../../styles/constants";

const EmailWrapper = styled.div`
  max-width: 300px;
  margin: 0 auto;
  font-size: 1.05em;
`;

const SendAgainButton = styled.a`
  border: none;
  outline: none;
  color: ${p => p.theme.colors.primary};
  background: transparent;
  cursor: pointer;
  font-size: .8em;
  transition: color .2s ease-in-out;
  
  &:hover, &:active {
    color: ${p => p.theme.colors.secondary};
  }
`;

const SentBtn = styled(SendAgainButton)`
  color: ${p => p.theme.colors.primary};
  cursor: default;
  
  &:hover {
    color: ${p => p.theme.colors.lightGray};
  }
`;

const Img = styled.img`
  width: 80px;
  border-radius: 50%;
  margin-bottom: 1em;
`;

const Register = () => {

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const history = useHistory();
    const {auth, user, functions, firestore} = useContext(FBContext);

    const sendRappelFn = functions.httpsCallable('sendConfirmationLink');
    const confRef = firestore.collection('emailConfirmation').doc(user.uid);
    const [emailConf, emailConfLoading] = useDocumentData(user ? confRef: null);

    useEffect(() => {
        if (user.emailVerified) {
            history.replace('/onboarding');
        }
    }, [user, user.emailVerified, history] );

    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const sendValidationEmail = async () => {
        if (isLoading) return;
        setIsLoading(true);
        try {
            /*await auth.currentUser.sendEmailVerification({
                url: 'https://snowclass.com/onboarding', handleCodeInApp: false
            });*/
            try {
                await sendRappelFn({email: auth.currentUser.email, templateName: 'confirmationEmail'});
            } catch (e) {

            }
            setEmailSent(true);
            setIsLoading(false);
        } catch(err) {
            setIsLoading(false);
        }
    };

    return /*user.emailVerified && !isTesting ? */(
            <>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Snowclass | Confirmation de votre adresse courriel</title>
                    <meta name="theme-color" content={colors.secondary}/>
                </Helmet>
                <EmailWrapper>
                    <Img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Snowman"/>
                    <PageHeading>Merci!</PageHeading>
                    <P>Un courriel de confirmation a été envoyé à <strong>{user.email}</strong>.
                        {/*Vous pourrez y poursuivre votre inscription.*/}
                    </P>

                    {(!emailConf || (emailConf && !emailConf.message)) && (
                        <>
                            <Margin margintop=".5em"><P>Vérifiez votre boîte de courriels indésirables si vous tardez à recevoir la confirmation.</P></Margin>
                        </>
                    )}

                    {emailSent || isLoading ? (
                        <SentBtn as="p">{isLoading ? 'Envoi en cours' : 'Courriel envoyé'}</SentBtn>
                    ) : (
                        <SendAgainButton onClick={sendValidationEmail}>Envoyer le courriel à nouveau</SendAgainButton>
                    )}

                    {process.env.REACT_APP_IS_TESTING === '1' && <button onClick={() => history.push('/onboarding')}>SKIP</button>}



                    <Margin margintop="1em">
                        <HighlightMessage align="left">
                            {!emailConfLoading && emailConf && emailConf.message ? (
                                <><h3>Un message de Snowclass:</h3><P color="#fff">{emailConf.message}</P></>
                            ) : (
                                <P color="#fff">Vous n'avez toujours par reçu votre confirmation? <Link to="/contact?sujet=confirmation">Communiquez
                                    avec nous</Link></P>
                            )}

                        </HighlightMessage>
                    </Margin>
                </EmailWrapper>
            </>
    )/* : (
        <Redirect to="/onboarding"/>
    )*/;
};


export default Register;

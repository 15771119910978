import styled, {css} from 'styled-components';

export const H2 = styled.h2`
  font-size: ${p => p.theme.sizes.text.h2};
  line-height: 1.36em;
  color: ${p => p.color || '#000'};
  font-family: ${p => (p.font || 'Poppins') + ', sans-serif'};
  letter-spacing: 0;
`;

export const PageHeading = styled(H2)`
  margin-bottom: .75em;
`;

export const P = styled.p`
  font-size: ${p => p.fontsize || '0.85em'};
  color: ${p => p.color || '#000'};
  font-family: ${p => (p.font || 'Roboto') + ', sans serif'};
  line-height : 1.33em;
  letter-spacing: 0;
  display:block;
 
  ${p => p.hover && css`
    :hover {
      color: ${p.hover};
    }
  `}
`;

export const BigHeading = styled(PageHeading)`
  color: ${p => p.color || p.theme.colors.dark};
  font-size: 2.3em;
  
  @media screen and (min-width:${p => p.theme.sizes.media.xsmall}){
    font-size: 3.5em;  
  }
  
   @media screen and (min-width:${p => p.theme.sizes.media.large}){
    font-size: 3.5em; 
   }
   
   @media screen and (min-width:${p => p.theme.sizes.media.xlarge}){
    font-size: 4.4em; 
   }
  
  
  line-height: 1.06;
  ${p => p.fontsize && css`
    font-size: ${p.fontsize};
  `}
  ${p => p.marginbottom && css`
    margin-bottom: ${p.marginbottom};
  `}
  ${p => p.margintop && css`
    margin-top: ${p.margintop};
  `}
`;

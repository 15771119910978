import {Navigation, SideHeader, Footer} from "../../components";
import styled from "styled-components";
import {BorderTop} from "../../styles/ui";
import useTawk from "../../hooks/useTawk";

const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;    
    align-items: stretch;  
    
    @media screen and (min-width: ${p => p.theme.sizes.media.large}) { 
      min-height: 100vh;
      flex-direction: row;
    }     
`;

const Left = styled.div`  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) { 
      flex: 3;
    }  
`;

const Right = styled.div`  
  padding-bottom: 3em;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 3em;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
    flex: 10;
    padding-top: 6.9em;
  }
`;

const Center = styled.section`
  display:flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const MainColumn = styled.div`
  width: ${p => p.theme.sizes.layout.small};
  @media screen and (min-width: ${p => p.theme.sizes.media.xLarge}) {
    width: 65%;
    //min-width: 700px;
  }
`;

const LoggedLayout = ({children}) => {

    useTawk();

    return (
        <LayoutWrapper>
            <Left>
                <SideHeader/>
            </Left>
            <Right>
                <Center>
                    <MainColumn>
                        <Navigation/>

                        <BorderTop>
                            <main>
                                {/* Routes */}
                                {children}
                            </main>
                        </BorderTop>

                        <BorderTop>
                            <Footer/>
                        </BorderTop>
                    </MainColumn>
                </Center>
            </Right>
        </LayoutWrapper>
    );
};

export default LoggedLayout;

export const loginEvent = 'login';
export const loginEventParams = {
    method: 'method'
};
export const registerEvent = 'SIGN_UP';
export const registerEventParams = {
    method: 'method'
};
export const pageViewEvent = 'page_view';
export const pageViewEventParams = {
    pagePath: 'page_path'
};


// custom
export const onboardingOverEvent = 'ONBOARDING_OVER';
export const onboardingOverEventParams = {
    usersCount: 'usersCount'
};

// export const loggedvisitorEvent = 'LOGGED_VISITOR';
export const erreur404Event = '404_ERROR';
export const erreur404EventParams = {
    pagePath: 'page_path'
};

export const downloadEvent = 'DOWNLOAD';
export const downloadEventParams = {
    platform: 'platform'
};

export const clickTestAppEvent = 'TEST_APP_CLICKED';


export const purchaseEvent = 'purchase';
export const purchaseEventParams = {
    value: 'value',
    items: 'items'
};

export const cancelRenewEvent = 'CANCEL_AUTORENEW';
export const cancelRenewEventParams = {
    items: 'items'
};

export const upgradePlanEvent = 'UPGRADE_PLAN';



import styled, {css} from 'styled-components';

export const BorderTop = styled.div`
  padding-top: ${p => p.padding || '2.19em'};
  margin-top: ${p => p.margin || '2.19em'};
  border-top: 1px solid ${p => p.theme.colors.formBorder};
`;

export const DisplayFlex = styled.div`
  display: flex;
  flex-direction: ${p => p.direction || 'row'};
  justify-content: ${p => p.justifycontent || 'flex-start'};
  align-items: ${p => p.alignitems || 'stretch'};
`;

export const Margin = styled.div`
  margin-left: ${p => p.marginleft || 0};
  margin-top: ${p => p.margintop || 0};
  margin-right: ${p => p.marginright || 0};
  margin-bottom: ${p => p.marginbottom || 0};
`;

export const ZIndex = styled.div`
  display: inline-block;
  position: ${p => p.position || 'relative'};
  z-index: ${p => p.index};
`;

export const Hidden = styled.div`
  display: ${p => p.value ? 'none' : 'block'};
`;

export const Visible = styled.div`
  visibility: ${p => p.value ? 'visible' : 'hidden'};
`;

export const SpinnerParent = styled.div`
  position: relative;
  
  ${p => p.loading && css`    
    filter: blur(50%);
    &::after{
      content: '';
      display: block;
      position: absolute;
      background-color: rgba(255,255,255, 0.5);
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index:10;
    }
  `}
`;

import styled from 'styled-components';
import {P} from "../../styles/ui";
import {useEffect, useState, useContext} from "react";
import {Link, useLocation} from "react-router-dom";
import {logEvent} from "../../services/firebase";
import {erreur404Event, erreur404EventParams} from "../../models/anlytics";

const FullScreenSpinner = styled.div`
  background-color: ${p => p.theme.colors.primary};
  display: flex;
  font-family: 'Poppins', sans-serif;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  height: 100vh;
  font-size: 3em;
  color: #fff;
  transition: opacity 300ms ease-in-out;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  &.fly-enter {
    opacity: 0;
  }
  
  &.fly-enter-active {
    opacity: 1;
  }
  
  &.fly-exit {
    opacity: 1;
  }
  
  &.fly-exit-active {
    opacity: 0;
  }
  
  img {
    width: 80%;
    //max-width: 300px;
    transform: translateY(-22px);
    @media screen and (min-width: ${p => p.theme.sizes.media.small}){
      width: 436px;
    }
  }
`;

const HomeLink = styled(Link)`
  transition: color ease-in-out .2s;

  &:link, &:visited {
    color: ${p => p.theme.colors.secondary};
  }
  
  &:hover, &:active {
    color: #fff ;
  }
`;

const PageSpinner = () => {

    const location = useLocation();

    const [showMsg, setShowMsg] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMsg(true);
            logEvent(erreur404Event, {[erreur404EventParams.pagePath]: location.pathname + location.search})
        }, 5000);

        return () => clearTimeout(timer);
    }, [location.pathname,logEvent, location.search]);

    return (
        <FullScreenSpinner>
            <div>
                <img src={`${process.env.PUBLIC_URL}/img/img-snowball.png`} alt=""/>
                {!showMsg ? (<P color="#fff">Chargement...</P>) : (
                    <P color="#fff">Oups! Une erreur est survenue? <br/>
                        Revenir à <HomeLink to="/">l'Accueil</HomeLink>.
                    </P>
                )}
            </div>
        </FullScreenSpinner>
    );
};

export default PageSpinner;

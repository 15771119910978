import React,{useState} from "react";

const devContext = React.createContext();

const {Consumer, Provider} = devContext;

const DevProvider = ({children}) => {

    const [isTesting, setIsTesting] = useState(process.env.REACT_APP_IS_TESTING === '1');
    const [isOnline, setIsOnline] = useState(process.env.REACT_APP_IS_ONLINE === '1');

    return (
        <Provider value={{isTesting, setIsTesting, isOnline, setIsOnline}}>
            {children}
        </Provider>
    );
};

export {Consumer as DevConsumer, DevProvider, devContext};




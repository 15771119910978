import React, {useState, useContext, useEffect} from 'react';
import {Form, ChangePassword} from "../../components";
import * as Account from '../../models/account';
import {BorderTop, Margin, P, PageHeading} from "../../styles/ui";
import {AccountContext, FBContext} from "../../context";
import {Helmet} from "react-helmet-async";
import {usePage} from "../../hooks";
import firebase from "firebase/app";
import {functions} from "../../services/firebase";
import {colors} from "../../styles/constants";

const AccountInfo = () => {

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const {account, accountRef} = useContext(AccountContext);
    const {auth} = useContext(FBContext);
    const [accountInfo] = useState({prenom: '', nom: '', users: [], ...account});
    const [msg, setMsg] = useState({positive: true, msg:''});
    const [isLoading, setIsLoading] = useState(false);
    const updateCustomerNameFn = functions.httpsCallable('updateCustomerName');

    const updateAccount = async (data) => {
        if (isLoading) return;

        if (auth.currentUser.displayName !== `${data.prenom} ${data.nom}`) {
            auth.currentUser.updateProfile({displayName: `${data.prenom} ${data.nom}`});
        }

        setIsLoading(true);
        setMsg(msg => ({...msg, msg: ''}));
        try {
            const promises = [
                accountRef.update({[Account.prenom]: data.prenom, [Account.nom]: data.nom, [Account.updatedAt]: firebase.firestore.FieldValue.serverTimestamp()})
            ];

            if(account && !!account.stripeCustomerId) {
                promises.push(updateCustomerNameFn({name: `${data.prenom} ${data.nom}`}));
            }

            await Promise.all(promises);
            setMsg({positive: true, msg: 'Sauvegardé!'});
        } catch(er) {
            // console.log(er);
            setMsg({positive: false, msg: 'Erreur'});
        }
        setIsLoading(false);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Profil</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            <div>
                <PageHeading>Informations</PageHeading>
                <P>Modifiez les informations du compte.</P>

                <Margin margintop="1.5em">
                    <Form btnWidth="200px" msg={msg} btnLabel="Sauvegarder" isDisabled={isLoading}
                        submitFn={updateAccount}
                        elements={[
                            {
                                id: 'prenom',
                                type: 'text',
                                label: 'Prénom',
                                init: accountInfo.prenom,
                            },
                            {id: 'nom', type: 'text', label: 'Nom', init: accountInfo.nom},
                        ]}/>
                </Margin>
            </div>
            <BorderTop><ChangePassword/></BorderTop>
            {/*<section>*/}
            {/*    <h2>Courriel</h2>*/}
            {/*    <p>Effectuez un changement de mot de passe en appuyant sur le bouton suivant. Un courriel vous sera acheminé à cet effet.</p>*/}
            {/*    <button disabled={isPwdLoading}>Réinitialiser</button>{pwdMsg.msg && (<span>{pwdMsg.msg}</span>)}*/}
            {/*</section>*/}

        {/*</LoggedLayout>*/}
        </>
    );
};

export default AccountInfo;

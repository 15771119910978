import {BigHeading, Margin, SectionDescription} from "../../styles/ui";
import {usePage} from "../../hooks";
import React, {useEffect} from "react";
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {Helmet} from "react-helmet-async";
import {colors} from "../../styles/constants";

const Lien = styled.a`
  :link, :visited {
    color: ${p => p.theme.colors.primary};
  }
  
  :hover, :active {
    color: ${p => p.theme.colors.secondary};
  }
`;

const Wrapper = styled.div`
  font-weight: normal;
  
  font-size: .81em;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    font-size: .71em;
  }
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    font-weight: bold;
  }
`;

const Confidentialite = () => {
    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Politique de confidentialité</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            <Wrapper>
                <header>
                    <BigHeading>Politique de confidentialité</BigHeading>
                </header>
                <article>
                    <BigHeading fontsize="2.7em" marginbottom=".2em">Renseignements personnels</BigHeading>
                    <SectionDescription textalign="justify" fontsize="1.3em">Nous accordons la plus haute importance au
                        caractère privé de vos renseignements personnels. Nous nous engageons à ne jamais divulguer, que
                        ce soit par la vente ou la location, les renseignements saisis sur le site ou soumis à
                        Snowclass. Pour des fins de suivi interne, les statistiques d’utilisation peuvent être analysées
                        de façon confidentielle. Vous ne recevrez aucun courriel de la part de Snowclass, sauf dans des
                        cas particuliers (ex. : expiration de votre compte, état de l’abonnement, nouvelle version
                        disponible, infolettre si vous avez choisi de vous abonner, etc.). Il est cependant possible que
                        nous vous contactions afin d’obtenir des informations sur les besoins de nos utilisateurs, mais
                        votre participation reste totalement optionnelle. Lors de la création d’un profil utilisateur,
                        si vous ne souhaitez pas divulguer le prénom et/ou le nom de famille de votre enfant, vous
                        pouvez utiliser un identifiant différent, tel un surnom.</SectionDescription>
                </article>
                <Margin margintop="1em">
                    <article>
                        <BigHeading fontsize="2.7em" marginbottom=".2em">Comptes Snowclass</BigHeading>
                        <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Chaque utilisateur
                            est
                            le seul propriétaire de son compte. Snowclass ne supprime pas de compte sans demande
                            formelle. À
                            l’échéance d’un abonnement payant, un compte d’utilisateur reste dans notre système pendant
                            180
                            jours. Pour actualiser un compte échu, l’utilisateur peut simplement renouveler son
                            abonnement
                            pour retrouver ses activités et sa classe dans le même état qu’au moment de l’expiration.
                            Après
                            la période de grâce de 180 jours, le compte sera supprimé, ainsi que les profils
                            utilisateurs et
                            les classes qui y sont associées.</SectionDescription>
                        <SectionDescription textalign="justify" fontsize="1.3em">Tous les mots de passe sont cryptés
                            dans la
                            base de données. Chaque utilisateur est responsable de son propre mot de passe. Veuillez ne
                            pas
                            partager votre nom d’utilisateur ni votre mot de passe avec qui que ce soit qui pourrait
                            modifier les renseignements personnels du compte.</SectionDescription>
                    </article>
                </Margin>
                <Margin margintop="1em">
                    <section>
                        <header><BigHeading margintop=".8em" marginbottom=".2em">Politique de confidentialité -
                            FAQ</BigHeading></header>
                        <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Si vous êtes un
                            enseignant ou un parent, vous avez sans doute à cœur la sécurité et la protection de la vie
                            privée de vos élèves et enfants.</SectionDescription>
                        <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Le présent document
                            résume les politiques de confidentialité de l’application Snowclass.</SectionDescription>

                        <article><BigHeading fontsize="2.7em" marginbottom=".2em">Quelles données personnelles
                            recueillons-nous?</BigHeading>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Afin de
                                permettre aux
                                utilisateurs de s’identifier sur le site Snowclass pour la création et la gestion d’un
                                compte,
                                nous recueillons seulement les renseignements personnels suivants :
                            </SectionDescription>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Nom
                                d’utilisateur, c’est-à-dire leur
                                courriel<br/>
                                Ex. : jean123@gmail.com</SectionDescription>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Nom complet –
                                Pour simplifier l’accueil et
                                la communication des utilisateurs dans leur compte.
                            </SectionDescription>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Mot de passe,
                                sécurisé et encrypté dans une
                                base de données.</SectionDescription>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Prenez note que
                                le service Snowclass ne
                                demandera jamais directement d’obtenir de l’information personnelle concernant les
                                enfants. Le
                                processus d’inscription se fait par l’intermédiaire de parents, ou de membres du
                                personnel
                                d’écoles pour les élèves concernés.</SectionDescription>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Aucune autre
                                donnée personnelle n’est
                                recueillie. Toutes les données que nous conservons relativement aux utilisateurs, comme
                                les
                                listes d’activités et la création de classes sont créées lorsque les élèves et
                                enseignants
                                utilisent l’application Snowclass.</SectionDescription></article>

                        <article>
                            <BigHeading fontsize="2.7em" marginbottom=".2em">Que surveillons-nous?</BigHeading>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Nous assurons
                                un suivi des activités de nos utilisateurs pour les raisons suivantes
                                :</SectionDescription>
                            <ul>
                                <li><SectionDescription textalign="justify" fontsize="1.3em">Sauvegardes des activités
                                    et des classes.</SectionDescription></li>
                                <li><SectionDescription textalign="justify" fontsize="1.3em">Analyse quantitative des
                                    activités sur les classes (nombre d’accès à une classe).</SectionDescription></li>
                                <li><SectionDescription textalign="justify" fontsize="1.3em">Erreur ou défaillance afin
                                    d’aviser les membres de nos équipes techniques et de soutien.</SectionDescription>
                                </li>
                            </ul>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Nous ne faisons
                                pas un suivi des activités au sein de Snowclass à des fins de marketing, de vente ou
                                autres qui ne sont pas directement reliés à l’enseignement. Les efforts de marketing et
                                de vente ne sont déployés qu’à l’extérieur de Snowclass.</SectionDescription>
                        </article>

                        <article>
                            <BigHeading fontsize="2.7em" marginbottom=".2em">Avec qui partageons-nous cette
                                information?</BigHeading>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Avec personne.
                                Nous ne partageons pas de données personnelles. Ni avec les écoles, les enseignants, des
                                partenaires commerciaux, d’autres sociétés, ou organismes gouvernementaux. Personne.
                                C’est un engagement au cœur de notre philosophie.</SectionDescription>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Snowclass a
                                toujours été libre de publicité et farouchement opposé à l’exploitation des données
                                personnelles. Notre modèle économique par abonnement assure une relation mutuelle
                                directe entre votre implication financière et notre offre de
                                service.</SectionDescription>
                        </article>

                        <article>
                            <BigHeading fontsize="2.7em" marginbottom=".2em">Où conservons-nous les données
                                recueillies?</BigHeading>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Toutes les
                                données du compte utilisateur sont conservées chez goDaddy.com, un fournisseur central
                                situé à Scottsdale, Arizona et incorporé au Delaware (États-Unis) qui offre des
                                environnements sécurisés. Seuls les membres de notre équipe technique ont accès à ces
                                serveurs, ainsi qu’à toute copie de sauvegarde. Lorsque vous utilisez Snowclass, vous
                                utilisez toujours une connexion sécurisée entre votre navigateur Web et nos
                                environnements privés chez goDaddy.com.</SectionDescription>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Pour les
                                données des adultes, comme les enseignants et les parents, nous utilisons divers
                                services et d’outils afin de maintenir le niveau de service et de bien répondre aux
                                besoins des clients :</SectionDescription>
                            <ul>
                                <li><SectionDescription textalign="justify" fontsize="1.3em">Gestionnaire de
                                    compte</SectionDescription></li>
                                <li><SectionDescription textalign="justify" fontsize="1.3em">Service de listes de
                                    diffusion (courriels par consentement)</SectionDescription></li>
                                <li><SectionDescription textalign="justify" fontsize="1.3em">Service de facturation et
                                    de comptabilité</SectionDescription></li>
                            </ul>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Ces outils ne
                                sont pas restreints à un seul fournisseur de services et de gestion données, mais ce
                                sont les outils parmi les plus utilisés et populaires sur le marché et leurs
                                fournisseurs ont des politiques de confidentialité et des modalités d’utilisation
                                valides. Nous pouvons vous les fournir sur demande.</SectionDescription>
                        </article>

                        <article>
                            <BigHeading fontsize="2.7em" marginbottom=".2em">Que faisons-nous pour assurer la sécurité
                                des renseignements ?</BigHeading>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Nous utilisons
                                toujours les meilleures pratiques de l’industrie, telles que l’encryption des données et
                                la mise à jour constante de nos outils et logiciels, ainsi que des infrastructures
                                modernes pour assurer la sécurité de vos données.</SectionDescription>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">La liste du
                                personnel ayant accès aux données du système est strictement limitée aux employés de
                                Snowclass qui sont directement impliqués dans la création et la maintenance du
                                produit. </SectionDescription>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Dans
                                l’éventualité d’un bris de sécurité menant à un vol de données, Snowclass s’engage à
                                communiquer avec transparence les détails de l’événement. Dans un tel cas, les clients
                                concernés seront informés rapidement de la portée exacte et des impacts
                                possibles.</SectionDescription>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Après 180 jours
                                d’expiration de compte abonné, les données personnelles au compte et les profils
                                utilisateurs seront supprimés. Seules des données d’utilisation et des statistiques
                                anonymes sont conservées dans nos systèmes à des fins d’analyse.</SectionDescription>
                        </article>

                        <article>
                            <BigHeading fontsize="2.7em" marginbottom=".2em">Contactez Snowclass pour vos demandes de
                                renseignements</BigHeading>
                            <SectionDescription marginbottom=".5em" textalign="justify" fontsize="1.3em">Si vous avez
                                des questions supplémentaires à propos de nos politiques de confidentialité, ou si vous
                                voulez nous faire parvenir des demandes plus spécifiques concernant notre utilisation de
                                vos données personnelles, veuillez nous contacter directement et nous vous répondrons
                                rapidement de façon personnelle :<br/>
                                Par courriel: <Lien href="mailto:vieprivee@snowclass.com"
                                                    target="_blank">vieprivee@snowclass.com</Lien>
                            </SectionDescription>

                        </article>
                    </section>

                </Margin>
            </Wrapper></>
    );
};

export default Confidentialite;

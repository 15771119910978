import {useEffect} from 'react';
import tawkTo from 'tawkto-react';
/*
* <!--Start of Tawk.to Script-->
<script type="text/javascript">
var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/605f6747067c2605c0bcdfec/1f1qams6l';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();
</script>
<!--End of Tawk.to Script-->*/

const useTawk = () => {
    const propertyId = process.env.REACT_APP_TAWK_PROPERTY_ID;
    const key = process.env.REACT_APP_TAWK_KEY;

    const directChatLink = `https://tawk.to/chat/${propertyId}/${key}`;

    useEffect(() => {
       tawkTo(propertyId, key);
    }, []);

    return {
        directChatLink
    };
};

export default useTawk;

export const email = 'email';
export const prenom = 'prenom';
export const nom = 'nom';
export const nextNo = 'nextNo';
export const users = 'users';
export const stripeCustomerId = 'stripeCustomerId';
export const activePlan = 'activePlan';
export const newsletter = 'newsletter';
export const isProf = 'isProf';
export const userUpdateCount = 'userUpdateCount';
export const status = 'status';
export const createdAt = 'createdAt';
export const updatedAt = 'updatedAt';


export const AccountStatus = {
    EXPIRED: {
        index: 0,
        label: 'EXPIRED'
    },
    ACTIVE: {
        index: 1,
        label :'ACTIVE'
    },
    ONBOARDING: {
        index: 2,
        label: 'ONBOARDING'
    },
    BETA: {
        index: 3,
        label: 'BETA'
    },
    BETA_OVER: {
        index: 4,
        label: 'BETA_OVER'
    },
    OPTED_OUT: {
        index: 5,
        label: 'OPTED_OUT'
    },
    PROF_ACTIF: {
        index: 6,
        label: 'PROF_ACTIF'
    }
};

import {colors} from "../../styles/constants";
import {ColWrapper, HomeSection, Margin, P, SectionTitle, SectionWrapper} from "../../styles/ui";
import {faFacebookSquare, faYoutubeSquare} from "@fortawesome/free-brands-svg-icons";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";


const RightArrow = styled(FontAwesomeIcon)`
  font-size: .9em;
`;

const StyledMailingInput = styled.form`
  .inputWrapper {
    width: 90%;
    display: inline-block;
    margin: 0 5%;
    position: relative;
    color: #bdbdbd;
    margin-bottom: 4em;
    @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
      margin: 0;
    }
    
    @media screen and (min-width: 1100px) {
      margin: 0 5%;
    }
  }
  
  .emailInput {
    width: 100%;
    margin: 0 auto;
    line-height: 1.56;
    padding:1.06em 4em 1.06em 1.56em;
    background: #fff;
    border:0;
    outline: 0;
    border-radius: 2em;
    position: relative;
    display:block;
    border: 2px solid transparent;
    
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${p => p.theme.colors.inputTextColor};
    }
    :-ms-input-placeholder {
      color: ${p => p.theme.colors.inputTextColor};
    }
    
    &:focus {
      border: 2px solid ${p => p.theme.colors.inputTextColor};
    }
  }
  
  .emailSubmit {
    position:absolute; 
    background: transparent; 
    display:flex;
    flex-direction: column;
    justify-content: center;
    right:1em;
    top:0;
    bottom:0;        
  }
  
  .btn {
    border: none;
    outline-color: ${p => p.theme.colors.inputTextColor};    
    cursor: pointer;
    vertical-align: middle;
    background:transparent;
    font-size: 2em;
    padding:.2em;
    color: ${p => p.theme.colors.inputTextColor};
  }
`;

const MailingListInput = () => {
    return (
        <StyledMailingInput action="https://snowclass.us7.list-manage.com/subscribe/post?u=082d4faf70d04929f62ecc1e1&amp;id=50bad001fd"
                            method="post" name="mc-embedded-subscribe-form"
                            target="_blank" noValidate>
            <div className="inputWrapper">
                <input className="emailInput" name="EMAIL" type="email" placeholder="Votre courriel"/>
                <div className="emailSubmit">
                    <button className="btn" type="submit"><RightArrow icon={faArrowRight}/></button>
                </div>
            </div>
        </StyledMailingInput>
    );
};

const SocialLogo = styled(FontAwesomeIcon)`
  font-size:3em;
  color: #fff;
  margin-right: .3em;
  margin-top: .2em;
  transition: color .2s ease-in-out;
  ${p => p.offset && css`
    padding-left: ${p.offset};
  `}
  
  &:hover {
    color: ${p => p.theme.colors.dark};
  }
`;

const FooterLogo = styled.img`
  position: relative;
  bottom: -10px;
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
      position: absolute;
      left:0;
      bottom:0;
  }
`;

const Footer = styled.footer`
  font-size: .8em;
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
    font-size: 1em;
  }
`;

const FooterBottom = styled(SectionWrapper)`
  flex-direction: column-reverse;
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    align-items: flex-end;
  }
`;

const SnowmanWrapper = styled(ColWrapper)`
  text-align: left;
`;

const LicenceWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    display: block;
    text-align: right;
  }
`;

const InfolettreTitle = styled(SectionTitle)`
  font-size: 2.7em;
`;

const HomeFooter = () => {

    return (
        <Footer>
            <HomeSection color={colors.secondary} textcolor="#fff">
                <SectionWrapper>
                    <ColWrapper>
                        <InfolettreTitle fontsize="2.8em" color="#fff">Infolettre Snowclass</InfolettreTitle>
                        <MailingListInput/>
                    </ColWrapper>
                    <ColWrapper>
                        <SectionTitle margin=".21em;" color="#fff" fontsize="2.19em">Snowclass</SectionTitle>
                        <Link to="/contact"><P hover={colors.dark} fontsize="1.5em" color="#fff">Communiquez avec nous</P></Link>
                        <Link to="/terms"><P hover={colors.dark} fontsize="1.5em" color="#fff">Entente d'utilisation</P></Link>
                        <Link to="/confidentiality"><P hover={colors.dark} fontsize="1.5em" color="#fff">Politique de confidentialité</P></Link>
                        <a href="https://www.facebook.com/snowclassjeux/" target="_blank" rel="noreferrer"><SocialLogo icon={faFacebookSquare}/></a>
                        <a href="https://www.youtube.com/channel/UCPyMTEMUL6XBbEQVZPR7zfQ" target="_blank" rel="noreferrer"><SocialLogo icon={faYoutubeSquare}/></a>
                    </ColWrapper>
                </SectionWrapper>
            </HomeSection>

            <HomeSection color={colors.dark} overflow="visible+">
                <FooterBottom padding="0">
                    <SnowmanWrapper>
                        <FooterLogo src={`${process.env.PUBLIC_URL}/img/img-bottom-logo.png`} alt="Logo Snowclass"/>
                    </SnowmanWrapper>
                    <Margin marginbottom="2.19em" margintop="2.19em">
                        <ColWrapper textalign="right">
                            <LicenceWrapper><P fontsize="1.25em" color="#fff">SNOWCLASS (c) {new Date().getFullYear()}</P>
                                <P fontsize="1.25em" color="#fff">Tous droits réservés</P>
                                <Link to="/confidentiality"><P fontsize="1.25em" color="#fff">Politique de confidentialité</P></Link>
                            </LicenceWrapper>
                        </ColWrapper>
                    </Margin>
                </FooterBottom>
            </HomeSection>
        </Footer>
    );
};

export default HomeFooter;

import React, {useState, useCallback} from 'react';

const LoaderContext = React.createContext();
const {Provider, Consumer} = LoaderContext;

const LoaderProvider = ({children}) => {
    const [isLoading, setIsLoading] = useState(true);

    const startLoading = useCallback(() => {
        if (!isLoading) {
            setIsLoading(true);
        }
    }, [isLoading]);

    const stopLoading = useCallback(() => {
        if (isLoading) {
            setIsLoading(false);
        }
    }, [isLoading]);

    return (
        <Provider value={{isLoading, setIsLoading, startLoading, stopLoading}}>
            {children}
        </Provider>
    );
};

export {LoaderProvider, Consumer as LoaderConsumer, LoaderContext}

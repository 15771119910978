export const colors = {
    primary: '#43caff',
    secondary: '#008FC7',
    dark: '#002633',
    textField: '#f3f3f4',
    formBorder: '#c2c2c2',
    lightPrimary: '#5ddeff',
    thumbShadow: '#bcbcbc',
    thumbBorder: '#8f8f8f',
    lightGray: '#707070',
    darkGray: '#7d7d7d',
    focus: '#FF9900',
    darkFocus: '#985B00',
    focusHover: '#ffa416',
    download: '#91e0ff',
    danger: '#ff4343',
    paparman: '#eefffd',
    inputTextColor: '#9A9A9A'
};

export const fonts = {
    text: 'Roboto, sans-serif',
    heading: 'Poppins, sans-serif'
};

export const sizes = {
    media: {
        xsmall: '555px',
        small: '700px',
        medium: '830px',
        large: '960px',
        xLarge: '1280px'
    },
    layout: {
        small: '90%',
        medium: '80%',
        large: '50%',
        xlarge: '25%'
    },
    text: {
        h2: '1.126em',//20 1.56em; //25
        p: '0.85em'
    }
};

import './Spinner.css';
import {CSSTransition} from "react-transition-group";
import {createRef} from "react";
const Spinner = ({show}) => {
    const nodeRef = createRef(null);
    return (
        <CSSTransition nodeRef={nodeRef} mountOnEnter unmountOnExit timeout={300} in={show} classNames="appear">
            <div ref={nodeRef} className="Spinner">
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Spinner;

export const generateUniqueCode = () => {
    return 'unique-code';
};

export const suspend = promise => {
    let result;
    let status = 'pending';
    const suspender = promise.then(response => {
        status = 'success';
        result = response;
    }, error => {
        status = 'error';
        result = error;
    });

    return () => {
        switch(status) {
            case 'pending':
                throw suspender;
            case 'error':
                throw result;
            default:
                return result;
        }
    };
};

export const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
};

export const retirerAccent = (texte) => {
    const accent = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g, // C, c
    ];
    const noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];

    for(let i = 0; i < accent.length; i++){
        texte = texte.replace(accent[i], noaccent[i]);
    }
    return texte;
};


import {useEffect, useState, useRef} from 'react';
import {CSSTransition} from "react-transition-group";
import styled from 'styled-components';
import {AlertMessage} from "../../styles/ui";

const TimedAlerts = styled.div`
  overflow: hidden;
`;

const TimedAlert = ({children, delay, positive = false, callback = null}) => {
    const [show, setShow] = useState(true);
    const animDelay = 200;

    useEffect(() => {
        let timerAnim;

        const timer = setTimeout(() => {
            setShow(false);

            if (callback) {
                timerAnim = setTimeout(callback, animDelay);
            }
        }, delay);

        return () => {
            clearTimeout(timer);
            if (callback) {
                clearTimeout(timerAnim);
            }
        };
    },[callback, delay]);

    const nodeRef = useRef(null);

    return (
        <TimedAlerts>
            <CSSTransition nodeRef={nodeRef} unmountOnExit mountOnEnter timeout={animDelay} in={show} classNames="fly">
                <AlertMessage ref={nodeRef} positive={positive}>{children}</AlertMessage>
            </CSSTransition>
        </TimedAlerts>
    );
};

export default TimedAlert;

import React, {useState, useContext, useEffect} from 'react';
import {PageHeading, P, BorderTop} from "../../styles/ui";
import Form from "../../components/Form/Form";
import {devContext} from "../../context";
import {Helmet} from "react-helmet-async";
import {usePage} from "../../hooks";
import {functions} from "../../services/firebase";
import {colors} from "../../styles/constants";

const ResetPassword = () => {

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const {isTesting} = useContext(devContext);
    const [msg, setMsg] = useState({msg: '', positive: false});
    const [isLoading, setIsLoading] = useState(false);

    const sendPasswordEmailFn = functions.httpsCallable('sendResetPasswordLink');

    const submitFn = async ({email}) => {
        setIsLoading(true);
        setMsg({msg: '', positive: true});
        try {
            if (!isTesting) {
                try {
                    await sendPasswordEmailFn({email});
                    setMsg({positive: true, msg: 'Courriel envoyé'});
                } catch (e) {

                }
            }
        } catch (er) {
            setMsg({positive: false, msg: 'Erreur'});
        }
    };

    return (
            <>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Snowclass | Authentification</title>
                    <meta name="theme-color" content={colors.secondary}/>
                </Helmet>
                <PageHeading>Réinitialiser votre mot de passe</PageHeading>
                <P>Entrez votre courriel afin de recevoir les instructions de réinitialisation de votre mot de
                    passe.</P>
                <BorderTop>
                    <Form btnLabel="Réinitialiser" msg={msg} isDisabled={isLoading} submitFn={submitFn} elements={[
                        {id: 'email', label: 'Courriel', init: ''}
                    ]}/>
                </BorderTop>
            </>
    );
};

export default ResetPassword;

import React, {useContext, useEffect, useState} from 'react';
import {AccountContext} from "../../context";
import {AccountName, InitUsers, PlanSelection} from "./index";
import {Helmet} from "react-helmet-async";
import {usePage} from "../../hooks";
import * as Account from '../../models/account';
import {emailProviders} from "../../models/emailProviders";
import {useLocation} from 'react-router-dom';
import * as queryString from 'query-string';
import {firestore} from "../../services/firebase";
import {colors} from "../../styles/constants";

const Onboarding = () => {
    const {account, user} = useContext(AccountContext);

    //todo: Vérifier si emails arrive jusqu'à onboarding si pas loggué
    const {search} = useLocation();
    const {emails} = queryString.parse(search);

    useEffect(() => {

        const saveEmailProviders = async() => {
            const emailProviderRef = firestore.collection('emailProviders').doc(user.uid);
            emailProviderRef.set({
                provider: emailProviders[emails]
            });
        };

        if (emails !== null && emails < emailProviders.length && user?.uid) {
            saveEmailProviders();
        }
    }, [emails]);



    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const [showUsers, setShowUsers] = useState(false); // utilisé pour PROF_ACTIF

    const showSection = () => {
        if (!account.prenom || !account.nom) {
            return <AccountName/>;
        } else if (!showUsers && !account[Account.activePlan]) {
            return <PlanSelection showNextFn={setShowUsers} account={account}/>;
        } else {
            return <InitUsers/>;
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Activation</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            {showSection()}
        </>
    );
};

export default Onboarding;

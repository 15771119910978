import {useState} from 'react';
import styled, {css} from 'styled-components';
import {Input, P, Select, TextArea} from "../../styles/ui";

const InputHeader = styled.div`
  margin-bottom: .4em;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    flex-direction: row;
    align-items: center;
  }
`;

const Label = styled(P)`
  font-weight: bold;
`;

const horizontal = `
    display: flex;
    align-items: center;
`;

const StyledFormElement = styled.div`
  margin-bottom: .93em;
  ${p => p.type === 'checkbox' && css`${horizontal}`}
  ${p => p.css && p.css};
`;

const StyledTip = styled(P)`
  font-style: italic;
  align-self: flex-start;
  font-size: .8em;
`;


const FormElement = ({type = "text", label, uniqId, text, onChangeFn, slotElement, showSlotCondition, focus, tip, checkValidityFn, choices, css}) => {

    const [formState, setFormState] = useState({
        touched: false,
        pristine: true
    });

    const [isValid, setIsValid] = useState(true);

    const changeHandler = (e) => {
        setFormState(state => ({...state, pristine: false}));
        onChangeFn(e);
        if (!isValid && checkValidityFn) {
            const isValValid = checkValidityFn();
            if (isValValid) {
                setIsValid(true);
            }
        }
    };

    const onBlurHandler = (e) => {
        if (formState.touched && !formState.pristine && checkValidityFn){
            const isValid = checkValidityFn();
            setIsValid(isValid);
        }
    };

    const showInputByType = type => {
        switch(type) {
            case 'radio':
                return <Input id={uniqId} type={type} checked={text} onChange={changeHandler}/>;
            case 'textarea':
                return <TextArea valid={isValid ? 1 : 0} onBlur={onBlurHandler} onFocus={() => setFormState(state => ({...state, touched: true}))} value={text} id={uniqId} onChange={changeHandler} autoFocus={focus}/>;
            case 'select':
                return (
                    <Select value={text} id={uniqId} onChange={changeHandler}>
                        {choices.map((c,i) => (<option value={i} key={`${uniqId}_${i}`}>{c}</option>))}
                    </Select>
                );
            case 'hidden':
                return (
                    <input type="hidden" id={uniqId} value={text}/>
                );
            default:
                return <Input valid={isValid ? 1 : 0} onBlur={onBlurHandler} onFocus={() => setFormState(state => ({...state, touched: true}))} id={uniqId} autoFocus={focus} type={type} value={text} onChange={changeHandler}/>;
        }
    };

    return (
        <StyledFormElement type={type} css={css}>
            <InputHeader>
                {type !== 'hidden' && (<Label as="label" htmlFor={uniqId}>{label}</Label>)}
                {tip && <StyledTip as="span">{tip}</StyledTip>}
                {!!slotElement && (!showSlotCondition || showSlotCondition(text)) && slotElement}
            </InputHeader>
            {showInputByType(type)}
        </StyledFormElement>
    );
};

export default FormElement;

import {P} from "../../styles/ui";
import styled from "styled-components";


import {Link} from "react-router-dom";
const AuthLink = styled(Link)`
  &:link, &:visited {
    color: ${p => p.theme.colors.primary};
  }
  &:hover, &:active {
    color: #000;
  }
`;

const RegisterTopRightSection = () => (
    <>
        <P>Déjà un compte? <AuthLink to="/login">Connectez-vous</AuthLink> </P>
    </>
);

export default RegisterTopRightSection;

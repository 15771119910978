import styled from 'styled-components';
import {P} from '../../styles/ui';

const StyledHeader = styled.header`
    overflow: hidden;
    position:relative;
    background: ${p => p.theme.colors.primary};
    display: flex;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    flex-direction: column;
    background: ${p => p.theme.colors.primary} bottom right / cover no-repeat; 
    
    
    @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
      text-align: center;
      justify-content: stretch;
      flex: 3;
      padding-top: 3.8em;
      padding-bottom: 0;
      min-height: 100%; 
      background-position: bottom right;
      background-size: contain;  
      background-image: url(${p => process.env.PUBLIC_URL + '/img/bg-side.png'});
    }
    
    
    .wrapper {
      width: 90%;
      display: flex;
      align-items: center;
      @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
        width: 80%;
        display:block;  
      }
    }
    
    .logo {
      width: 28%;
      @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
          width: 80%;
          margin-bottom: 1.8em;
      }
    }
`;

const HeaderP = styled(P)`
  display: none;
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
      font-weight: bold;
      font-size: 1.2em;
  }
`;

const LogoBtn = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
`;

const SideHeader = () => {
    return (
        <StyledHeader>
            <div className="wrapper">
                <LogoBtn onClick={() => window.location.href="/"}><img className="logo" src={`${process.env.PUBLIC_URL}/img/img-snowclass-logo.png`} alt="Logo Snowclass"/></LogoBtn>
                <HeaderP color="#fff">Jeux éducatifs pour enrichir le travail de révision et les devoirs.</HeaderP>
            </div>
        </StyledHeader>
    );
};

export default SideHeader;

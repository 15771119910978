import styled from "styled-components";

const HeaderMessage = styled.div`
  border-radius: .375em;
  padding: .88em;
  border: 1px solid ${p => p.theme.colors.formBorder};
  margin-bottom: 2em;
  
  h2 {
  font-size: .99em;
    margin-bottom: .5em;
  }

  p {
    font-family: ${p => p.theme.fonts.text};
  }
`;

const FreeTrialMessage = () => (
    <HeaderMessage>
        <h2>Essai gratuit avec tous les abonnements</h2>
        <p>Essayez sans engagement! <strong>Aucun frais pour 14 jours</strong>. Vous pouvez vous désabonner
            à tout moment pendant l'essai.</p>
    </HeaderMessage>
);

export default FreeTrialMessage;

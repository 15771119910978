import {useState, useContext} from "react";
import {FBContext} from "../../context";
import firebase from 'firebase/app';
import {Margin, P, PageHeading} from "../../styles/ui";
import Form from "../Form/Form";

const ChangePassword = () => {
    const {auth} = useContext(FBContext);

    const [isPwdLoading, setIsPwdLoading] = useState(false);
    const [pwdMsg, setPwdMsg] = useState({positive: true, msg: ''});
    const [currentPwd, setCurrentPwd] = useState('');
    const [pwd,setPwd] = useState('');
    const [confPwd, setConfPwd] = useState('');

    const changePwd = async(data) => {
        setIsPwdLoading(true);
        setPwdMsg({positive: false, msg: ''});
        try {
            await auth.currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(
                auth.currentUser.email,
                data.currentPwd
            ));
            await auth.currentUser.updatePassword(data.pwd);
            setPwdMsg({positive: true, msg: 'Modifié!'});
            setPwd('');
            setConfPwd('');
            setCurrentPwd('');
        } catch (err) {
            setPwdMsg({positive: false, msg: err})
        }
        setIsPwdLoading(false);
    };

    return (
        <section>
            <PageHeading>Mot de passe</PageHeading>
            <P>Effectuez un changement de mot de passe ci-dessous:</P>
            <Margin margintop="1.5em">

                <Form msg={pwdMsg} isDisabled={isPwdLoading} btnLabel="Modifier" submitFn={changePwd} btnWidth="200px" elements={
                    [
                        {id: 'currentPwd', type: 'password', label: 'Mot de passe actuel', init:currentPwd, validation: [ {type: 'minLength', val:8}]},
                        {id: 'pwd', type: 'password', label: 'Nouveau mot de passe', init:pwd, validation: [ {type: 'minLength', val:8}, {type: 'alphaNum'}]},
                        {id: 'confPwd', type: 'password', label: 'Confirmez votre mot de passe', init:confPwd, validation: [ {type: 'sameValue', val:'pwd'}]},
                    ]
                }>
                </Form>
            </Margin>
        </section>
    );
};

export default ChangePassword;

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MSGER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};



firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();
const functions = firebase.functions();
const logEvent = analytics.logEvent;
if (process.env.REACT_APP_IS_ONLINE === "0") {
    /*firestore.settings({
        host: 'http://localhost:8080',
        ssl: false
    });*/
    firestore.useEmulator('localhost', 8080);
    auth.useEmulator('http://localhost:9099');
    functions.useEmulator('http://localhost', 5001);
}

export {firebase, auth, firestore, analytics, functions, logEvent};

import { useContext, useCallback } from 'react';
import {analytics, logEvent} from "../services/firebase";
import {AccountContext, devContext, FBContext, LoaderContext} from "../context";
import {routesCategory} from "../routes";
import {useLocation} from 'react-router-dom';
import {pageViewEvent, pageViewEventParams} from "../models/anlytics";

export const usePage = () => {

    const {stopLoading} = useContext(LoaderContext);
    const {userLoading, user} = useContext(FBContext);
    const {account, accountLoading} = useContext(AccountContext);
    const {isTesting} = useContext(devContext);
    const {pathname, search} = useLocation();

    /*validation(user, account, isTesting);*/

    const activatePage = useCallback(() => {
        analytics.setCurrentScreen(pathname + search);
        logEvent(pageViewEvent, {[pageViewEventParams.pagePath]: pathname + search});
        stopLoading();
    }, [pathname, search, stopLoading]);

    const getRoutesStatus = (category, validation, path) => {
        let validationResult;
        if (category === routesCategory.public) {
            return {ready: true, success: true};
        } else if ((category === routesCategory.auth) && !userLoading) {
            validationResult = validation(user, account, isTesting);
            return {ready: true, success: !!user && validationResult.success, redirectTo: validationResult.redirectTo};
        } else if ((category === routesCategory.notauth) && !userLoading) {
            validationResult = validation(user, account, isTesting);
            return {ready: true, success: !user && validationResult.success, redirectTo: validationResult.redirectTo};
        } else if (category === routesCategory.account && ((!userLoading && !accountLoading && user && account) || (!userLoading && !accountLoading && !user))) {
            validationResult = validation(user, account, isTesting);
            return {ready: true, success: !!account && validationResult.success, redirectTo: validationResult.redirectTo, includeRedirect: true};
        }
        return {ready: false};
    };

    return {
        activatePage,
        getRoutesStatus
    };
};

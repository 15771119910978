import {FormElement} from "../";
import styled, {css} from 'styled-components';
import React from "react";
import {StyledThumbnail} from "../../styles/ui";

const StyledVignette = styled.article`
  position: relative;
  background: #fff;
  box-shadow: 0 2px 0 ${p => p.theme.colors.thumbShadow};
  border: 1px solid ${p => p.theme.colors.thumbBorder};
  border-radius: .52em;
  padding: .875em .8125em;
  margin-bottom:1em;
  
  font-size: 1.2em;
`;

const SectionNom = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const userInputCSS = css`
    flex: 1;
    margin-left: .3em;
    margin-right: .3em;
`;

const UserVignette = ({prenom, nom, updatePrenomFn, updateNomFn, children, id, index, focus}) => {

    return (
        <StyledVignette>
            <SectionNom>
                <StyledThumbnail src="/img/userThumb.png" alt="Icône utilisateur"/>
                <FormElement css={userInputCSS} focus={focus && index === 0} text={prenom} onChangeFn={updatePrenomFn} uniqId={`prenom_${id}`} label="Prénom"/>
                <FormElement css={userInputCSS} text={nom} onChangeFn={updateNomFn} uniqId={`nom_${id}`} label="Nom"/>
            </SectionNom>
            {children && children}
        </StyledVignette>
    );
};

export default UserVignette;

import React from 'react';
import {useAuthState} from "react-firebase-hooks/auth";
import {useCollectionData, useDocumentData, useCollectionDataOnce} from "react-firebase-hooks/firestore";
import {auth, analytics, firestore, functions} from "../services/firebase";

const fbContext = React.createContext();
const {Provider, Consumer} = fbContext;

const FBProvider = ({children}) => {

    const [user, loading, error] = useAuthState(auth);


    return (
        <Provider value={{analytics, functions, user, userLoading: loading, userError: error, auth, firestore, useCollectionData, useCollectionDataOnce, useDocumentData}}>
            {children}
        </Provider>
    );
};

export {FBProvider, Consumer as FBConsumer, fbContext as FBContext};

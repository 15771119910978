import React, {useContext, useEffect, useState} from "react";
import {Form, FreeAppDownload} from "../../components";
import {Link, useHistory, useLocation} from "react-router-dom";
import {FBContext} from "../../context";
import {PageHeading, Button, BorderTop, ButtonWrapper, Margin} from "../../styles/ui";
import styled from "styled-components";
import {Helmet} from "react-helmet-async";
import {usePage} from "../../hooks";
import {logEvent} from "../../services/firebase";
import {loginEvent, loginEventParams} from "../../models/anlytics";
import * as queryString from 'querystring';
import {colors} from "../../styles/constants";

const StyledResetLink = styled(Link)`
  color: ${p => p.theme.colors.primary};
  align-self: flex-end;
  margin-bottom: -.6em;
  display: inline-block;
  font-size: ${p => p.theme.sizes.text.p};
`;

const Login = () => {
    const history = useHistory();
    const {search} = useLocation();
    let params = queryString.parse(search);

    const redirect = params.redirect || params['?redirect'];
    let emailProvider = (params.emails || params['?emails']);
    emailProvider = emailProvider ? '?emails=' + emailProvider : '';

    const redirectUrl = (redirect ? '/' + redirect : '/applications') + emailProvider;

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const {auth} = useContext(FBContext);
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState({msg: '', positive: false});

    const signIn = ({email, password}) => {
        if (isLoading) return;
        setError({msg: '', positive: false});
        setIsLoading(true);
        auth.signInWithEmailAndPassword(email, password)
            .then(() => {
                logEvent(loginEvent, {[loginEventParams.method]: 'password'});
                history.replace(redirectUrl);
            })
            .catch((err) => {
                setIsLoading(false);
                setError({msg: err, positive: false});
            });
    };

    const resetLink = (<StyledResetLink to="/reset">Oublié?</StyledResetLink>);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Authentification</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            <Margin marginbottom="2em" margintop="1.5em"><FreeAppDownload>
                Téléchargez l'app Snowclass et entrez le nom d'utilisateur 'SNOW' pour visiter la classe test!&nbsp;→
            </FreeAppDownload></Margin>

            <PageHeading>Se connecter</PageHeading>
            <Form btnLabel="Suivant" submitFn={signIn} msg={error} isDisabled={isLoading} elements={[
                {id: 'email', type: 'email', label: 'Courriel', init: '', focus: true},
                {id: 'password', type: 'password', label: 'Mot de passe', init: '', slot: resetLink, validation: [{type: 'minLength', val:8}]}
            ]}/>
            <BorderTop>
                <PageHeading>Vous êtes nouveau?</PageHeading>
                <ButtonWrapper><Button as={Link} to="/register">Créer un compte</Button></ButtonWrapper>
            </BorderTop>
        </>
    );
};

export default Login;

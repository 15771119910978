import styled, {css} from 'styled-components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";
import {settings} from "../Landscape/Landscape";
import {Mountain, Nuage, Tree, HeaderNav} from "..";

const StyledFullHeader = styled.header`
  overflow: hidden;
  @media screen and (min-width: ${_ => settings.media.small}) {
    height: 100vh;
    min-height: 664px;
  }  
  max-height: 960px;
  background-color: ${p => p.theme.colors.primary};
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-around;
  flex:9;
  position: relative;
  z-index: 10;
`;

const SnowclassLogo = styled.img`
  display:none;
  z-index:5;
  position:relative;
  width: ${_ => settings.logoWidth.mobile};
  @media screen and (min-width: ${_ => settings.media.small}) {
    display: inline;
    width: ${_ => settings.logoWidth.desktop};
  }
`;


/* Nuages */

const NuageWrapper = styled.div`
  display: none;
  margin: 0 auto;
  width: ${_ => settings.centerColumnWidth};
  position: relative;
  
  @media screen and (min-width:${_ => settings.media.large}) {
    display: block;
  }
`;

/* Sol */
const SolWrapper = styled.div`
  z-index: 8;
  display: none;
  background: ${_ => settings.colors.sol};
  flex: 2;
  width: 100%;
  border-top: 3px solid #fff;
  position: relative;
  
  @media screen and (min-width: ${_ => settings.media.small}) {
    display:block;
  }
  
  .sol_center {
    width: ${_ => settings.centerColumnWidth};
    position: absolute;
    top:0;
    bottom:0;
    left: calc(50% - ${_ => settings.centerColumnHalfWidth});
  }
`;

/* Montages */
const MountainWrapper = styled.div`
  position: absolute;
  bottom:100%;
  pointer-events: none;
  z-index:4;
  
  ${p => p.zindex && css`
    z-index: ${p.zindex};
  `}
  
  ${p => p.scale && css`
    transform: scale(${p.scale});
  `}
  
  ${p => p.left && css`
    left: ${p.left};
  `}
  
  ${p => p.right && css`
    right: ${p.right};
  `}
`;

const DownArrow = styled(FontAwesomeIcon)`
  position: absolute;
  bottom:0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 3em;
  color: ${p => p.theme.colors.primary};  
  transform: translateY(-125%);
  @media screen and (min-width: ${_ => settings.media.large}) {
    transform: translateY(0%);  
  }
`;


const FullHeader = () => {

    return (
        <StyledFullHeader>
            <HeaderNav/>
            <Wrapper>
                <h1><SnowclassLogo src={`${process.env.PUBLIC_URL}/img/img-snowclass-logo.png`} alt="Snowclass"/></h1>
            </Wrapper>

            <SolWrapper>
                <div className="sol_center">
                    <NuageWrapper>
                        <Nuage top="-571px" left="-510px" animation={settings.animation.names.nuage1}/>
                        <Nuage top="-638px" left="520px" animation={settings.animation.names.nuage1}/>
                        <Nuage top="-755px" left="710px" animation={settings.animation.names.nuage5} zindex="2" scale=".5"/>
                        <Nuage top="-312px" left="590px" animation={settings.animation.names.nuage3} zindex="1" scale=".3"/>
                        <Nuage top="-227px" left="-630px" animation={settings.animation.names.nuage3} zindex="1" scale=".3"/>
                        <Nuage top="-687px" left="-810px" animation={settings.animation.names.nuage5} zindex="2" scale=".5"/>
                        <Nuage top="-412px" left="-900px" animation={settings.animation.names.nuage3} zindex="1" scale=".3"/>
                        <Nuage top="-357px" left="420px" animation={settings.animation.names.nuage3} zindex="1" scale=".3"/>
                        <Nuage top="-447px" left="800px" animation={settings.animation.names.nuage5} zindex="2" scale=".5"/>
                    </NuageWrapper>
                    <div className="mountains">
                        <MountainWrapper right="-320px">
                            <Mountain/>
                            <Tree bottom="192px" right="-180px" scale=".8" animation={settings.animation.names.vent8}/>
                            <Tree bottom="121px" right="0px" scale=".8" animation={settings.animation.names.vent8} />
                            <Tree bottom="92px" right="-100px" scale=".8" animation={settings.animation.names.vent8}/>
                        </MountainWrapper>

                        <MountainWrapper right="-54px" scale=".56">
                            <Mountain/>
                            <Tree bottom="82px" right="100px" animation={settings.animation.names.vent1}/>
                            <Tree bottom="163px" right="-24px" scale=".75" animation={settings.animation.names.vent75}/>
                        </MountainWrapper>

                        <MountainWrapper left="-174px" scale=".58" zindex="2">
                            <Mountain/>
                            <Tree bottom="65px" right="-220px" animation={settings.animation.names.vent1}/>
                            <Tree bottom="139px" right="-80px" animation={settings.animation.names.vent1}/>
                        </MountainWrapper>

                        <MountainWrapper scale=".73" left="-330px">
                            <Mountain/>
                            <Tree bottom="294px" right="-130px" scale=".8" animation={settings.animation.names.vent8}/>
                        </MountainWrapper>

                        <MountainWrapper scale=".9" left="-590px">
                            <Mountain/>
                            <Tree bottom="256px" right="-19px" scale=".6" animation={settings.animation.names.vent6}/>
                            <Tree bottom="209px" right="-130px" scale=".75" animation={settings.animation.names.vent75}/>
                            <Tree bottom="167px" right="-164px" scale=".5" animation={settings.animation.names.vent5}/>
                            <Tree bottom="64px" right="-212px" scale=".75" animation={settings.animation.names.vent75}/>
                            <Tree bottom="52px" right="-20px" scale=".6" animation={settings.animation.names.vent6}/>
                        </MountainWrapper>
                    </div>

                    <div className="trees">
                        <Tree big left="-607px" bottom="20%" scale=".8" animation={settings.animation.names.vent8}/>
                        <Tree big left="-430px" bottom="41%" scale=".8" animation={settings.animation.names.vent8}/>
                        <Tree big right="-278px" bottom="43%" scale=".8" animation={settings.animation.names.vent8}/>
                    </div>
                    <DownArrow icon={faArrowDown}/>
                </div>
            </SolWrapper>

        </StyledFullHeader>
    );
};

export default FullHeader;

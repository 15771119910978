import {Route} from "react-router-dom";
import {PrivateRoute} from "../components";

const Layout = ({LayoutComponent, routes, isPublic, category, extraProps = {}, validation = () => ({success: true})}) => {
    const paths = routes.map(r => r.path);

    const layout = <LayoutComponent children={
        routes.map(r => (<Route key={r.path} {...r}/>))
    } {...extraProps}/>;



    return isPublic ? (
        <Route exact path={paths} children={layout}/>
    ) : (
        <PrivateRoute category={category} validation={validation} exact path={paths} routes={routes} children={layout}/>
    );
};

export default Layout;

import {HeaderNav, HomeFooter} from "../../components";
import styled from 'styled-components';
import {HomeSection, Margin} from "../../styles/ui";

const LayoutWrapper = styled.div`
    display: flex;
    align-items: stretch;
    flex-direction: column;
    
    @media screen and (min-width: ${p => p.theme.sizes.media.large}) { 
      min-height: 100vh;
    } 
`;

const Clouds = styled.div`
  height: 6em;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) { 
    height: 8em;
  }
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) { 
    height: 13em;
  }
  background: ${p => p.theme.colors.primary} url(${_ => `${process.env.PUBLIC_URL}/img/nuages.png`}) center / 80% auto no-repeat;
`;

const Wrapper = styled.div`
  width: 85%;
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) { 
    width: 63%;
  }
  margin: 0 auto;
`;

const InfoLayout = ({children}) => {

    return (
        <LayoutWrapper>
            <HeaderNav/>
            <Clouds/>
            <HomeSection>
                <Wrapper>
                    <Margin marginbottom="3.36em" margintop="3.25em">
                        {children}
                    </Margin>
                </Wrapper>
            </HomeSection>
            <HomeFooter/>
        </LayoutWrapper>
    );
};

export default InfoLayout;

import React, {useEffect, useState, useRef} from "react";
import {
    BorderTop,
    Button,
    DeleteConfirmation, /*Button,*/
    Margin,
    P,
    PageHeading,
    SpinnerParent
} from "../../styles/ui";
import styled from 'styled-components';
import {FreeTrialMessage, ListePlans, NewCardForm, Spinner} from "../../components";
import {Helmet} from "react-helmet-async";
import {usePage, useSubscription} from "../../hooks";
import moment from 'moment';
import 'moment/locale/fr-ca';
import {/*CardElement, */useElements} from "@stripe/react-stripe-js";
import {AccountContext} from "../../context";
import {useContext} from 'react';
import {AccountStatus} from "../../models/account";
import TimedAlert from "../../components/TimedAlert/TimedAlert";
import {CSSTransition} from "react-transition-group";
import {colors} from "../../styles/constants";
// import {Link} from "react-router-dom";


// const ChooseButton = styled(Button)`
//   width: 200px;
//   min-width: auto;
//   display: block;
//   margin-bottom: 1em;
// `;

const TextLink = styled.a`
  color: ${p => p.theme.colors.primary};
  &:link, &:visited {
    color: ${p => p.theme.colors.primary};
  }
  
  &:hover, &:active {
    color: ${p => p.theme.colors.secondary};
  }
`;

const LinksList = styled.ul`
  list-style-type: none;
`;

const ButtonTextLink = styled.button`
  background: transparent;
  border: none;
  color: ${p => p.theme.colors.primary};
  cursor: pointer;
  font-family: ${p => p.theme.fonts.heading};
  font-size: 1em;
  
  &:hover {
    color: ${p => p.theme.colors.secondary};
  }
`;

const AnnulationWrapper = styled.div`
  transition: max-height .3s ease-in-out;
  overflow: hidden;
  
  &.appear-enter {
    max-height: 0;
  }
  
  &.appear-enter-active {
    max-height: 350px;
    
    @media screen and (min-width: ${p => p.theme.sizes.media.xsmall}) {
      max-height: 100px;
    }
  }
  
  &.appear-exit {
    max-height: 350px;
    
    @media screen and (min-width: ${p => p.theme.sizes.media.xsmall}) {
      max-height: 100px;
    }
  }
  
  &.appear-exit-active {
    max-height: 0px;
  }
`;

const Abonnement = () => {

    const {subscription, subscribeHandler, unsubscribeHandler, isLoading, error, stripe, getPortalUrlHandler} = useSubscription();
    const {account} = useContext(AccountContext);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [showAbonnementConf, setShowAbonnementConf] = useState(false);
    const [showAnnulationConf, setShowAnnulationConf] = useState(false);
    const [showCancelBox, setShowCancelBox] = useState(false);
    const [doGetPortalUrl, setDoGetPortalUrl] = useState(false);
    // const [upgradeCost] = useState(null);
    // const [wallet, setWallet] = useState(null);
    const [portalUrl, setPortalUrl] = useState('');
    const elements = useElements();
    // const [_, setSelectedCard] = useState('new');

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    useEffect(() => {
        if (account.status === AccountStatus.PROF_ACTIF.label) {
            setSelectedPlan('');
        }
    }, [account?.status]);

    // preview d'upgrade
    // useEffect(() => {
    //     if (subscription && selectedPlan && !upgradeCost &&
    //         selectedPlan === process.env.REACT_APP_PLAN_ANNUEL_PLUS_ID) {
    //
    //         previewProrationHandler(selectedPlan);
    //     }
    // }, [selectedPlan, upgradeCost, subscription]);

    // cartes de crédit existantes

    // useEffect(() => {
    //     const listCreditCards = async () => {
    //         const cards = await listCreditCardsHandler();
    //         setWallet(cards);
    //         if (cards && cards.length) {
    //             setSelectedCard(cards[0].id);
    //         } else {
    //             setSelectedCard('new');
    //         }
    //     };
    //
    //     if (process.env.REACT_APP_IS_BETA_ON  !== '1' && account.status === AccountStatus.OPTED_OUT) {
    //         listCreditCards();
    //     }
    // }, [selectedPlan, account.status]);

     useEffect(() => {
        const getPortalUrl = async () => {
            const resp = await getPortalUrlHandler();
            setPortalUrl(resp);
            window.open(resp, '_blank');
        };

        if (doGetPortalUrl) {
            getPortalUrl();
            console.log('requesting');
        }

        /*if (process.env.REACT_APP_IS_BETA_ON === '0' && account.status !== AccountStatus.PROF_ACTIF.label && account.status !== AccountStatus.BETA_OVER.label && account.status !== AccountStatus.BETA.label) {
            getPortalUrl();
        }*/
    }, [doGetPortalUrl]);

    const selectPlanHandler = (plan) => {
       setSelectedPlan(prev => prev ? prev === plan ? '' : plan : plan);
    };

    const getEndText = () => {
        if (!subscription) {
            return;
        }
        const date = moment(  new Date(subscription.current_period_end * 1000)).locale('fr-ca').format('LL');

        return (subscription.cancel_at_period_end ? 'se terminera le ' : 'prochaine facturation le ') + date;
    };

    const getPlanName = () => {
        return subscription?.plan?.nickname + (subscription.status === 'trialing' ? ' en période d\'essai' : '');
    };

    const cancelAutoRenew = async () => {
        await unsubscribeHandler();
        setShowAnnulationConf(true);
    };

    const submitFormHandler = async(e) => {
        e.preventDefault();
        const cardElement = elements.getElement('cardNumber');
        const success = await subscribeHandler(cardElement, selectedPlan);
        if (success) {
            setSelectedPlan('');
            setShowAbonnementConf(true);
        }
    };

    const getConfirmationText = () => {
        const planName = subscription.plan.nickname;
        const trial = subscription.status === 'trialing';
        return <span>L'abonnement au plan <strong>{planName + trial ? ' (période d\'essai gratuit)' : ''}</strong> a été activé avec succès.</span>;
    };

    const getCancelButtonMessage = () => {
        return subscription.status === 'trialing' ? 'Voulez-vous vraiment annuler votre abonnement?' : 'Voulez-vous vraiment annuler votre renouvellement';
    };

    const getCancelLinkText = () => {
        return subscription.status === 'trialing' ? 'Annuler l\'abonnement' : 'Annuler le renouvellement automatique';
    };

    const subManagementLinkHandler = () => {
        if (!!portalUrl) {
            window.open(portalUrl, '_blank');
        } else {
            setDoGetPortalUrl(true);
        }
    };

    const nodeRef = useRef(null);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Abonnement</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            <PageHeading>Votre abonnement</PageHeading>
            <P>Votre abonnement à Snowclass.</P>

            <SpinnerParent loading={(process.env.REACT_APP_IS_BETA_ON === '0' && account.status !== AccountStatus.BETA.label && isLoading) ? 1: 0}>

                {process.env.REACT_APP_IS_BETA_ON === '1' ?
                    (<P>Abonnement actuel: <strong>Lancement</strong> (utilisation gratuite jusqu'au 26 mars 2021)</P>) :
                    subscription?.plan && (<P>Abonnement actuel : <strong>{getPlanName()}</strong> ({getEndText()})</P>)
                }
                <Margin margintop="2em" marginbottom="2em">
                    {process.env.REACT_APP_IS_BETA_ON === '0' && account.status === AccountStatus.BETA_OVER.label && (
                        <FreeTrialMessage/>
                    )}
                    <Margin marginbottom="1.5em"><PageHeading>Choisir un abonnement</PageHeading></Margin>


                    {subscription && showAbonnementConf && (
                        <TimedAlert delay={5000} positive={true} callback={() => setShowAbonnementConf(false)}>{getConfirmationText()}</TimedAlert>
                    )}

                    {subscription && showAnnulationConf && (
                        <TimedAlert delay={5000} positive={true} callback={() => setShowAnnulationConf(false)}>Votre abonnement ne sera pas renouvelé à la fin de la période courante.</TimedAlert>
                    )}
                    <ListePlans enabled={!isLoading} selectedPlan={selectedPlan} selectPlanFn={!isLoading ? selectPlanHandler : () => {}}
                                       activePlan={subscription?.plan?.id}/>
                </Margin>

                {/*{portalUrl && (<ChooseButton as="a" href={portalUrl} target="_blank">Gestion de votre abonnement</ChooseButton>)}*/}

                {process.env.REACT_APP_IS_BETA_ON !== '1' &&
                (account.status !== AccountStatus.ACTIVE.label && account.status !== AccountStatus.EXPIRED.label && account.status !== AccountStatus.BETA.label && account.status !== AccountStatus.PROF_ACTIF.label) &&
                (
                    <Margin margintop="1em">
                        <section>
                            {/*<PageHeading>Paiement sécuritaire par carte de crédit</PageHeading>*/}
                            {/*{wallet && wallet.length ? (*/}
                            {/*    <>*/}
                            {/*        <PageHeading>Cartes au dossier</PageHeading>*/}
                            {/*        {wallet.length > 1 ? (*/}
                            {/*            <select>*/}
                            {/*            {wallet.map(c => <CreditCard card={c.card} key={c.id}/>)}*/}
                            {/*            </select>*/}
                            {/*        ) : (*/}
                            {/*            <p>{wallet[0].card.brand} *** **** *** {wallet[0].card.last4} expires {wallet[0].card.exp_month}/{wallet[0].card.exp_year}</p>*/}
                            {/*        )}*/}
                            {/*    </>*/}
                            {/*) : null}*/}
                            {/*<PageHeading>Nouvelle carte</PageHeading>*/}
                            <NewCardForm account={account} selectedPlan={selectedPlan} isEnvValid={!!selectedPlan} show={true} isLoading={false} handleSubmitFn={submitFormHandler} error={error} stripe={stripe} autofocus={false}/>
                        </section>
                    </Margin>
                )}

                {account && account.status !== AccountStatus.PROF_ACTIF.label && !!account.stripeCustomerId && (
                    <BorderTop>
                        <section>
                            <PageHeading>Gestion de l'abonnement</PageHeading>
                            <LinksList>
                                {account.status !== AccountStatus.OPTED_OUT.label && (
                                    <li><ButtonTextLink as="button" onClick={subManagementLinkHandler}>Moyens de paiement</ButtonTextLink></li>
                                )}
                                <li><ButtonTextLink as="button" onClick={subManagementLinkHandler}>Historique de paiements</ButtonTextLink></li>
                                {subscription && !subscription.cancel_at_period_end && (
                                    <li>
                                        {!showCancelBox && (<ButtonTextLink as="button" onClick={() => setShowCancelBox(true)}>{getCancelLinkText()}</ButtonTextLink>)}
                                        <CSSTransition nodeRef={nodeRef} in={showCancelBox} unmountOnExit mountOnEnter timeout={300} classNames="appear">
                                            <AnnulationWrapper ref={nodeRef}>
                                                <DeleteConfirmation>
                                                    <P>{getCancelButtonMessage()}</P>
                                                    <div className="buttonsWrapper">
                                                        <Button textcolor="#fff" onClick={cancelAutoRenew}
                                                                hovercolor="#ff615c" minwidth="200px" color="#f74748"
                                                                shadowcolor="#813e3e">Oui, annuler</Button>
                                                        <Button onClick={() => setShowCancelBox(false)} minwidth="200px"
                                                                textcolor="#ffff" hovercolor="#70d2ff" color="#62c6fe"
                                                                shadowcolor="#53a5d0">Non</Button>
                                                    </div>
                                                </DeleteConfirmation>
                                            </AnnulationWrapper>
                                        </CSSTransition>
                                    </li>
                                )}
                            </LinksList>
                        </section>
                    </BorderTop>

                )}

                <Spinner show={process.env.REACT_APP_IS_BETA_ON === '0' && account.status !== AccountStatus.BETA && isLoading}/>
            </SpinnerParent>

            {/*{process.env.REACT_APP_IS_BETA_ON !== '1' && subscription && subscription.plan && !subscription.cancel_at_period_end && (*/}
            {/*    <Margin margintop="2em">*/}
            {/*        <section>*/}
            {/*            <PageHeading>Annulation</PageHeading>*/}
            {/*            <P>Pour annuler le renouvellement automatique, cliquez sur le bouton suivant.</P>*/}
            {/*            <Margin margintop="1em"><Button textcolor="#fff" hovercolor="#ff615c" minwidth="200px"*/}
            {/*                                            color="#f74748"*/}
            {/*                                            shadowcolor="#813e3e" onClick={cancelAutoRenew}>Annuler mon*/}
            {/*                abonnement</Button></Margin>*/}
            {/*        </section>*/}
            {/*    </Margin>*/}
            {/*)}*/}

            {/*{process.env.REACT_APP_IS_BETA_ON !== '1' && subscription && subscription.plan && subscription.cancel_at_period_end && (*/}
            {/*    <Margin margintop="2em">*/}
            {/*        <section>*/}
            {/*            <PageHeading>Annulation</PageHeading>*/}
            {/*            <P>Vous avez choisi d'annuler le renouvellement automatique.</P>*/}
            {/*            <Margin margintop="1em"><Button minwidth="200px" onClick={undoUnsubscribeHandler}>Renouveler l'abonnement</Button></Margin>*/}
            {/*        </section>*/}
            {/*    </Margin>*/}
            {/*)}*/}
        </>
    );
};

export default Abonnement;

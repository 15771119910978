import {Link, useLocation} from "react-router-dom";
import React, {useContext} from "react";
import {AccountContext} from "../../context";
import {H2, P} from "../../styles/ui";
import styled, {css} from 'styled-components';
import {Messagerie, SubscriptionAlerts} from "../index";

const StyledNav = styled.nav`
  margin-top: 1.2em;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${p => p.theme.colors.textField};
  border-radius: .44em;
  padding: 1.2em;
  align-items: flex-start;
  
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
      flex-direction: row;
      align-items: center;
  }
  
  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
      flex-direction: row;
    }
  }
  
  li + li {
    @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
      margin-left: 2em;
    }
  }
`;

const NavItem = styled(P)`
  font-weight: bold;
  display:block;
  // cursor: pointer;
  transition: color .2s ease-in-out;
  color: ${p => !p.active ? '#000' : p.theme.colors.secondary};
  font-size: 1.2em;
  padding: .5em;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    font-size: .85em;
    padding:0;
  }
  
  &:hover {
    ${props => !props.active && css`
    color: ${p => p.theme.colors.secondary};
    cursor: pointer;
  `}
    
  }
`;

// const AbonnementBtn = styled(Button)`
//   width: auto;
//   min-width: initial;
//   border-radius: 1.3em;
//   transition: background-color .2s ease-in-out;
//   color: #fff;
//   margin-left: .5em;
//   font-size: 1em;
//
//   @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
//     margin-left: 0;
//     font-size: .85em;
//   }
//
//   ${p => p.active && css`
//     background-color: ${p => p.theme.colors.primary};
//   `}
//
//   &:hover {
//
//   }
// `;

const WelcomeTitle = styled(H2)`
  text-align: center;
  font-size: 1.8em;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    text-align: left;
  }
  
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    font-size: 1.126em;
  }
`;

const NamePart = styled.span`
  display: none;
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    display: inline;
  }
`;

const Navigation = () => {

    const {pathname} = useLocation();

    const {account} = useContext(AccountContext);
    return (
        <>

            {<Messagerie/>}
            {process.env.REACT_APP_IS_BETA_ON !== '1' && (<SubscriptionAlerts account={account}/>)}


            <WelcomeTitle>Bienvenue sur Snowclass<NamePart>, {`${account.prenom} ${account.nom}`}</NamePart>!</WelcomeTitle>
            <StyledNav>
                <ul>
                    <li><NavItem as={Link} active={pathname === '/applications' ? 1 : 0} to="/applications">Téléchargements</NavItem></li>
                    <li><NavItem as={Link} active={pathname === '/users' ? 1 : 0} to="/users">Gestion des profils</NavItem></li>
                    <li><NavItem as={Link} active={pathname === '/profile' ? 1 : 0} to="/profile">Gestion du compte</NavItem></li>
                    <li><NavItem as={Link} active={pathname === '/subscription' ? 1 : 0} to="/subscription">Abonnement</NavItem></li>
                </ul>
                {/*<AbonnementBtn active={pathname === '/subscription' ? 1 : 0}*/}
                {/*               hovercolor={pathname === '/subscription' ? colors.primary : colors.focusHover}*/}
                {/*               color={pathname === '/subscription' ? colors.primary : colors.focus}*/}
                {/*               shadowcolor={pathname === '/subscription' ? colors.secondary : colors.darkFocus}*/}
                {/*               as={Link} to="/subscription">Abonnement</AbonnementBtn>*/}
            </StyledNav>
        </>
    );
};

export default Navigation;

import {P} from "../../styles/ui";
import styled, {css} from 'styled-components';
import {AccountContext} from "../../context";
import {useContext} from 'react';
import {colors} from "../../styles/constants";
import {AccountStatus} from "../../models/account";
import {useSubscription} from "../../hooks";

const StyledListePlans = styled.ul`
  list-style-type: none;
  display: flex;
  font-size: .9em;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    flex-direction: row;
    justify-content: ${p => p.fixed ? 'space-between' : 'flex-start'};
  }
`;

const Plan = styled.li`  
  color: ${p => p.color ? p.color : 'initial'};
  position: relative;
  display:flex;
  flex-direction: row;
  background: ${p => p.bg ? p.bg : p.theme.colors.textField};
  border-radius: 8px; // à convertir en em;
  padding: 1.7em 1em 1em;
  align-items: center;
  justify-content: flex-start;
  cursor: ${p => p.selectable === 0 || p.freeze === '1' ? 'default' : 'pointer'};
  transition: border-color ease-in-out .2s;
  
  margin: .35em;
  
  ${p => !p.fixed && css`
    border: 3px solid ${p => p.selected ? p.theme.colors.focus : (p.active ? p.theme.colors.primary : 'transparent') };
  `}
  
  ${p => p.freeze && !p.fixed && css`
    opacity:.7;
  `}
  
  flex-basis: 100%;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    flex-direction: column;
    flex-basis: 31%;
    max-width: 210px;
    margin: ${p => p.fixed ? '0.2em' : '0 .2em 0 .2em'};
  }
  
  img {
    margin:1.7em;
  }
`;

const PlanHighlight = styled(P)`
  position:absolute;
  top: 0;
  left: 1.3em;
  right: 1.3em;
  color: #fff;
  background-color: ${p => p.bgcolor || p.theme.colors.focus};
  font-weight: bold;
  transform: translateY(calc(-50% - .1em));
  border-radius: .35em;
  text-align:center;
  padding: .2em 0;
  font-size: 1.1em;
  display: none;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    display: block;
  }
`;

const Thumb = styled.div`
  // width: 60%;
  overflow: hidden;
  justify-content: center;
  text-align: center;
  display: flex;
  max-width: 120px;
  
  flex-grow: 0;
  flex-basis: 8.5em;
  //margin: 0 2em;
  margin: 0;
  font-size: 0.8em;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
      flex-grow: inherit;
      flex-basis: auto;
      margin: 0;
      max-width: none;
      font-size: 1em;
      
  }
  
  img {
    
    border-radius: 50%;
    width: 78px;
    height: 78px;
    //width: 102px;
    //height: 102px;
    
    @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
      height: 120px;
      width: 120px;
    }
  }
`;

const PlanTitle = styled.h3`
  font-size: 2.5em;
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    font-size: 1.9em;
  }
`;

const PlanDescription = styled(P)`
  font-size: 1.5em;
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    font-size: 1.1em;
  }
`;

const PlanInfo = styled.div`
  text-align: center;
  flex: 2;
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    width: 100%;
  }
`;

const PlanDetails = styled(P)`
  font-size: 1.2em;
  text-align: center;
  padding:.33em 0;
  width: 90%;
  margin: 1em auto 0;
  color: ${p => p.fixed ? '#fff' : '#000'}; 
  line-height: 1.25;
  border-radius: .57em;
  border: ${p => p.fixed ? '1px solid #fff' : `1px solid ${p.theme.colors.lightGray}` };
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
    font-size: 1em;
    width: 93%;  
  }
`;

// fixed détermine si ce n'est pas une version interactive
// color -> couleur d'arrière-plan des plans
const ListePlans = ({fixed, bgColor, selectedPlan = '', activePlan = '', selectPlanFn = () => {}, enabled = true, color = 0}) => {
    const {account} = useContext(AccountContext);
    const {PLAN_ANNUEL_ID, PLAN_ANNUEL_PLUS_ID} = useSubscription();

    const showBetaPlan = process.env.REACT_APP_IS_BETA_ON === '1' || (!fixed && process.env.REACT_APP_IS_BETA_ON === '0' && account && account.status === 'BETA_OVER');
    const isBetaActive = process.env.REACT_APP_IS_BETA_ON === '1';
    const isSelectable = planId => {
        return enabled && !fixed && (!activePlan)  && process.env.REACT_APP_IS_BETA_ON !== '1' && account && account.status !== AccountStatus.PROF_ACTIF.label;
    };

    return (
        <StyledListePlans fixed={fixed ? 1 : 0}>

            {showBetaPlan  && (fixed || (account && account?.status !== AccountStatus.PROF_ACTIF.label)) && (
                <Plan selectable={0} active={isBetaActive} freeze={!isBetaActive} fixed={fixed ? 1 : 0} bg={bgColor} color={color}>
                    <Thumb>
                        <img src={`${process.env.PUBLIC_URL}/img/icon-01.png`} alt="Plan d'essai"/>
                    </Thumb>
                    <PlanInfo>
                        <PlanTitle>Lancement</PlanTitle>
                        <PlanDescription color={color}>Gratuit jusqu'au 26 mars</PlanDescription>
                        <PlanHighlight>{isBetaActive ? 'Durée limitée' : 'Épuisé'}</PlanHighlight>
                    </PlanInfo>
                </Plan>
            )}
            {!fixed && account && account.status === AccountStatus.PROF_ACTIF.label && (
                <Plan selectable={0} active={true} freeze={false} fixed={0} bg={bgColor} color={color}>
                    <Thumb>
                        <img src={`${process.env.PUBLIC_URL}/img/icon-01.png`} alt="Plan enseignant"/>
                    </Thumb>
                    <PlanInfo>
                        <PlanTitle>Enseignant</PlanTitle>
                        <PlanDescription color={color}>Plan école</PlanDescription>
                        {/*<PlanHighlight>{isBetaActive ? 'Durée limitée' : 'Épuisé'}</PlanHighlight>*/}
                    </PlanInfo>
                </Plan>
            )}

            <Plan freeze={isBetaActive || (!isSelectable(PLAN_ANNUEL_ID) && activePlan !== PLAN_ANNUEL_ID)} selectable={isSelectable(PLAN_ANNUEL_ID) ? 1 : 0} active={activePlan === PLAN_ANNUEL_ID ? 1: 0} selected={selectedPlan === PLAN_ANNUEL_ID && activePlan !== PLAN_ANNUEL_ID} fixed={fixed ? 1 : 0} bg={bgColor} color={color} onClick={!isSelectable(PLAN_ANNUEL_ID) ? null : () => selectPlanFn(PLAN_ANNUEL_ID)}>
                <Thumb>
                    <img src={`${process.env.PUBLIC_URL}/img/userThumb.png`} alt="Plan mensuel"/>
                </Thumb>
                <PlanInfo>
                    <PlanTitle>Annuel</PlanTitle>
                    <PlanDescription color={color}>19.99$ / année</PlanDescription>
                    <PlanDetails fixed={fixed ? 1 : 0}>
                        2 utilisateurs d'une même famille inclus<br/>
                        Essai gratuit de 14 jours
                    </PlanDetails>
                    {!isBetaActive && !fixed && activePlan ===  PLAN_ANNUEL_ID && (<PlanHighlight bgcolor={colors.primary}>Votre abonnement</PlanHighlight>)}
                </PlanInfo>
            </Plan>

            <Plan freeze={isBetaActive || (!isSelectable(PLAN_ANNUEL_PLUS_ID) && activePlan !== PLAN_ANNUEL_PLUS_ID)} selectable={isSelectable(PLAN_ANNUEL_PLUS_ID) ? 1 : 0} active={activePlan === PLAN_ANNUEL_PLUS_ID} selected={/*!activePlan &&*/ selectedPlan === PLAN_ANNUEL_PLUS_ID && activePlan !== PLAN_ANNUEL_PLUS_ID} fixed={fixed ? 1 : 0} bg={bgColor} color={color} onClick={!isSelectable(PLAN_ANNUEL_PLUS_ID) ? null : () => selectPlanFn(PLAN_ANNUEL_PLUS_ID)}>
                <Thumb>
                    <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Plan annuel"/>
                </Thumb>
                <PlanInfo>
                    <PlanTitle>Annuel+</PlanTitle>
                    <PlanDescription color={color}>24.99$ / année</PlanDescription>
                    <PlanDetails fixed={fixed ? 1 : 0}>
                        4 utilisateurs d'une même famille inclus<br/>
                        Essai gratuit de 14 jours
                    </PlanDetails>
                    {!isBetaActive && !fixed && activePlan ===  PLAN_ANNUEL_PLUS_ID && (<PlanHighlight bgcolor={colors.primary}>Votre abonnement</PlanHighlight>)}
                </PlanInfo>
            </Plan>

        </StyledListePlans>
    );
};

export default ListePlans;

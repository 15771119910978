import {useMessagerie} from "../../hooks";
import {DisplayFlex, HighlightMessage, Margin, P, StyledThumbnail, ToggleButton} from "../../styles/ui";
import moment from 'moment';
import 'moment/locale/fr-ca';
import styled from 'styled-components';
import {colors} from "../../styles/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimesCircle} from "@fortawesome/free-solid-svg-icons";
const StyledCloseButton = styled(ToggleButton)`
  position:absolute;
  top:.1em;
  right:0;
`;

const MessageWrapper = styled.div`
  position:relative;
`;

const Messagerie = () => {

    const {message, closeMsg} = useMessagerie();

    return message != null && (
        <Margin marginbottom="1em">
            <MessageWrapper>
                <HighlightMessage align="left">
                    <Margin margintop=".3em" marginbottom=".3em"><DisplayFlex>
                        <Margin marginright="1em">
                            <StyledThumbnail src="/img/userThumb.png" alt="Snowclass"/>
                        </Margin>
                        <div>
                            <h3>Un message de Snowclass:</h3>
                            <P color="#fff">{message.message}</P>
                            <Margin margintop=".2em">
                                <P color={colors.thumbBorder}>{moment(new Date(message.createdAt * 1000)).locale('fr-ca').format('D MMMM - H:mm')}</P>
                            </Margin>
                        </div>
                    </DisplayFlex></Margin>
                    <StyledCloseButton onClick={() => closeMsg(message.id)}>
                        <FontAwesomeIcon size="lg" color={colors.secondary} icon={faTimesCircle}/>
                    </StyledCloseButton>
                </HighlightMessage>
            </MessageWrapper>
        </Margin>
    );
};

export default Messagerie;

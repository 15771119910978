import UserVignette from "../UserVignette";
import {useState, useEffect, useRef} from 'react';
import {retirerAccent, shallowEqual} from "../../../utils";
import styled from 'styled-components';
import {Button, DeleteConfirmation, P, ToggleButton} from "../../../styles/ui";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {CSSTransition} from "react-transition-group";


const StyledToggleButton = styled(ToggleButton)`
  position:absolute;
  top:0;
  right:0;
`;

const VignetteSection = styled.section`
  margin: 1em 0;
`;

const ExpandedVignette = styled.div`
  overflow: hidden;
  transition: max-height .3s ease-in-out;

  &.slide-enter {
    max-height: 0;
  }
  
  &.slide-enter-active {
   max-height: 500px;
  }
  
  &.slide-exit {
    max-height: 500px;
  }
  
  &.slide-exit-active {
    max-height: 0;
  }
`;

const SectionHeading = styled(P)`
  margin-bottom:.66em;
`;

const InfoLabel = styled(P)`
  font-family: Roboto, sans-serif;
  font-weight: bold;
  padding-top: .2em;
  padding-bottom: .2em;
  @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
  // ${p => p.invalid && css`
  //   color: ${p => p.valid ? '#000' : p.theme.colors.danger};
  // `}

const DeleteButton = styled(P)`
  background: transparent;
  border: none;
  outline-color: ${p => p.theme.colors.lightGray};
  display: inline-block;
  color: ${p => p.theme.colors.lightGray};
  cursor: pointer;
  
  &:hover {
    color: #000;
  }
`;

const RadioInput = styled.input`
  display:none;
  
  & + label::before {
    content: "";
    background-color: ${p => p.theme.colors.textField};
    width: 1.3em;
    height: 1.3em;
    border-radius: 50%;
    display: inline-block;
    margin-right: .6em;
    vertical-align: middle;
    cursor: pointer;
    
    @media screen and (min-width: ${p => p.theme.sizes.media.medium}) {
      width: 1em;
      height: 1em;
    }
  }
  
  &:checked + label::before {
    background-color: ${p => p.theme.colors.primary};
  }
`;

const ButtonContainer = styled.div`
  margin: 1.5em 0 1em 0;
`;

const Message = styled.span`
  color: ${p => p.theme.colors.primary};
  font-size: .8em;
  font-family: 'Roboto', sans-serif;
  margin-left: 1em;
`;

const UserFullVignette = (props) => {

    const [showDeleteConf, setShowDeleteConf] = useState(false);

    const [initState] = useState({
        prenom: props.prenom,
        nom: props.nom,
        c: props.c,
        g: props.genre,
        id: props.id
    });

    const getPropsObject = {
        prenom: props.prenom,
        nom: props.nom,
        c: props.c,
        g: props.genre,
        id: props.id
    };

    const checkApostrophe = (lettreSansAccent) => {
        return ['a','e','i','o','u'/*,'y'*/].indexOf(lettreSansAccent.toLowerCase()) !== -1 ? "'" : 'e ';
    };

    const [apostrophe, setApostrophe] = useState('');

    useEffect(() => {
        setApostrophe(props.prenom.trim() ? checkApostrophe(retirerAccent(props.prenom.trim()[0])) : 'e ');
    }, [props.prenom]);

    const genreSet = !!props.genre;
    const surnom = props.genre === 'm' ? 'M.' : 'Mme';

    const showWithMaxLength = (texte) => {
        const output = texte.substr(0, 29);
        return {
          output,
          valid: texte.length === output.length
        };
    };

    const cropped = {
        prenom: showWithMaxLength(props.prenom),
        nom: showWithMaxLength(props.nom),
        prenomNom: showWithMaxLength(`${props.prenom} ${props.nom}`)
    };

    const affichage = [
        {no: 0, text: `La classe d${apostrophe}${cropped.prenom.output}`, genreRequired: false, valid: cropped.prenom.valid},
        {no: 1, text: `La classe d${apostrophe}${cropped.prenomNom.output}`, genreRequired: false, valid: cropped.prenomNom.valid},
        {no: 2, text: `La classe de ${surnom} ${cropped.prenom.output}`, genreRequired: true, valid: cropped.prenom.valid},
        {no: 3, text: `La classe de ${surnom} ${cropped.nom.output}`, genreRequired: true, valid: cropped.nom.valid},
        {no: 4, text: `La classe de ${surnom} ${cropped.prenomNom.output}`, genreRequired: true, valid: cropped.prenomNom.valid},
    ];

    const showDeleteWindow = () => {
        setShowDeleteConf(true);
    };


    const autoOpen = (e, callback, prop) => {
        const prenom = prop === 'prenom' ? e.target.value : props.prenom;
        const nom = prop === 'nom' ? e.target.value : props.nom;

        if (!props.isOpen && prenom.trim() && nom.trim()) {
            props.toggleVignetteFn(props.index, true);
        } else if (props.isOpen && (!prenom.trim() || !nom.trim())) {
            props.toggleVignetteFn(props.index, false);
        }
        callback(e);
    };

    const nodeRef = useRef(null);

    return (
        <UserVignette {...props} updatePrenomFn={e => autoOpen(e, props.updatePrenomFn, 'prenom')}
                      updateNomFn={e => autoOpen(e, props.updateNomFn, 'nom')}>
            <CSSTransition nodeRef={nodeRef} mountOnEnter unmountOnExit in={props.isOpen} timeout={300} classNames="slide">
                <ExpandedVignette ref={nodeRef}>
                    <VignetteSection>
                        <SectionHeading>Genre:</SectionHeading>
                        <div>
                            <RadioInput checked={props.genre === "f"} onChange={props.updateGenreFn}
                                        id={`fldG1_${props.id}`}
                                        type="radio" name={`g${props.id}`} value="f"/>
                            <InfoLabel as="label" htmlFor={`fldG1_${props.id}`}>Féminin</InfoLabel>
                        </div>

                        <div>
                            <RadioInput checked={props.genre === "m"} onChange={props.updateGenreFn}
                                        id={`fldG2_${props.id}`}
                                        type="radio" name={`g${props.id}`} value="m"/>
                            <InfoLabel as="label" htmlFor={`fldG2_${props.id}`}>Masculin</InfoLabel>
                        </div>
                    </VignetteSection>

                    <VignetteSection>
                        <SectionHeading>Choisir le nom de votre classe:</SectionHeading>
                        {affichage
                            .filter(a => !a.genreRequired || (a.genreRequired && genreSet))
                            .map(a => (
                                <div key={a.no}>
                                    <RadioInput checked={props.c === a.no} onChange={props.updateClasseFn}
                                                id={`fldA${a.no}_${props.id}`} type="radio" name={`c${props.id}`}
                                                value={a.no}/>
                                    <InfoLabel invalid={a.valid ? 0 : 1} as="label" htmlFor={`fldA${a.no}_${props.id}`}>{a.text}</InfoLabel>
                                </div>
                            ))}
                    </VignetteSection>
                    {!showDeleteConf ? (<div>
                        <ButtonContainer>
                            {props.saveFn && !shallowEqual(getPropsObject, initState) && (<Button minwidth="170px" onClick={props.saveFn}
                                                      disabled={props.isLoading}>Sauvegarder</Button>)}
                            {props.msg?.msg && (<Message>{props.msg.msg}</Message>)}
                        </ButtonContainer>
                        {props.nbUsers > 1 && (<>Ou <DeleteButton as="button" onClick={() => showDeleteWindow()}>supprimer
                            ce
                            profil</DeleteButton>.</>)}
                    </div>) : (
                        <DeleteConfirmation>
                            <P>Voulez-vous supprimer définitivement ce profil?</P>
                            <div className="buttonsWrapper">
                                <Button textcolor="#fff" onClick={props.deleteFn} hovercolor="#ff615c" minwidth="200px" color="#f74748"
                                            shadowcolor="#813e3e">Supprimer</Button>
                                <Button onClick={() => setShowDeleteConf(false)} minwidth="200px" textcolor="#ffff" hovercolor="#70d2ff" color="#62c6fe"
                                        shadowcolor="#53a5d0">Annuler</Button>
                            </div>
                        </DeleteConfirmation>
                    )}
                </ExpandedVignette>
            </CSSTransition>

            {props.prenom.trim() && props.nom.trim() && (<StyledToggleButton isOpen={props.isOpen ? 1 : 0}
                                                                       onClick={() => props.toggleVignetteFn(props.index, !props.isOpen)}><FontAwesomeIcon icon={faChevronUp}/></StyledToggleButton>)}
        </UserVignette>
    )
};

export default UserFullVignette;

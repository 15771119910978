import styled, {css} from 'styled-components';
import {Link} from "react-router-dom";
import {clickTestAppEvent} from "../../models/anlytics";
import {logEvent} from "../../services/firebase";

const StyledLink = styled(Link)`
   &:link, &:visited, &:hover, &:active {
    color: #fff;
  } 
`;

const StyledBanner = styled.div`
  font-size: .9em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
  background-color: ${p => p.theme.colors.secondary};
  border-radius: .44em;
  font-family: ${p => p.theme.fonts.text};
  color: #fff;
  transition: background-color .15s ease-in;
  text-align: left;
  
  ${p => p.islink === 1 && css`
      &:hover {
        background-color: ${p => p.theme.colors.lightPrimary};
      }    
  `}
  
  .contenu {
    margin-left: 1em;
    h3 {
      font-size: 1.125em;
      font-weight: bold;
      margin-bottom: .2em;
    }
  }
`;

const Thumb = styled.img`
  width: 77.59px;
  height: 57px;
  //width: 48.87px;
  flex-grow: 0;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.xsmall}) {
    width: ${p => p.imgWidth || 108.9}px;
    height: ${p => p.imgWidth || 80}px;
  }
`;


const FreeAppDownload = ({children, isLink = true}) => {

    let elem = (
        <StyledBanner islink={isLink ? 1 : 0}>
            <Thumb src="/img/platformsIcon.png" alt="Logo Snowclass"/>
            <div className="contenu">
                <h3>Essayez Snowclass</h3>
                <p>{children}</p>
            </div>
        </StyledBanner>
    );

    if (isLink) {
        elem = (
            <StyledLink to="/download" onClick={() => logEvent(clickTestAppEvent)}>
                {elem}
            </StyledLink>
        );
    }


    return elem;
};


export default FreeAppDownload;

import styled, {css} from "styled-components";
import {BigHeading, P} from "..";
import {Link} from "react-router-dom";

const HomeSection = styled.section`
  background: ${p => p.color};
  width: 100%;
  overflow: ${p => p.overflow || 'hidden'};
  ${p => p.textcolor && css`
    color: ${p.textcolor};
  `}
  text-align: center;
  
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
    text-align: left;
  }
`;

const SectionWrapper = styled.div`
  width: 90%;
  flex-direction: column;  
  
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
    flex-direction: ${p => p.direction ? p.direction : 'row'}; 
    width: 63%;
  }
  margin: 0 auto;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 3.75em 0;
  
  ${p => p.padding && css`
    padding-top: ${p.padding};
    padding-bottom: ${p.padding};
  `}
  
  ${p => p.paddingbottom && css`
    padding-bottom: ${p.paddingbottom};
  `}
  
  ${p => p.paddingtop && css`
    padding-top: ${p.paddingtop};
  `}
  
  ${p => p.direction && css`
    flex-direction: ${p.direction};
  `}
  
  ${p => p.align && css`
    align-items: ${p.align};
  `}
  
  ${p => p.justify && css`
    justify-content: ${p.justify};
  `}
`;

const SectionTitle = styled(BigHeading)`
  margin-bottom: .51em;
  line-height: 1.06;
  ${p => p.margin && css`
    margin-bottom: ${p.margin};
  `}
  ${p => p.textalign && css`
    text-align: ${p.textalign};
  `}
`;

const SectionDescription = styled(P)`
  font-size: 1.4em;
  @media screen and (min-width: ${p => p.theme.sizes.media.xlarge}) {
    font-size: ${p => p.fontsize || '1.7em'};  
  }
  font-weight: 600;
  color: ${p => p.theme.colors.dark};
  ${p => p.textalign && css`
    text-align: ${p.textalign};
  `}
  ${p => p.marginbottom && css`
    margin-bottom: ${p.marginbottom};
  `}
`;

const ColWrapper = styled.div`
  width: ${p => p.mobilewidth || '90%'};
  margin: 0 auto;
  @media screen and (min-width:${p => p.theme.sizes.media.large}) {
    width: ${p => p.width ? p.width : 'auto'};  
   ${p => p.marginleft && css`
      margin-left: ${p.marginleft};
    `}
  }
  
  
  ${p => p.marginright && css`
    margin-right: ${p.marginright};
  `}
  
  ${p => p.basis && css`
    flex-basis: ${p.basis};
  `}
  
  ${p => p.textalign && css`
    text-align: ${p.textalign};
  `}
`;

export const HomeLink = styled(Link)`
  color: ${p => p.color || p.theme.colors.secondary};
  font-size: ${p => p.fontsize || '0.85em'};
  font-family: ${p => (p.font || 'Roboto') + ', sans serif'};
  
  :hover {
    color: ${p => p.hover || p.theme.colors.lightGray};
  }
`;

export {HomeSection, SectionWrapper, SectionDescription, ColWrapper, SectionTitle};

import {useContext} from 'react';
import {FBContext} from "../context";
import {firestore} from "../services/firebase";
import {useCollectionData} from "react-firebase-hooks/firestore";

export const useMessagerie = () => {
    const {user} = useContext(FBContext);

    const messagesRef = firestore.collection('accounts').doc(user.uid).collection('messagerie')
        .where('lu', '==', false)
        .orderBy('createdAt', 'asc');

    const [messages, messagesLoading, messagesError] = useCollectionData(messagesRef, {idField: 'id'});

    const fermerMsg = async (id) => {
        const msgRef = firestore.collection('accounts').doc(user.uid).collection('messagerie').doc(id);
        await msgRef.update({
            lu: true
        })
    };

    return {
            message: messages ? messages[0] : null,
            messagesError,
            messagesLoading,
            closeMsg: fermerMsg
    };

};

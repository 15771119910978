import {AuthCommonLayout, CommonLayout, HomeLayout, InfoLayout, LoggedLayout} from "./layout";
import {
    AccountInfo,
    EmailValidation,
    Home,
    Login,
    Logout,
    Register,
    ResetPassword,
    UsersInfo,
    Onboarding,
    AppShowcase, Abonnement, Contact, /*FAQ,*/ Terms, Confidentialite, Ecoles, DownloadEcole, /*Page404*/
} from "./pages";

import RegisterTopRightSection from "./pages/Register/RegisterTopRightSection";
import LoginTopRightSection from "./pages/Login/LoginTopRightSection";
import {AccountStatus} from "./models/account";

export const routesCategory = {
    public: 'public',
    auth: 'auth',
    account: 'account',
    notauth: 'notauth'
};

const isOnboarding = (user, account) => account &&
    ((!account.prenom || !account.nom) || (!account.users || !account.users.length) ||
        (account.status === AccountStatus.ONBOARDING.label));

export const routesGroup = [
    {
        category: routesCategory.public,
        LayoutComponent: InfoLayout,
        isPublic: true,
        routes: [
            {
                path: '/contact',
                component: Contact
            },
            /*{
                path: '/faq',
                component: FAQ
            },*/
            {
                path: '/terms',
                component: Terms
            },
            {
                path: '/confidentiality',
                component: Confidentialite
            },
            {
                path: '/ecoles',
                component: Ecoles
            },
            {
                path: '/telechargement',
                component: DownloadEcole
            },
            {
                path: '/download',
                component: DownloadEcole
            }
        ]
    },
    {
        category: routesCategory.public,
        LayoutComponent: CommonLayout,
        isPublic: false,
        validation: (user, account) => {
            return {success: !!user, redirectTo: '/'};
        },
        routes: [
            {
                path: '/logout',
                component: Logout
            },
            {
                path: '/reset',
                component: ResetPassword
            },
        ]
    },
    {
        category: routesCategory.notauth,
        LayoutComponent: CommonLayout,
        isPublic: false,
        validation: (user, account) => {
          let redirect = '/applications';
          if (user && !user.emailVerified) {
              redirect = '/confirmation';
          } else if (isOnboarding(user, account)) {
              redirect = '/onboarding';
          }
          return {success: !user, redirectTo: redirect};
        },
        extraProps: {
            topRightSection: {
                '/register': RegisterTopRightSection,
                '/login': LoginTopRightSection
            }
        },
        routes: [
            {
                path: '/login',
                component: Login
            },
            {
                path: '/register',
                component: Register,
            }
        ]
    },
    {
        category: routesCategory.public,
        LayoutComponent: HomeLayout,
        isPublic: true,
        routes: [
            {
                path: '/',
                component: Home,
                exact: true
            },
            {
                path: '/index.html',
                component: Home,
                exact: true
            }
        ]
    },
    {
        category: routesCategory.account,
        LayoutComponent: LoggedLayout,
        isPublic: false,
        validation: (user, account, isTesting) => {
            let redirect = '/login';
            if (user && user.emailVerified) {
                if (isOnboarding(user, account)) {
                    redirect = '/onboarding';
                }
            }
            return {success:
                    user && (user.emailVerified || isTesting) &&
                    !isOnboarding(user, account),
                redirectTo: redirect};
        },
        routes: [
            {
                path: '/profile',
                component: AccountInfo,
            },
            {
                path: '/users',
                component: UsersInfo,
            },
            {
                path: '/applications',
                component: AppShowcase
            },
            {
                path: '/subscription',
                component: Abonnement
            }
        ]
    },
    {
        category: routesCategory.auth,
        LayoutComponent: AuthCommonLayout,
        isPublic: false,
        validation: (user, _, testing) => {
            return {success: user && (!user.emailVerified || testing), redirectTo: '/login'};
        },
        routes: [
            {
                path: '/confirmation',
                component: EmailValidation,
            },
        ]
    },
    {
        category: routesCategory.account,
        LayoutComponent: CommonLayout,
        isPublic: false,
        validation: (user, account, isTesting) => {
            let redirect = '/login';
            if (!user || !account) {
            } else if (user && (!user.emailVerified && !isTesting)) {
                redirect = "/confirmation";
            } else if (!isOnboarding(user, account)) {
                redirect = '/applications';
            }
            return {success:
                    user && (user.emailVerified || isTesting) && isOnboarding(user, account),
                redirectTo: redirect};
        },
        routes: [
            {
                path:'/onboarding',
                component: Onboarding,
            }
        ]
    },
    // {
    //     category: routesCategory.public,
    //     LayoutComponent: HomeLayout,
    //     isPublic: true,
    //     routes: [
    //         {
    //             path: '*',
    //             component: Page404
    //         }
    //     ]
    // }
];

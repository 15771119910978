import {ButtonNoShape, DisplayFlex, HeaderButton} from "../../styles/ui";
import {Link} from "react-router-dom";
import {colors} from "../../styles/constants";
import {useContext} from "react";
import {FBContext} from "../../context";
import styled from "styled-components";


const LinkButton = styled(HeaderButton)`
  display: block;
  
  &:link, &:visited {
    color: ${props => props.theme.colors.dark};
  }
`;

const StyledSmallLogo = styled.div`
      width: 7.8em;
      // transform: translateY(-50%);
      
      img {
        width: 100%;
      }
      
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
      position: absolute;
      left:3.125em;
      background-color: ${p => p.theme.colors.dark};
      padding: .89em 1.38em;
      top:0;
  }
`;

const StyledHeaderNav = styled.nav`
  padding: 1.25em 1.25em;
  position: relative;
  z-index:10;
  display:flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${p => p.theme.colors.secondary};
  
  @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
      justify-content: flex-end;
      padding: 1.25em 3.125em;
      flex-direction: row;
  }
  
  .fullHeaderNav {
    display: flex;
    justify-content: flex-end;
    list-style-type: none;
    flex-direction: column;
    @media screen and (min-width: ${p => p.theme.sizes.media.small}) {
      flex-direction: row;
      
    }
    
    li + li {
      margin-top: .5em;
      @media screen and (min-width: ${p => p.theme.sizes.media.xsmall}) { 
        margin-top: 0;
      }
    }
    
    
    a {
      text-decoration: none;
    }
  }
`;

const SmallLogo = () => {
    return (
        <StyledSmallLogo>
            <Link to="/"><img src={`${process.env.PUBLIC_URL}/img/img-snowclass-logo-small.png`} alt="Petit Logo"/></Link>
        </StyledSmallLogo>
    );
};

const HeaderNav = () => {
    const {auth} = useContext(FBContext);

    return (
        <StyledHeaderNav>
            <SmallLogo/>
            {!auth || !auth.currentUser ? (
                <ul className="fullHeaderNav">
                    <li><ButtonNoShape hovercolor="transparent" as={Link} to="/register" login="1">Créer un compte</ButtonNoShape></li>
                    <li><LinkButton hovercolor={colors.dark} hovertextcolor="#fff" as={Link} to="/login" login="1">Connexion</LinkButton></li>
                </ul>
            ) : (
                <DisplayFlex justifycontent="flex-end"><LinkButton as={Link} to="/applications">Votre compte</LinkButton></DisplayFlex>
            )}
        </StyledHeaderNav>
    );
};

export default HeaderNav;

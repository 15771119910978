import {Link} from "react-router-dom";
import {FBContext} from "../../context";
import React, {useContext, useEffect} from 'react';
import styled from 'styled-components';
import {Helmet} from "react-helmet-async";
import {usePage} from "../../hooks";
import {colors} from "../../styles/constants";

const StyledLink = styled(Link)`
  &:link, &:visited {
    color: ${p => p.theme.colors.primary};
  }
  
  &:hover, &:active {
    color: ${p => p.theme.colors.secondary};
  } 
`;

const Img = styled.img`
  width: 80px;
  border-radius: 50%;
  margin-bottom: 1em;
`;

const Wrapper = styled.div`
  text-align: center;
  margin-top: 3em;
  @media screen and (min-width: ${p => p.theme.sizes.media.large}) {
    margin: 0;
  }
`;

const InnerWrapper = styled.div`
  display: inline-block;
  text-align: left;
`;

const Logout = () => {

    const {activatePage} = usePage();
    useEffect(() => {
        activatePage();
    }, [activatePage]);

    const {auth} = useContext(FBContext);
    auth.signOut();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Snowclass | Déconnexion</title>
                <meta name="theme-color" content={colors.secondary}/>
            </Helmet>
            <Wrapper><InnerWrapper>
            {/*<CommonLayout>*/}
            <Img src={`${process.env.PUBLIC_URL}/img/icon-01.png`} alt=""/>
            <h2>Déconnexion réussie</h2>
            <p>Vous êtes maintenant déconnecté. <br/><StyledLink to="/">Retour à l'accueil</StyledLink></p>
            {/*</CommonLayout>*/}
            </InnerWrapper></Wrapper>
        </>
    );
};

export default Logout;
